import {applyMiddleware, createStore} from "redux";
import {persistStore, persistReducer, createMigrate, MigrationManifest} from "redux-persist";
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";
import {rootReducer} from "./combineReducers";
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import {rootSaga} from "./combineSaga";

const migrations: MigrationManifest = {
    // 0: (state: ApplicationState) => {
    //   // migration clear out device state
    //   return {
    //     ...state,
    //   //   login: {...state.login, subscribing: false,
    //   //     subscribedMessage: '',
    //   //     subscribedEmails: []}
    //   }
    // }
}

/**
 * Persist Store Config
 */

const persistConfig = {
    key: "root",
    version: 0,
    storage,
    migrate: createMigrate(migrations, {debug: false}),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
/**
 * store is configured here
 */

const sagaMiddleware = createSagaMiddleware();


const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
);


// store.subscribe(() => {
//   logger.log('store: ', store.getState());
// });


const persistor = persistStore(store);
sagaMiddleware.run(rootSaga)

store.subscribe(() => {
    console.log('store: ', store.getState());
});

export {store, persistor};
