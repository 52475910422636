export const popularMarket = [
    {
        "id": "1.1.1",
        "name": "Obelink Lugano 6 Plus tunneltent",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/2/428850-428850-images_main-obelink_lugano_6-ecommerce.jpg",
        "price": "199,00",
        "discount": null,
        "rating": 5,
        "ratingCount": "513",
        "features": [
            "Aantal personen 6",
            "Materiaal buitendoek Polyester",
            "Totaalgewicht 21.2 kg"
        ],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/obelink-lugano-6-plus-tunneltent.html",
                "price": "199,00"
            },
            {
                "name": "Frank",
                "url": "https://www.frank.nl/coleman-cook-6-tunneltent/223656?bl3nlclid=1f1d0ba3-7844-4203-a0b4-8949a56c2623&lgw_code=17516-223656&utm_medium=cpc&utm_source=beslist_nl&utm_term=17516-223656",
                "price": "333,00"
            },
            {
                "name": "Ebay",
                "url": "https://www.ebay-kleinanzeigen.de/s-anzeige/obelink-lugano-6-plus-tunnelzelt-mit-vordach/1341927309-230-1359",
                "price": "195,00"
            }
        ]
    },
    {
        "id": "1.1.5",
        "name": "Obelink Lodge 180 tunneltent",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/3/435349-435349-images_main-obelink_lodge_180-ecommerce.jpg",
        "price": "89,00",
        "rating": 4.4,
        "discount": "198,00",
        "ratingCount": "42",
        "features": [
            "Aantal personen 3",
            "Materiaal buitendoek Polyester",
            "Totaalgewicht 9 kg"
        ],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/obelink-lodge-180-tunneltent.html",
                "price": "89,00"
            },
            {
                "name": "Sport bay",
                "url": "https://sportbay.nl/nl/pr/Husky-Bromer-4-tunneltent/1574",
                "price": "229,00"
            },
            {
                "name": "Internet Sport & Causuals",
                "url": "https://www.internet-sportandcasuals.com/214424-dutch-mountains-grebbeberg-4-persoons-tunneltent-460-cm-blauw/?ecg=a06narKNuQASuCWcsBS4b11F1lHUXIp0BcnCDgT-R-MXL-2JZoa8fc7VLAjBikNG3LhtXUbEaPp7XV6HST_QHzMznDs8i-eVR0kA0oaGdISNvs_nX7V6-YFmDofFbrhYtedA4lMQn0vxJ4crYvE8K1Kb5B8zVWcy_8FiGxGPZmKN7jmjDWQljBFvbETMJWkAE3o172AQxqH7Dat2smuQiz0w61bJEZrq0cN_bxkuanikKKhnFBHiejCdt8C4F1CcJud2Ns11H3UQu9k0NXLPNcDbgAQgD7G1qGW1Zig64nUlPDvxDDI5Nlx3A3UJCfwKj2dRvULA6zQzdvlYqZ4_YQiF-cZB9Z6lSEy5kwQ_k0BxQxeKxi3mKcT_MVFuXxHGekyghJsHBDBt1LELou9FuzBNBhJmdAGQ53A8oFfIIh7OY_zexG1KNUXxi06OLEz_6Hg9zATnBaYuDMHseqMZyyZpw-64gLay68VfTDw58X9jqmOWut5cO1folqGhoknXy_17-7sHMu6p270GFExDR6-pef_hs2xCS9LK6QYQVehdqzyeOaxqojZZx4K6Vp0D&utm_campaign=Dutch+Mountains&utm_content=Kamperen+%7C+Tenten&utm_medium=cpc&utm_source=marktplaats&utm_term=214424#",
                "price": "161,00"
            }
        ]
    },
    {
        "id": "1.2.2",
        "name": "Obelink Dance Pop-up tent",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/538338-538338-images_main-obelink_dance_popup-ecommerce.jpg",
        "price": "29,95",
        "discount": null,
        "rating": 3,
        "ratingCount": "102",
        "features": [
            "Aantal personen 1",
            "Materiaal buitendoek Polyester",
            "Totaalgewicht 2.86 kg"
        ],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/obelink-dance-pop-up-tent-538338.html",
                "price": "29,95"
            },
            {
                "name": "bol.com",
                "url": "https://www.bol.com/nl/p/maxxgarden-pop-up-tent-festival-camping-tent-2-personen-255x155x95-cm/9200000107940719/?bltgh=s4gk-9pLzeO23ZRdNQlKvQ.1_30.32.ProductTitle",
                "price": "43,99"
            }
        ]
    },
    {
        "id": "1.2.3",
        "name": "Lief! Lifestyle Stoer! pop-up tent",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/1/316945-316945-images_main-lief_lifestyle_popup_tent_stoer_jongens-ecommerce.jpg",
        "price": "39,95",
        "discount": null,
        "rating": 5,
        "ratingCount": "1",
        "features": [
            "Aantal personen 1",
            "Materiaal buitendoek Polyester",
            "Totaalgewicht 2.1 kg"
        ],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/lief-lifestyle-stoer-pop-up-tent.html",
                "price": "39,95"
            },
            {
                "name": "bol.com",
                "url": "https://www.bol.com/nl/p/pop-up-tent-245-x-145-x-95-cm-waterdicht-uv-beschermd/9200000084951672/?bltgh=s4gk-9pLzeO23ZRdNQlKvQ.1_30.31.ProductTitle",
                "price": "34,40"
            }
        ]
    },
    {
        "id": "8.6.4",
        "name": "Rooster voor ventilatie",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/4/247558-247558-images_main-ventilatierooster25-ecommerce.jpg",
        "price": "2,95",
        "discount": "3,25",
        "rating": 3,
        "ratingCount": "1",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/rooster-voor-ventilatie.html",
                "price": "2,95"
            },
            {
                "name": "marktplaats",
                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/m1538817278-hypercamp-350.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                "price": "3,00"
            }
        ]
    },
    {
        "id": "8.6.5",
        "name": "Opbouw ventilatieroosters",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/1/219350-219350-images_main-rozetinbouwbruinopbouw-ecommerce.jpg",
        "price": "2,25",
        "discount": "2,45",
        "rating": 0,
        "ratingCount": "27",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/opbouw-ventilatieroosters.html",
                "price": "2,25"
            },
            {
                "name": "ventilatieshop",
                "url": "https://www.ventilatieshop.com/ventilatieroosters-ventielen/ventilatieroosters-binnen/afsluitbare-schuifroosters/",
                "price": "5,49"
            }
        ]
    },

    {
        "id": "3.1.2",
        "name": "Outwell Asado gasbarbecue",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/6/469455-469455-images_main-asadogasgril-ecommerce.jpg",
        "price": "38,95",
        "discount": "59,95",
        "rating": 4.4,
        "ratingCount": "81",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/outwell-asado-gasbarbecue.html",
                "price": "38,95"
            },
            {
                "name": "outwell",
                "url": "https://www.outwell.com/en-gb/ecommerce-1/accessories/asado-gas-grill",
                "price": "59,95"
            },
            {
                "name": "leisureoutlet",
                "url": "https://www.leisureoutlet.com/caravans-and-motorhomes/cooking/barbeques/131220-outwell-asado-gas-grill-barbeque",
                "price": "38,99"
            }
        ]
    },
    {
        "id": "8.5.2",
        "name": "All Ride ronde autoventilator ",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/0/503918-503918-images_main-all_ride_autoventilator-ecommerce.jpg",
        "price": "16,95",
        "discount": null,
        "rating": 5,
        "ratingCount": "2",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/all-ride-ronde-autoventilator.html",
                "price": "16,95"
            },
            {
                "name": "bol.com",
                "url": "https://www.bol.com/nl/p/all-ride-autoventilator-12-volt-o-15-cm-bevestiging-met-klem-zuignap-kunststof-zwart/9200000081534731/",
                "price": "24,95"
            }
        ]
    },
    {
        "id": "8.5.3",
        "name": "All Ride 12 volt twin ventilator ",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/0/503919-503919-images_main-all_ride_dubbele_ventilator_12_volt-ecommerce.jpg",
        "price": "14,95",
        "discount": "598,00",
        "rating": 4.8,
        "ratingCount": "12",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/all-ride-12-volt-twin-ventilator.html",
                "price": "14,95"
            },
            {
                "name": "bol.com",
                "url": "https://www.bol.com/nl/p/all-ride-auto-ventilator-met-zuignap/9200000060188514/",
                "price": "14,95"
            }
        ]
    },
    {
        "id": "2.1.5",
        "name": "Meindl Portland GTX wandelschoenen",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/9/295200-295200-images_main-meindl_342931-ecommerce.jpg",
        "price": "155,00",
        "rating": 0,
        "discount": "169,90",
        "ratingCount": "42",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/meindl-portland-gtx-wandelschoenen.html",
                "price": "155,00"
            },
            {
                "name": "outdoor",
                "url": "https://www.outdoorxl.nl/meindl-portland-gtx.html?prl=92-631x144-69",
                "price": "169,95"
            },
            {
                "name": "beslist",
                "url": "https://www.beslist.nl/schoenen/d1033897748/Meindl_Portland_GTX_Antraciet_Wandelschoenen_Heren.html?productIdentifier=00000137439987369748999315432",
                "price": "159,99"
            }
        ]
    },
    {
        "id": "4.3.1",
        "name": "Isabella Camp-let Passion 2019 vouwwagen",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/2/525696-525696-images_main-isabella_camp-let_passion_nieuw-ecommerce.jpg",
        "price": "10.250,00",
        "discount": null,
        "rating": 0,
        "ratingCount": "71",
        "features": [
            "Modeljaar 2019   ",
            "Rijklaar gewicht 300  kg ",
            "Max. toelaatbaar gewicht 500  kg "
        ],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/isabella-camp-let-passion-2019-vouwwagen.html",
                "price": "10.250,00"
            }
        ]
    },
    {
        "id": "4.3.2",
        "name": "Campooz Fat Freddy* 2019 vouwwagen",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/2/524734-524734-images_main-campooz_fat_freddy_nieuw-ecommerce.jpg",
        "price": "6.950,00",
        "discount": null,
        "rating": 0,
        "ratingCount": "285",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/campooz-fat-freddy-2019-vouwwagen.html",
                "price": "6.950,00"
            },
            {
                "name": "dewitschijndel",
                "url": "https://www.dewitschijndel.nl/campooz-fat-freddy-camping-vouwwagen-inclusief-voortent/",
                "price": "7.695,00"
            }
        ]
    },
    {
        "id": "4.3.3",
        "name": "Jamet Dakota Obelink Special 2019 vouwwagen",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/530944-530944-images_main-jamet_dakota_obelink_special_nieuw-ecommerce.jpg",
        "price": "6.495,00",
        "discount": null,
        "rating": 0,
        "ratingCount": "102",
        "features": [
            "Modeljaar 2019   ",
            "Rijklaar gewicht 395  kg ",
            "Max. toelaatbaar gewicht 650  kg "
        ],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/jamet-dakota-obelink-special-2019-vouwwagen.html",
                "price": "6.495,00"
            }
        ]
    },
    {
        "id": "7.5.7",
        "name": "Kampa Ace Air All Season 400 caravanvoortent",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/9/490620-490620-images_main-kampa_ace_air_allseason_400_2018-ecommerce.jpg",
        "price": "1.499,00",
        "discount": "2.250,00",
        "rating": 4.5,
        "ratingCount": "4",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/kampa-ace-air-all-season-400-caravanvoortent.html",
                "price": "1.499,00"
            },
            {
                "name": "v",
                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/a1315028433-kampa-dometic-opblaasbare-voortent-ace-air-all-season-400.html",
                "price": "1.799,00"
            }
        ]
    },
    {
        "id": "6.1.2",
        "name": "Obelink Mallorca Easy Air Tall bustent",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530584-530584-images_main-obelink_mallorca_easy_air_1_3-ecommerce.jpg",
        "price": "479,00",
        "discount": null,
        "rating": 0,
        "ratingCount": "81",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/obelink-mallorca-easy-air-tall-bustent.html",
                "price": "479,00"
            }
        ]
    },
    {
        "id": "6.1.3",
        "name": "Obelink Sahara 400 Bell tent",
        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/2/123895-123895-images_main-obelink_sahara_400_101-ecommerce.jpg",
        "price": "299,00",
        "discount": null,
        "rating": 5,
        "ratingCount": "71",
        "features": [],
        "links": [
            {
                "name": "obelink",
                "url": "https://www.obelink.nl/obelink-sahara-400-bell-tent.html",
                "price": "299,00"
            },
            {
                "name": "AliExpress",
                "url": "https://nl.aliexpress.com/item/32279588317.html",
                "price": "429,00"
            },
            {
                "name": "Belltent Boutique",
                "url": "https://belltentboutique.co.uk/products/4m-bell-tent?variant=15864271667243",
                "price": "379,90"
            }
        ]
    }
];

export const popularCamping = [
    {
        "campsiteId": 105222,
        "campsiteName": "Camping Poljana ****",
        "campsiteImages": [
            "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7e0c53e.jpeg",
            "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7fdda59.jpeg",
            "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a48a63.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c009e8a.jpeg",
            "https://cdn2.suncamp.eu/5/d/9/e/5d9efa833f512.jpeg",
            "https://cdn3.suncamp.eu/5/d/3/9/5d398d18a6894.jpeg",
            "https://cdn1.suncamp.eu/5/d/3/9/5d398d1a9d805.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ca69d.jpeg",
            "https://cdn1.suncamp.eu/5/d/3/9/5d3995a7bd439.jpeg",
            "https://cdn2.suncamp.eu/5/d/3/9/5d3995a9d6217.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn1.suncamp.eu/5/d/9/e/5d9efa7e282e9.jpeg",
            "https://cdn3.suncamp.eu/5/d/9/e/5d9efa8003bfc.jpeg",
            "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a65d71.jpeg",
            "https://cdn3.suncamp.eu/5/e/1/5/5e15e8c02953a.jpeg",
            "https://cdn2.suncamp.eu/5/d/9/e/5d9efa8355c11.jpeg",
            "https://cdn3.suncamp.eu/5/d/3/9/5d398d18c25bd.jpeg",
            "https://cdn2.suncamp.eu/5/d/3/9/5d398d1ac6ef9.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ea800.jpeg",
            "https://cdn2.suncamp.eu/5/d/3/9/5d3995a7da26b.jpeg",
            "https://cdn3.suncamp.eu/5/d/3/9/5d3995aa02a5d.jpeg"
        ],
        "geo": {
            "country": "Kroatië",
            "place": "Mali Lošinj",
            "region": "Primorje-Gorski Kotar"
        },
        "reviewScore": "8,2",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 115",
        "price": "€ 115",
        "facilityCategory": [
            {
                "Name": "Avondentertainment",
                "ID": 47
            },
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Geldautomaat",
                "ID": 51
            }
        ],
        "latitude": "44.55586",
        "longitude": "14.44228",
        "locale": "nl_NL",
        "links": [
            {
                "name": "EuroCampings",
                "url": "https://www.eurocampings.nl/kroatie/primorje-gorski-kotar/mali-losinj/camping-poljana-105222/#bookingPriceGridFilter",
                "price": "1.041,00"
            },
            {
                "name": "booking.com",
                "url": "https://www.booking.com/hotel/hr/poljana-camping-village-resort.html",
                "price": "230,00"
            },
            {
                "name": "Suncamp",
                "url": "https://www.suncamp.nl/kroatie/primorje-gorski-kotar/mali-losinj/camping-poljana-105222/",
                "price": "212,00"
            }
        ]
    },
    {
        "campsiteId": 105237,
        "campsiteName": "Camping Zaton Holiday Resort ****",
        "campsiteImages": [
            "https://cdn2.suncamp.eu/5/b/e/1/5be16142e5703.jpeg",
            "https://cdn2.suncamp.eu/5/b/e/1/5be16145163a1.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614704fb1.jpeg",
            "https://cdn2.suncamp.eu/5/b/e/1/5be16148f2796.jpeg",
            "https://cdn3.suncamp.eu/5/b/e/1/5be1614b0715e.jpeg",
            "https://cdn3.suncamp.eu/5/b/e/1/5be1614de253f.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614fce0f3.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be16151b7cee.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1615397756.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be161557cf50.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614314cb3.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be16145386c7.jpeg",
            "https://cdn2.suncamp.eu/5/b/e/1/5be161472a4b0.jpeg",
            "https://cdn3.suncamp.eu/5/b/e/1/5be16149213e6.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614b29fed.jpeg",
            "https://cdn2.suncamp.eu/5/b/e/1/5be1614e10237.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614feff83.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be16151d8a92.jpeg",
            "https://cdn3.suncamp.eu/5/b/e/1/5be16153b9b39.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be161559dc3f.jpeg"
        ],
        "geo": {
            "country": "Kroatië",
            "place": "Zaton",
            "region": "Zadar"
        },
        "reviewScore": "8,1",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 191",
        "price": "€ 191",
        "facilityCategory": [
            {
                "Name": "Avondentertainment",
                "ID": 47
            },
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Fitness",
                "ID": 46
            }
        ],
        "latitude": "44.22806",
        "longitude": "15.16917",
        "locale": "nl_NL",
        "links": [
            {
                "name": "EuroCampings",
                "url": "https://www.eurocampings.nl/kroatie/zadar/zaton/camping-zaton-holiday-resort-105237/",
                "price": "78,30"
            },
            {
                "name": "Suncamp",
                "url": "https://www.suncamp.nl/kroatie/zadar/zaton/camping-zaton-holiday-resort-105237/",
                "price": "311,00"
            },
            {
                "name": "booking.com",
                "url": "https://www.booking.com/hotel/hr/zaton-holiday-resort-mobile-homes.nl.html",
                "price": "320,00"
            }
        ]
    },
    {
        "campsiteId": 109413,
        "campsiteName": "Camping Bella Austria ****",
        "campsiteImages": [
            "https://cdn1.suncamp.eu/5/8/3/c/583c2a02cf993.jpeg",
            "https://cdn1.suncamp.eu/5/8/3/c/583c2a046bab2.jpeg",
            "https://cdn3.suncamp.eu/5/8/3/c/583c2a05e5f2c.jpeg",
            "https://cdn3.suncamp.eu/5/8/3/c/583c2a0759b53.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce2987b.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/b/5e2b02cfb86c1.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/b/5e2b0269d55a8.jpeg",
            "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bc0aeb.jpeg",
            "https://cdn1.suncamp.eu/5/8/3/c/583c2a1e1750d.jpeg",
            "https://cdn3.suncamp.eu/5/8/3/c/583c2a1f7aab6.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn2.suncamp.eu/5/8/3/c/583c2a02edb06.jpeg",
            "https://cdn1.suncamp.eu/5/8/3/c/583c2a048a007.jpeg",
            "https://cdn3.suncamp.eu/5/8/3/c/583c2a0610d17.jpeg",
            "https://cdn2.suncamp.eu/5/8/3/c/583c2a0777e28.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce437f2.jpeg",
            "https://cdn2.suncamp.eu/5/e/2/b/5e2b02cfd31fe.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/b/5e2b0269ee86a.jpeg",
            "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bde671.jpeg",
            "https://cdn2.suncamp.eu/5/8/3/c/583c2a1e3020f.jpeg",
            "https://cdn2.suncamp.eu/5/8/3/c/583c2a1f986bd.jpeg"
        ],
        "geo": {
            "country": "Oostenrijk",
            "place": "Sankt Peter am Kammersberg",
            "region": "Stiermarken"
        },
        "reviewScore": "7,9",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 94",
        "price": "€ 94",
        "facilityCategory": [
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Honden toegestaan",
                "ID": 39
            },
            {
                "Name": "Mindervaliden",
                "ID": 44
            }
        ],
        "latitude": "47.18028",
        "longitude": "14.21528",
        "locale": "nl_NL"
    },
    {
        "campsiteId": 105237,
        "campsiteName": "Camping Zaton Holiday Resort ****",
        "campsiteImages": [
            "https://cdn2.suncamp.eu/5/b/e/1/5be16142e5703.jpeg",
            "https://cdn2.suncamp.eu/5/b/e/1/5be16145163a1.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614704fb1.jpeg",
            "https://cdn2.suncamp.eu/5/b/e/1/5be16148f2796.jpeg",
            "https://cdn3.suncamp.eu/5/b/e/1/5be1614b0715e.jpeg",
            "https://cdn3.suncamp.eu/5/b/e/1/5be1614de253f.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614fce0f3.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be16151b7cee.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1615397756.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be161557cf50.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614314cb3.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be16145386c7.jpeg",
            "https://cdn2.suncamp.eu/5/b/e/1/5be161472a4b0.jpeg",
            "https://cdn3.suncamp.eu/5/b/e/1/5be16149213e6.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614b29fed.jpeg",
            "https://cdn2.suncamp.eu/5/b/e/1/5be1614e10237.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be1614feff83.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be16151d8a92.jpeg",
            "https://cdn3.suncamp.eu/5/b/e/1/5be16153b9b39.jpeg",
            "https://cdn1.suncamp.eu/5/b/e/1/5be161559dc3f.jpeg"
        ],
        "geo": {
            "country": "Kroatië",
            "place": "Zaton",
            "region": "Zadar"
        },
        "reviewScore": "8,1",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 191",
        "price": "€ 191",
        "facilityCategory": [
            {
                "Name": "Avondentertainment",
                "ID": 47
            },
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Fitness",
                "ID": 46
            }
        ],
        "latitude": "44.22806",
        "longitude": "15.16917",
        "locale": "nl_NL",
        "links": [
            {
                "name": "EuroCampings",
                "url": "https://www.eurocampings.nl/kroatie/zadar/zaton/camping-zaton-holiday-resort-105237/",
                "price": "78,30"
            },
            {
                "name": "Suncamp",
                "url": "https://www.suncamp.nl/kroatie/zadar/zaton/camping-zaton-holiday-resort-105237/",
                "price": "311,00"
            },
            {
                "name": "booking.com",
                "url": "https://www.booking.com/hotel/hr/zaton-holiday-resort-mobile-homes.nl.html",
                "price": "320,00"
            }
        ]
    },
    {
        "campsiteId": 110977,
        "campsiteName": "Camping Bi-Village ****",
        "campsiteImages": [
            "https://cdn2.suncamp.eu/5/e/1/7/5e17011a8477c.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/0/5d80b4900558c.jpeg",
            "https://cdn3.suncamp.eu/5/8/7/4/5874c033afae7.jpeg",
            "https://cdn1.suncamp.eu/5/e/1/7/5e17011c70324.jpeg",
            "https://cdn1.suncamp.eu/5/e/1/7/5e17011e48e2a.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6cceaff.jpeg",
            "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6e73930.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/5/5e15bc701d841.jpeg",
            "https://cdn1.suncamp.eu/5/6/f/2/56f250626e863.jpeg",
            "https://cdn2.suncamp.eu/5/8/7/4/5874c04d6ac7f.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn1.suncamp.eu/5/e/1/7/5e17011aa1426.jpeg",
            "https://cdn3.suncamp.eu/5/d/8/0/5d80b4901f40b.jpeg",
            "https://cdn1.suncamp.eu/5/8/7/4/5874c033d2726.jpeg",
            "https://cdn3.suncamp.eu/5/e/1/7/5e17011c8cbb9.jpeg",
            "https://cdn3.suncamp.eu/5/e/1/7/5e17011e67aa7.jpeg",
            "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6ce5e11.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6e906a2.jpeg",
            "https://cdn3.suncamp.eu/5/e/1/5/5e15bc7037228.jpeg",
            "https://cdn1.suncamp.eu/5/6/f/2/56f2506293114.jpeg",
            "https://cdn1.suncamp.eu/5/8/7/4/5874c04d927e3.jpeg"
        ],
        "geo": {
            "country": "Kroatië",
            "place": "Fažana",
            "region": "Istrië"
        },
        "reviewScore": "7,9",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 123",
        "price": "€ 123",
        "facilityCategory": [
            {
                "Name": "Avondentertainment",
                "ID": 47
            },
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Geldautomaat",
                "ID": 51
            }
        ],
        "latitude": "44.91739",
        "longitude": "13.81104",
        "locale": "nl_NL",
        "links": [
            {
                "name": "EuroCampings",
                "url": "https://www.eurocampings.nl/kroatie/istrie/fazana/camping-bi-village-110977/#bookingPriceGridFilter",
                "price": "1.041,30"
            },
            {
                "name": "Suncamp",
                "url": "https://www.suncamp.nl/kroatie/istrie/fazana/camping-bi-village-110977/",
                "price": "250,00"
            },
            {
                "name": "booking.com",
                "url": "https://www.booking.com/hotel/hr/bi-village.nl.html",
                "price": "280,00"
            }
        ]
    },
    {
        "campsiteId": 105177,
        "campsiteName": "Camping Bijela Uvala ****",
        "campsiteImages": [
            "https://cdn1.suncamp.eu/5/e/2/a/5e2add7be14e7.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/a/5e2add7db4a05.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/a/5e2add7f94e23.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/a/5e2add81c82e4.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/a/5e2add83bdc51.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/a/5e2adce4d5ec1.jpeg",
            "https://cdn2.suncamp.eu/5/e/2/a/5e2adce679357.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/a/5e2adce8147d2.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/a/5e2adce9bae6c.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/a/5e2adceb6fc7e.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn3.suncamp.eu/5/e/2/a/5e2add7c0a0e7.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/a/5e2add7dd12f1.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/a/5e2add7fb42a5.jpeg",
            "https://cdn2.suncamp.eu/5/e/2/a/5e2add81e8aef.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/a/5e2add83dbf83.jpeg",
            "https://cdn3.suncamp.eu/5/e/2/a/5e2adce4f33e8.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/a/5e2adce69362c.jpeg",
            "https://cdn2.suncamp.eu/5/e/2/a/5e2adce82f846.jpeg",
            "https://cdn2.suncamp.eu/5/e/2/a/5e2adce9d6775.jpeg",
            "https://cdn1.suncamp.eu/5/e/2/a/5e2adceb88d25.jpeg"
        ],
        "geo": {
            "country": "Kroatië",
            "place": "Poreč",
            "region": "Istrië"
        },
        "reviewScore": "8,2",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 92",
        "price": "€ 92",
        "facilityCategory": [
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Fitness",
                "ID": 46
            },
            {
                "Name": "Geldautomaat",
                "ID": 51
            }
        ],
        "latitude": "45.19153",
        "longitude": "13.59689",
        "locale": "nl_NL",
        "links": [
            {
                "name": "EuroCampings",
                "url": "https://www.eurocampings.nl/kroatie/istrie/porec/camping-bijela-uvala-105177/",
                "price": "78,30"
            },
            {
                "name": "Suncamp",
                "url": "https://www.suncamp.nl/kroatie/istrie/porec/camping-bijela-uvala-105177/",
                "price": "177,00"
            },
            {
                "name": "booking.com",
                "url": "https://www.booking.com/hotel/hr/caravans-bijela-uvala-porec.nl.html",
                "price": "300"
            }
        ]
    },
    {
        "campsiteId": 100684,
        "campsiteName": "Camping Terme Catez *****",
        "campsiteImages": [
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a108c03a00.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a108dd06a4.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a108f977ba.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a10918f8d4.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093a9022.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a116a70bfa.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a116c712c4.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a116e6c2cc.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a117057b29.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a11724fe5e.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a108c21c35.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a108dee84d.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a108fb302f.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a1091af76c.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093e71bd.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a116a9247f.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a116c9106c.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a116e89af6.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a117074d37.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a11726dfa2.jpeg"
        ],
        "geo": {
            "country": "Slovenië",
            "place": "Čatež ob Savi",
            "region": null
        },
        "reviewScore": "7,8",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 175",
        "price": "€ 175",
        "facilityCategory": [
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Fitness",
                "ID": 46
            },
            {
                "Name": "Geldautomaat",
                "ID": 51
            }
        ],
        "latitude": "45.89111",
        "longitude": "15.62575",
        "locale": "nl_NL",
        "links": [
            {
                "name": "terme-catez",
                "url": "https://www.terme-catez.si/en/",
                "price": "56,20"
            },
            {
                "name": "EuroCamping",
                "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                "price": "30,00"
            },
            {
                "name": "Suncamp",
                "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                "price": "35,00"
            }
        ]
    },
    {
        "campsiteId": 106750,
        "campsiteName": "Camping Vilanova Park Cat.1",
        "campsiteImages": [
            "https://cdn3.suncamp.eu/5/c/8/f/5c8f9937a9c35.jpeg",
            "https://cdn3.suncamp.eu/5/d/8/9/5d89cb059dac3.jpeg",
            "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a4c216.jpeg",
            "https://cdn2.suncamp.eu/5/c/8/f/5c8f993bb1f57.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89cb075e5fa.jpeg",
            "https://cdn3.suncamp.eu/5/d/8/9/5d89cb08e59ac.jpeg",
            "https://cdn3.suncamp.eu/5/d/8/9/5d89cb0a79f4d.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89cb0c05c32.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cd929e.jpeg",
            "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d5171f.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn2.suncamp.eu/5/c/8/f/5c8f9937bf61f.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/9/5d89cb05b221f.jpeg",
            "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a655f2.jpeg",
            "https://cdn1.suncamp.eu/5/c/8/f/5c8f993bc8fe9.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/9/5d89cb077383a.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89cb090758e.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0a90619.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0c1da61.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cf35cb.jpeg",
            "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d68279.jpeg"
        ],
        "geo": {
            "country": "Spanje",
            "place": "Vilanova i la Geltrú",
            "region": "Catalonië"
        },
        "reviewScore": "8,1",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 131",
        "price": "€ 131",
        "facilityCategory": [
            {
                "Name": "Avondentertainment",
                "ID": 47
            },
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Fitness",
                "ID": 46
            }
        ],
        "latitude": "41.23167",
        "longitude": "1.69106",
        "locale": "nl_NL",
        "links": [
            {
                "name": "vilanovapark",
                "url": "https://www.vilanovapark.com/",
                "price": "62,00"
            },
            {
                "name": "EuroCamping",
                "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                "price": "30,00"
            },
            {
                "name": "Suncamp",
                "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                "price": "30,00"
            }
        ]
    },
    {
        "campsiteId": 109105,
        "campsiteName": "Camping La Vallée Verte *****",
        "campsiteImages": [
            "https://cdn1.suncamp.eu/5/7/1/7/57172129969f5.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/0/5c40633060554.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/0/5c40633208b93.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/0/5c406333b6196.jpeg",
            "https://cdn2.suncamp.eu/5/e/4/5/5e4514e56d36e.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/0/5c4063373723a.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/0/5c406339b0711.jpeg",
            "https://cdn1.suncamp.eu/5/e/4/5/5e4514e7778b8.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/0/5c40633ce469f.jpeg",
            "https://cdn2.suncamp.eu/5/7/1/7/5717213a43b3b.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn1.suncamp.eu/5/7/1/7/57172129b18ea.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/0/5c4063307aa85.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/0/5c40633223e34.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/0/5c406333d1dd2.jpeg",
            "https://cdn1.suncamp.eu/5/e/4/5/5e4514e58fd2a.jpeg",
            "https://cdn3.suncamp.eu/5/c/4/0/5c40633753028.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/0/5c406339c9cc7.jpeg",
            "https://cdn2.suncamp.eu/5/e/4/5/5e4514e7956e1.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/0/5c40633d0bdb6.jpeg",
            "https://cdn1.suncamp.eu/5/7/1/7/5717213a6172e.jpeg"
        ],
        "geo": {
            "country": "Frankrijk",
            "place": "La Roque-sur-Cèze",
            "region": "Languedoc-Roussillon"
        },
        "reviewScore": "8,0",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 124",
        "price": "€ 124",
        "facilityCategory": [
            {
                "Name": "Avondentertainment",
                "ID": 47
            },
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Geldautomaat",
                "ID": 51
            },
            {
                "Name": "Honden toegestaan",
                "ID": 39
            }
        ],
        "latitude": "44.18132",
        "longitude": "4.53537",
        "locale": "nl_NL",
        "links": [
            {
                "name": "EuroCampings",
                "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                "price": "78,30"
            },
            {
                "name": "Suncamp",
                "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                "price": "80,00"
            },
            {
                "name": "booking.com",
                "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                "price": "90,00"
            }
        ]
    },
    {
        "campsiteId": 104032,
        "campsiteName": "Camping Le Sérignan Plage *****",
        "campsiteImages": [
            "https://cdn1.suncamp.eu/5/c/a/e/5caefed8b8955.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/a/5c4ae497911f8.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49915a09.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a7c761.jpeg",
            "https://cdn3.suncamp.eu/5/c/4/a/5c4ae49c02c89.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54d9e4e1.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/a/5c4ae54f54675.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae550f2795.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae55299fe1.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5543d115.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn1.suncamp.eu/5/c/a/e/5caefed8cf118.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae497aaa74.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae4992c179.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a96020.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49c1a630.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54dba52a.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54f72fa6.jpeg",
            "https://cdn3.suncamp.eu/5/c/4/a/5c4ae55119b0b.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae552b599a.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5545925e.jpeg"
        ],
        "geo": {
            "country": "Frankrijk",
            "place": "Sérignan",
            "region": "Languedoc-Roussillon"
        },
        "reviewScore": "8,2",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 273",
        "price": "€ 273",
        "facilityCategory": [
            {
                "Name": "Avondentertainment",
                "ID": 47
            },
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Fitness",
                "ID": 46
            }
        ],
        "latitude": "43.26389",
        "longitude": "3.32119",
        "locale": "nl_NL",
        "links": [
            {
                "name": "EuroCampings",
                "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/herault/serignan-plage/camping-le-serignan-plage-nature-104031/",
                "price": "69,30"
            },
            {
                "name": "Suncamp",
                "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/herault/serignan-plage/camping-le-serignan-plage-nature-104031/",
                "price": "80,00"
            },
            {
                "name": "booking.com",
                "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/herault/serignan-plage/camping-le-serignan-plage-nature-104031/",
                "price": "100,00"
            }
        ]
    },
    {
        "campsiteId": 117804,
        "campsiteName": "Camping Praha Klánovice",
        "campsiteImages": [
            "https://cdn3.suncamp.eu/5/8/7/7/58779ea1bcdda.jpeg",
            "https://cdn2.suncamp.eu/5/d/0/0/5d00d2e09d9ac.jpeg",
            "https://cdn1.suncamp.eu/5/8/7/7/58779ea4d4159.jpeg",
            "https://cdn3.suncamp.eu/5/d/0/0/5d00d2e298b3d.jpeg",
            "https://cdn3.suncamp.eu/5/c/4/9/5c49941741c9f.jpeg",
            "https://cdn3.suncamp.eu/5/c/4/9/5c499418f0a48.jpeg",
            "https://cdn1.suncamp.eu/5/8/7/7/58779ea7ad78f.jpeg",
            "https://cdn2.suncamp.eu/5/8/7/7/58779ea855f31.jpeg",
            "https://cdn2.suncamp.eu/5/8/7/7/58779ea977e96.jpeg",
            "https://cdn2.suncamp.eu/5/8/7/7/58779eaab399f.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn3.suncamp.eu/5/8/7/7/58779ea1e41cf.jpeg",
            "https://cdn2.suncamp.eu/5/d/0/0/5d00d2e0ba139.jpeg",
            "https://cdn2.suncamp.eu/5/8/7/7/58779ea5024f7.jpeg",
            "https://cdn2.suncamp.eu/5/d/0/0/5d00d2e2b93d7.jpeg",
            "https://cdn2.suncamp.eu/5/c/4/9/5c4994175d21c.jpeg",
            "https://cdn1.suncamp.eu/5/c/4/9/5c49941919e00.jpeg",
            "https://cdn2.suncamp.eu/5/8/7/7/58779ea7cdbde.jpeg",
            "https://cdn1.suncamp.eu/5/8/7/7/58779ea874d5b.jpeg",
            "https://cdn2.suncamp.eu/5/8/7/7/58779ea98c055.jpeg",
            "https://cdn3.suncamp.eu/5/8/7/7/58779eaace64c.jpeg"
        ],
        "geo": {
            "country": "Tsjechië",
            "place": "Klánovice",
            "region": "Praag"
        },
        "reviewScore": "7,6",
        "isSunlodgeCategory": false,
        "isGlampingCategory": false,
        "discount": 0,
        "fromPrice": "€ 136",
        "price": "€ 136",
        "facilityCategory": [
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Golf/Midgetgolf",
                "ID": 50
            },
            {
                "Name": "Honden toegestaan",
                "ID": 39
            }
        ],
        "latitude": "50.09858",
        "longitude": "14.685",
        "locale": "nl_NL"
    },
    {
        "campsiteId": 111285,
        "campsiteName": "Camping Le Rotja ***",
        "campsiteImages": [
            "https://cdn2.suncamp.eu/5/c/6/b/5c6bd1abcac17.jpeg",
            "https://cdn2.suncamp.eu/5/6/6/a/566a6c2c4018c.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c2e59823.jpeg",
            "https://cdn1.suncamp.eu/5/6/6/a/566a6c2fac3e6.jpeg",
            "https://cdn2.suncamp.eu/5/6/6/a/566a6c3111d39.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c356e3b0.jpeg",
            "https://cdn2.suncamp.eu/5/6/6/a/566a6c395ac37.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c3aeb829.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c3c112bf.jpeg",
            "https://cdn2.suncamp.eu/5/6/6/a/566a6c3d1fb79.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn1.suncamp.eu/5/c/6/b/5c6bd1abe3173.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c2c5cca7.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c2e7ae1a.jpeg",
            "https://cdn1.suncamp.eu/5/6/6/a/566a6c2fc9adc.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c312940a.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c35cb507.jpeg",
            "https://cdn1.suncamp.eu/5/6/6/a/566a6c398b8c8.jpeg",
            "https://cdn2.suncamp.eu/5/6/6/a/566a6c3b13023.jpeg",
            "https://cdn2.suncamp.eu/5/6/6/a/566a6c3c29b54.jpeg",
            "https://cdn3.suncamp.eu/5/6/6/a/566a6c3d37385.jpeg"
        ],
        "geo": {
            "country": "Frankrijk",
            "place": "Fuilla",
            "region": "Languedoc-Roussillon"
        },
        "reviewScore": "8,7",
        "isSunlodgeCategory": false,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 117",
        "price": "€ 117",
        "facilityCategory": [
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Geldautomaat",
                "ID": 51
            },
            {
                "Name": "Honden toegestaan",
                "ID": 39
            }
        ],
        "latitude": "42.56239",
        "longitude": "2.35944",
        "locale": "nl_NL"
    },
    {
        "campsiteId": 105172,
        "campsiteName": "Camping Park Umag ****",
        "campsiteImages": [
            "https://cdn1.suncamp.eu/5/e/7/3/5e737402e2a12.jpeg",
            "https://cdn3.suncamp.eu/5/e/7/3/5e7374050133b.jpeg",
            "https://cdn2.suncamp.eu/5/e/7/3/5e737406f2e15.jpeg",
            "https://cdn2.suncamp.eu/5/e/7/3/5e737408f411d.jpeg",
            "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03885f5.jpeg",
            "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9056113.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd96226c0.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd97f137d.jpeg",
            "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd99d549d.jpeg",
            "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9bbe04d.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn2.suncamp.eu/5/e/7/3/5e7374030eb42.jpeg",
            "https://cdn2.suncamp.eu/5/e/7/3/5e737405219b5.jpeg",
            "https://cdn3.suncamp.eu/5/e/7/3/5e7374071e3c6.jpeg",
            "https://cdn2.suncamp.eu/5/e/7/3/5e73740927241.jpeg",
            "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03a8fb2.jpeg",
            "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9071f7b.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd963ff10.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd981a0c1.jpeg",
            "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9a02df8.jpeg",
            "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd9bdb8a6.jpeg"
        ],
        "geo": {
            "country": "Kroatië",
            "place": "Umag",
            "region": "Istrië"
        },
        "reviewScore": "8,1",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 88",
        "price": "€ 88",
        "facilityCategory": [
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Geldautomaat",
                "ID": 51
            },
            {
                "Name": "Golf/Midgetgolf",
                "ID": 50
            }
        ],
        "latitude": "45.36717",
        "longitude": "13.54731",
        "locale": "nl_NL",
        "links": [
            {
                "name": "EuroCampings",
                "url": "https://www.eurocampings.nl/kroatie/istrie/umag/camping-park-umag-105172/",
                "price": "78,30"
            },
            {
                "name": "Suncamp",
                "url": "https://www.suncamp.nl/kroatie/istrie/umag/camping-park-umag-105172/",
                "price": "180,00"
            },
            {
                "name": "booking.com",
                "url": "https://www.booking.com/hotel/hr/mobile-homes-park-umag.nl.html",
                "price": "176,00"
            }
        ]
    },
    {
        "campsiteId": 100684,
        "campsiteName": "Camping Terme Catez *****",
        "campsiteImages": [
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a108c03a00.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a108dd06a4.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a108f977ba.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a10918f8d4.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093a9022.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a116a70bfa.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a116c712c4.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a116e6c2cc.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a117057b29.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a11724fe5e.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a108c21c35.jpeg",
            "https://cdn2.suncamp.eu/5/e/6/a/5e6a108dee84d.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a108fb302f.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a1091af76c.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093e71bd.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a116a9247f.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a116c9106c.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a116e89af6.jpeg",
            "https://cdn1.suncamp.eu/5/e/6/a/5e6a117074d37.jpeg",
            "https://cdn3.suncamp.eu/5/e/6/a/5e6a11726dfa2.jpeg"
        ],
        "geo": {
            "country": "Slovenië",
            "place": "Čatež ob Savi",
            "region": null
        },
        "reviewScore": "7,8",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 204",
        "price": "€ 204",
        "facilityCategory": [
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Fitness",
                "ID": 46
            },
            {
                "Name": "Geldautomaat",
                "ID": 51
            }
        ],
        "latitude": "45.89111",
        "longitude": "15.62575",
        "locale": "nl_NL"
    },
    {
        "campsiteId": 106750,
        "campsiteName": "Camping Vilanova Park Cat.1",
        "campsiteImages": [
            "https://cdn3.suncamp.eu/5/c/8/f/5c8f9937a9c35.jpeg",
            "https://cdn3.suncamp.eu/5/d/8/9/5d89cb059dac3.jpeg",
            "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a4c216.jpeg",
            "https://cdn2.suncamp.eu/5/c/8/f/5c8f993bb1f57.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89cb075e5fa.jpeg",
            "https://cdn3.suncamp.eu/5/d/8/9/5d89cb08e59ac.jpeg",
            "https://cdn3.suncamp.eu/5/d/8/9/5d89cb0a79f4d.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89cb0c05c32.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cd929e.jpeg",
            "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d5171f.jpeg"
        ],
        "campsiteRetinaImages": [
            "https://cdn2.suncamp.eu/5/c/8/f/5c8f9937bf61f.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/9/5d89cb05b221f.jpeg",
            "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a655f2.jpeg",
            "https://cdn1.suncamp.eu/5/c/8/f/5c8f993bc8fe9.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/9/5d89cb077383a.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89cb090758e.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0a90619.jpeg",
            "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0c1da61.jpeg",
            "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cf35cb.jpeg",
            "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d68279.jpeg"
        ],
        "geo": {
            "country": "Spanje",
            "place": "Vilanova i la Geltrú",
            "region": "Catalonië"
        },
        "reviewScore": "8,1",
        "isSunlodgeCategory": true,
        "isGlampingCategory": true,
        "discount": 0,
        "fromPrice": "€ 131",
        "price": "€ 131",
        "facilityCategory": [
            {
                "Name": "Avondentertainment",
                "ID": 47
            },
            {
                "Name": "Barbecueën",
                "ID": 36
            },
            {
                "Name": "Campingwinkel",
                "ID": 37
            },
            {
                "Name": "Fietsen en Wandelen",
                "ID": 53
            },
            {
                "Name": "Fitness",
                "ID": 46
            }
        ],
        "latitude": "41.23167",
        "longitude": "1.69106",
        "locale": "nl_NL"
    }
];

export const categoryJson = [
    {
        "id": "1",
        "name": "Tenten",
        "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-tenten-2.jpg",
        "key": "Tenten",
        "subCategories": [
            {
                "id": "1.1",
                "name": "Koepeltenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-koepeltenten.png",
                "resultList": [
                    {
                        "id": "1.1.1",
                        "name": "Obelink Lugano 6 Plus tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/2/428850-428850-images_main-obelink_lugano_6-ecommerce.jpg",
                        "price": "199,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "513",
                        "features": [
                            "Aantal personen 6",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 21.2 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-lugano-6-plus-tunneltent.html",
                                "price": "199,00"
                            },
                            {
                                "name": "Frank",
                                "url": "https://www.frank.nl/coleman-cook-6-tunneltent/223656?bl3nlclid=1f1d0ba3-7844-4203-a0b4-8949a56c2623&lgw_code=17516-223656&utm_medium=cpc&utm_source=beslist_nl&utm_term=17516-223656",
                                "price": "333,00"
                            },
                            {
                                "name": "Ebay",
                                "url": "https://www.ebay-kleinanzeigen.de/s-anzeige/obelink-lugano-6-plus-tunnelzelt-mit-vordach/1341927309-230-1359",
                                "price": "195,00"
                            }
                        ]
                    },
                    {
                        "id": "1.1.2",
                        "name": "Obelink Napels 4 tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/2/428850-428850-images_main-obelink_lugano_6-ecommerce.jpg",
                        "price": "99,00",
                        "discount": null,
                        "rating": 4.5,
                        "ratingCount": "102",
                        "features": [
                            "Aantal personen 4",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 10.2 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-napels-4-tunneltent.html",
                                "price": "119,00"
                            },
                            {
                                "name": "Ebay",
                                "url": "https://www.ebay.nl/itm/Boutique-Camping-Tents-4m-Luna-Bell-Tent-Sandstone/183870618657?hash=item2acf8afc21:g:kswAAOSwbglc2~Rp",
                                "price": "839,00"
                            },
                            {
                                "name": "Frank",
                                "url": "https://www.tent-expert.nl/highlander-aeolus-4-opblaasbare-tunneltent",
                                "price": "610,85"
                            }
                        ]
                    },
                    {
                        "id": "1.1.3",
                        "name": "Coleman Coastline 3 plus tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/1/8/187863-187863-images_main-pop_coleman_coastline3plus-ecommerce.jpg",
                        "price": "115,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [
                            "Aantal personen 3",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 7 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/coleman-coastline-3-plus-tunneltent.html",
                                "price": "119,00"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.co.uk/Coleman-Coastline-Tent-Green-Person/dp/B0030BG9QA",
                                "price": "839,00"
                            },
                            {
                                "name": "Alternate",
                                "url": "https://www.alternate.nl/Coleman/Coastline-3-Plus-tent/html/product/1355616",
                                "price": "129,90"
                            }
                        ]
                    },
                    {
                        "id": "1.1.4",
                        "name": "Obelink Lugano 6 XL Excellent tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/6/467130-467130-images_main-obelink_lugano_6_xl-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "48",
                        "features": [
                            "Aantal personen 6",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 24.4 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-lugano-6-xl-excellent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/vidaxl-kampeertent-6-tunneltent-6-persoons-blauw-geel/9200000032252075/?Referrer=ADVNLGBL000&utm_source=google&utm_medium=cpc&utm_campaign=beslist-Fiets-Kamperen-en-Outdoor-Hardwaren&utm_term=9200000032252075",
                                "price": "123,99"
                            }
                        ]
                    },
                    {
                        "id": "1.1.5",
                        "name": "Obelink Lodge 180 tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/3/435349-435349-images_main-obelink_lodge_180-ecommerce.jpg",
                        "price": "89,00",
                        "rating": 4.4,
                        "discount": "198,00",
                        "ratingCount": "42",
                        "features": [
                            "Aantal personen 3",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 9 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-lodge-180-tunneltent.html",
                                "price": "89,00"
                            },
                            {
                                "name": "Sport bay",
                                "url": "https://sportbay.nl/nl/pr/Husky-Bromer-4-tunneltent/1574",
                                "price": "229,00"
                            },
                            {
                                "name": "Internet Sport & Causuals",
                                "url": "https://www.internet-sportandcasuals.com/214424-dutch-mountains-grebbeberg-4-persoons-tunneltent-460-cm-blauw/?ecg=a06narKNuQASuCWcsBS4b11F1lHUXIp0BcnCDgT-R-MXL-2JZoa8fc7VLAjBikNG3LhtXUbEaPp7XV6HST_QHzMznDs8i-eVR0kA0oaGdISNvs_nX7V6-YFmDofFbrhYtedA4lMQn0vxJ4crYvE8K1Kb5B8zVWcy_8FiGxGPZmKN7jmjDWQljBFvbETMJWkAE3o172AQxqH7Dat2smuQiz0w61bJEZrq0cN_bxkuanikKKhnFBHiejCdt8C4F1CcJud2Ns11H3UQu9k0NXLPNcDbgAQgD7G1qGW1Zig64nUlPDvxDDI5Nlx3A3UJCfwKj2dRvULA6zQzdvlYqZ4_YQiF-cZB9Z6lSEy5kwQ_k0BxQxeKxi3mKcT_MVFuXxHGekyghJsHBDBt1LELou9FuzBNBhJmdAGQ53A8oFfIIh7OY_zexG1KNUXxi06OLEz_6Hg9zATnBaYuDMHseqMZyyZpw-64gLay68VfTDw58X9jqmOWut5cO1folqGhoknXy_17-7sHMu6p270GFExDR6-pef_hs2xCS9LK6QYQVehdqzyeOaxqojZZx4K6Vp0D&utm_campaign=Dutch+Mountains&utm_content=Kamperen+%7C+Tenten&utm_medium=cpc&utm_source=marktplaats&utm_term=214424#",
                                "price": "161,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "1.2",
                "name": "Pop-up tenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-pop-up-tenten.png",
                "resultList": [
                    {
                        "id": "1.2.1",
                        "name": "Obelink Duo 4 pop-up tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/0/502457-502457-images_main-obelink_duo_4_pop_up_1-ecommerce.jpg",
                        "price": "139,00",
                        "discount": null,
                        "rating": 4.1,
                        "ratingCount": "18",
                        "features": [
                            "Aantal personen 4",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 6 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-duo-4-pop-up-tent.html",
                                "price": "139,00"
                            },
                            {
                                "name": "Decathlon",
                                "url": "https://www.decathlon.nl/p/kampeertent-arpenaz-4-2-4-personen-2-slaapcompartimenten/_/R-p-311103?mc=8562098&utm_source=beslist&utm_medium=shopbot&bl3nlclid=3ff3775c-e176-4c9f-9e2a-b8d048f03a11",
                                "price": "149,99"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/easy-camp-tornado-400-tent-grijs/9200000063153740/?bltgh=s4gk-9pLzeO23ZRdNQlKvQ.1_30.53.ProductTitle",
                                "price": "161,00"
                            }
                        ]
                    },
                    {
                        "id": "1.2.2",
                        "name": "Obelink Dance Pop-up tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/538338-538338-images_main-obelink_dance_popup-ecommerce.jpg",
                        "price": "29,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [
                            "Aantal personen 1",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 2.86 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-dance-pop-up-tent-538338.html",
                                "price": "29,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/maxxgarden-pop-up-tent-festival-camping-tent-2-personen-255x155x95-cm/9200000107940719/?bltgh=s4gk-9pLzeO23ZRdNQlKvQ.1_30.32.ProductTitle",
                                "price": "43,99"
                            }
                        ]
                    },
                    {
                        "id": "1.2.3",
                        "name": "Lief! Lifestyle Stoer! pop-up tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/1/316945-316945-images_main-lief_lifestyle_popup_tent_stoer_jongens-ecommerce.jpg",
                        "price": "39,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "1",
                        "features": [
                            "Aantal personen 1",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 2.1 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/lief-lifestyle-stoer-pop-up-tent.html",
                                "price": "39,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/pop-up-tent-245-x-145-x-95-cm-waterdicht-uv-beschermd/9200000084951672/?bltgh=s4gk-9pLzeO23ZRdNQlKvQ.1_30.31.ProductTitle",
                                "price": "34,40"
                            }
                        ]
                    },
                    {
                        "id": "1.2.4",
                        "name": "Coleman Galiano 2 pop-up tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/3/430486-430486-images_main-coleman_galiano_2-ecommerce.jpg",
                        "price": "57,90",
                        "discount": null,
                        "rating": 4.2,
                        "ratingCount": "28",
                        "features": [
                            "Aantal personen 2",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 2.6 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/coleman-galiano-2-pop-up-tent.html",
                                "price": "57,90"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.com/Coleman-2000014781-2-Person-Pop-Up-Tent/dp/B00FGPY9CE",
                                "price": "48,60"
                            },
                            {
                                "name": "Bever",
                                "url": "https://www.bever.nl/p/coleman-galiano-2-pop-up-tent-MAABC70015.html?channable=e36467.MjI5OTc1XzMwMw&colour=303&utm_source=beslist&utm_medium=catalogue_shop&utm_campaign=beslist&utm_term=Tenten&utm_content=229975_303",
                                "price": "89,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/coleman-tunneltent-galiano-2-2-persoons/9200000063209617/?bltgh=p4aNWmaKI-pE3oVh2ZVKgQ.1_4.5.ProductTitle",
                                "price": "75,57"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "1.3",
                "name": "Bell tenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-bell-tenten.png",
                "resultList": [
                    {
                        "id": "1.3.1",
                        "name": "Obelink Sahara 400 Bell tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/1/2/123895-123895-images_main-obelink_sahara_400_101-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "71",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sahara-400-bell-tent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "AliExpress",
                                "url": "https://nl.aliexpress.com/item/32279588317.html",
                                "price": "429,00"
                            },
                            {
                                "name": "Belltent Boutique",
                                "url": "https://belltentboutique.co.uk/products/4m-bell-tent?variant=15864271667243",
                                "price": "379,90"
                            }
                        ]
                    },
                    {
                        "id": "1.3.2",
                        "name": "Obelink Sahara 400 Ultimate Bell tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/1/6/169282-169282-images_main-obelink_sahara_400_ultimate_101-ecommerce.jpg",
                        "price": "489,00",
                        "discount": null,
                        "rating": 4,
                        "ratingCount": "17",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sahara-400-ultimate-bell-tent.html",
                                "price": "489,00"
                            },
                            {
                                "name": "Marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1523056791-bell-tent-sibley-400-ultimate.html?c=be2da871b0d84f75c3056cc6db858f31&previousPage=lr",
                                "price": "400,00"
                            },
                            {
                                "name": "CanvasCamp",
                                "url": "https://www.canvascamp.com/nl/sibley-400-ultimate",
                                "price": "549,00"
                            }
                        ]
                    },
                    {
                        "id": "1.3.3",
                        "name": "Obelink Sahara 300 Deluxe Bell tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/6/469272-469272-images_main-obelink_sahara_300_deluxe_101-ecommerce.jpg",
                        "price": "345,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sahara-300-deluxe-bell-tent.html",
                                "price": "345,00"
                            },
                            {
                                "name": "Belltent",
                                "url": "https://belltent.co.uk/products/3-metre-deluxe-bell-tent",
                                "price": "341,10"
                            },
                            {
                                "name": "Ebay",
                                "url": "https://www.ebay.nl/itm/3m-Deluxe-Bell-Tent-/172958759822",
                                "price": "799,00"
                            }
                        ]
                    },
                    {
                        "id": "1.3.4",
                        "name": "Obelink Sahara 300 Ultimate Bell tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/6/467602-467602-images_main-obelink_sahara_300_ultimate_101-ecommerce.jpg",
                        "price": "369,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "48",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sahara-300-ultimate-bell-tent.html",
                                "price": "369,00"
                            },
                            {
                                "name": "Belltent",
                                "url": "https://belltent.co.uk/products/3-metre-ultimate-bell-tent",
                                "price": "359,10"
                            },
                            {
                                "name": "Ebay",
                                "url": "https://www.ebay.nl/itm/Bell-Tent-Village-3m-Cotton-Canvas-Bell-Tents-With-Fire-Retardant-Stove-Hole/123872122047?hash=item1cd75a94bf:g:zvgAAOSwoutcnULG",
                                "price": "299,99"
                            }
                        ]
                    },
                    {
                        "id": "1.3.5",
                        "name": "Obelink Sahara 300 Bell tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/8/288760-288760-images_main-obelink_sahara_300_101-ecommerce.jpg",
                        "price": "279,00",
                        "discount": null,
                        "rating": 4.6,
                        "ratingCount": "7",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sahara-300-bell-tent.html",
                                "price": "279,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "1.4",
                "name": "Tunneltenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-tunneltenten.png",
                "resultList": [
                    {
                        "id": "1.4.1",
                        "name": "Obelink Lugano 6 Plus tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/2/428850-428850-images_main-obelink_lugano_6-ecommerce.jpg",
                        "price": "199,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "513",
                        "features": [
                            "Aantal personen 6",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 21.2 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-lugano-6-plus-tunneltent.html",
                                "price": "199,00"
                            },
                            {
                                "name": "Frank",
                                "url": "https://www.frank.nl/coleman-cook-6-tunneltent/223656?bl3nlclid=1f1d0ba3-7844-4203-a0b4-8949a56c2623&lgw_code=17516-223656&utm_medium=cpc&utm_source=beslist_nl&utm_term=17516-223656",
                                "price": "333,00"
                            },
                            {
                                "name": "Ebay",
                                "url": "https://www.ebay-kleinanzeigen.de/s-anzeige/obelink-lugano-6-plus-tunnelzelt-mit-vordach/1341927309-230-1359",
                                "price": "195,00"
                            }
                        ]
                    },
                    {
                        "id": "1.4.2",
                        "name": "Obelink Napels 4 tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/7/273676-273676-images_main-obelink_napels4grey-ecommerce.jpg",
                        "price": "99,00",
                        "discount": null,
                        "rating": 4,
                        "ratingCount": "102",
                        "features": [
                            "Aantal personen 4",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 10.2 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-napels-4-tunneltent.html",
                                "price": "119,00"
                            },
                            {
                                "name": "Ebay",
                                "url": "https://www.ebay.nl/itm/Boutique-Camping-Tents-4m-Luna-Bell-Tent-Sandstone/183870618657?hash=item2acf8afc21:g:kswAAOSwbglc2~Rp",
                                "price": "839,00"
                            },
                            {
                                "name": "Frank",
                                "url": "https://www.tent-expert.nl/highlander-aeolus-4-opblaasbare-tunneltent",
                                "price": "610,85"
                            }
                        ]
                    },
                    {
                        "id": "1.4.3",
                        "name": "Coleman Coastline 3 plus tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/1/8/187863-187863-images_main-pop_coleman_coastline3plus-ecommerce.jpg",
                        "price": "155,00",
                        "discount": "169,99",
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [
                            "Aantal personen 3",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 7 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/coleman-coastline-3-plus-tunneltent.html",
                                "price": "119,00"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.co.uk/Coleman-Coastline-Tent-Green-Person/dp/B0030BG9QA",
                                "price": "839,00"
                            },
                            {
                                "name": "Alternate",
                                "url": "https://www.alternate.nl/Coleman/Coastline-3-Plus-tent/html/product/1355616",
                                "price": "129,90"
                            }
                        ]
                    },
                    {
                        "id": "1.4.4",
                        "name": "Obelink Lugano 6 XL Excellent tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/6/467130-467130-images_main-obelink_lugano_6_xl-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "48",
                        "features": [
                            "Aantal personen 6",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 24.4 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-lugano-6-xl-excellent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/vidaxl-kampeertent-6-tunneltent-6-persoons-blauw-geel/9200000032252075/?Referrer=ADVNLGBL000&utm_source=google&utm_medium=cpc&utm_campaign=beslist-Fiets-Kamperen-en-Outdoor-Hardwaren&utm_term=9200000032252075",
                                "price": "123,99"
                            }
                        ]
                    },
                    {
                        "id": "1.4.5",
                        "name": "Obelink Lodge 180 tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/3/435349-435349-images_main-obelink_lodge_180-ecommerce.jpg",
                        "price": "89,00",
                        "discount": "198,00",
                        "rating": 4.8,
                        "ratingCount": "42",
                        "features": [
                            "Aantal personen 3",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 9 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-lodge-180-tunneltent.html",
                                "price": "89,00"
                            },
                            {
                                "name": "Sport bay",
                                "url": "https://sportbay.nl/nl/pr/Husky-Bromer-4-tunneltent/1574",
                                "price": "229,00"
                            },
                            {
                                "name": "Internet Sport & Causuals",
                                "url": "https://www.internet-sportandcasuals.com/214424-dutch-mountains-grebbeberg-4-persoons-tunneltent-460-cm-blauw/?ecg=a06narKNuQASuCWcsBS4b11F1lHUXIp0BcnCDgT-R-MXL-2JZoa8fc7VLAjBikNG3LhtXUbEaPp7XV6HST_QHzMznDs8i-eVR0kA0oaGdISNvs_nX7V6-YFmDofFbrhYtedA4lMQn0vxJ4crYvE8K1Kb5B8zVWcy_8FiGxGPZmKN7jmjDWQljBFvbETMJWkAE3o172AQxqH7Dat2smuQiz0w61bJEZrq0cN_bxkuanikKKhnFBHiejCdt8C4F1CcJud2Ns11H3UQu9k0NXLPNcDbgAQgD7G1qGW1Zig64nUlPDvxDDI5Nlx3A3UJCfwKj2dRvULA6zQzdvlYqZ4_YQiF-cZB9Z6lSEy5kwQ_k0BxQxeKxi3mKcT_MVFuXxHGekyghJsHBDBt1LELou9FuzBNBhJmdAGQ53A8oFfIIh7OY_zexG1KNUXxi06OLEz_6Hg9zATnBaYuDMHseqMZyyZpw-64gLay68VfTDw58X9jqmOWut5cO1folqGhoknXy_17-7sHMu6p270GFExDR6-pef_hs2xCS9LK6QYQVehdqzyeOaxqojZZx4K6Vp0D&utm_campaign=Dutch+Mountains&utm_content=Kamperen+%7C+Tenten&utm_medium=cpc&utm_source=marktplaats&utm_term=214424#",
                                "price": "161,00"
                            }
                        ]
                    },
                    {
                        "id": "1.4.6",
                        "name": "Obelink Manitoba 6 tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/5/459560-459560-images_main-obelink_manitoba6_tc-ecommerce.jpg",
                        "price": "595,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "42",
                        "features": [
                            "Aantal personen 6",
                            "Materiaal buitendoek Polyester",
                            "Totaalgewicht 37.9 kg"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-manitoba-6-tunneltent.html",
                                "price": "595,00"
                            },
                            {
                                "name": "Marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1525489195-obelink-manitoba-6-tunneltent.html",
                                "price": "229,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/cook-4/9200000040365118/?bltgh=qlHMB5OQDxs2-FIII5NJ-w.1_4.5.ProductTitle",
                                "price": "249,99"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "1.5",
                "name": "Schuurtenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-schuurtenten.png",
                "resultList": [
                    {
                        "id": "1.5.1",
                        "name": "Obelink Garda 210 schuurtent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/9/392099-392099-images_main-obelinkgarda210-ecommerce.jpg",
                        "price": "199,00",
                        "discount": "398,00",
                        "rating": 5,
                        "ratingCount": "1",
                        "features": [
                            "Lengte 210 cm",
                            "Materiaal buitendoek PVC",
                            "Breedte 210 cm"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-garda-210-schuurtent.html",
                                "price": "199,00"
                            },
                            {
                                "name": "Marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/a1296898435-bardani-faro-schuurtent.html?c=c31218e179beb5dcf8f82c79cca58d38&casData=ucWGQJUeJ5GzYPO-yQENe_ldXoMeBJ5cIsG0Rr2sYD4nah5psEyC5kYIrVVWlzdVhANd7mFrVwFPyjDfOQE0lazROutevBYNWsEUkr8UBZoUM7oEYZUwA9GlOGyEBnm15UxrRi4sTOIl2FEJPr9lgGzxzXdEPYyBBJkm-nsBRqsXroaRwHz1iQg5H3nxJo1JKAYvYSqw9U0cS6dvJsL7VkZfnIk9MGtbFY8_BxyH3kI-zdJKZLpRx4wl5bJk314OsmyWyI8P9hhxldmyyZXCHKaYw210AmYV030UIpzhHvkeRjV9lm5102h3FB13IIuKhZMGy3Y6ywFTYovc-sTuEZ0X-HPFsusI1uQ6i7jyLqzMDlbKVLIENRhBiJFNhwpg9Su3MJOdpdqrnAB0lPmlCdSwO8-TR3IX5ZTI8mxnWqCfG6Sr9Dx2df6JboldTXeILt06JaAqjX9RE8fHgfmKbXVyF10PraV4xpcWKWE7FYorzulK-d7fQbbE66ZMDRNbIGtzHX0exPk7nSkN8Nc7aOpvdBMIE_Fx4jAvHAVIphcT9FtSZEZJI6KreHsmI144EpODB_HQlhRW1yts2sl0tw8sFmf5wzD-FapWboLy4O9un7nNO-ItoQocx0ggvIAn&previousPage=lr",
                                "price": "159,00"
                            }
                        ]
                    },
                    {
                        "id": "1.5.2",
                        "name": "Bo-Camp Bike Shelter schuurtent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/5/253948-253948-images_main-bocamp_bikeshelter-ecommerce.jpg",
                        "price": "39,90",
                        "discount": "65,95",
                        "rating": 0,
                        "ratingCount": "17",
                        "features": [
                            "Lengte 180 cm",
                            "Materiaal buitendoek Polyester",
                            "Breedte 85 cm"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/bo-camp-bike-shelter-schuurtent.html",
                                "price": "199,00"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.nl/Bo-Camp-4471900-Bike-shelter/dp/B008L2VTCC",
                                "price": "51,81"
                            },
                            {
                                "name": "Teun",
                                "url": "https://www.teun.nl/nl/179627/bo-camp-bike-shelter/",
                                "price": "54,95"
                            }
                        ]
                    },
                    {
                        "id": "1.5.3",
                        "name": "Hypercamp Casa schuurtent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/7/278004-278004-images_main-hypercamp_casa_grey-ecommerce.jpg",
                        "price": "179,00",
                        "discount": "358,00",
                        "rating": 4.3,
                        "ratingCount": "201",
                        "features": [
                            "Lengte 240 cm",
                            "Materiaal buitendoek PVC",
                            "Breedte 200 cm"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-casa-schuurtent.html",
                                "price": "179,00"
                            },
                            {
                                "name": "Decathlon",
                                "url": "https://www.decathlon.nl/p/dagtent-met-tentbogen-voor-kamperen-arpenaz-base-arpenaz-base-fresh-10-personen/_/R-p-308178",
                                "price": "179,99"
                            }
                        ]
                    },
                    {
                        "id": "1.5.4",
                        "name": "Obelink Sahara 300 Ultimate Bell tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/6/467602-467602-images_main-obelink_sahara_300_ultimate_101-ecommerce.jpg",
                        "price": "179,00",
                        "discount": "358,00",
                        "rating": 4.8,
                        "ratingCount": "126",
                        "features": [
                            "Lengte 230 cm",
                            "Materiaal buitendoek PVC",
                            "Breedte 165 cm"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sahara-300-ultimate-bell-tent.html",
                                "price": "369,00"
                            },
                            {
                                "name": "Belltent",
                                "url": "https://belltent.co.uk/products/3-metre-ultimate-bell-tent",
                                "price": "359,10"
                            },
                            {
                                "name": "Ebay",
                                "url": "https://www.ebay.nl/itm/Bell-Tent-Village-3m-Cotton-Canvas-Bell-Tents-With-Fire-Retardant-Stove-Hole/123872122047?hash=item1cd75a94bf:g:zvgAAOSwoutcnULG",
                                "price": "299,99"
                            }
                        ]
                    },
                    {
                        "id": "1.5.5",
                        "name": "Obelink Outhouse 300 binnentent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/530162-530162-images_main-obelink_outhouse_300_binnentent_1-ecommerce.jpg",
                        "price": "59,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "7",
                        "features": [
                            "Lengte 200 cm",
                            "Materiaal buitendoek Polyester",
                            "Breedte 150 cm"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-outhouse-300-binnentent.html",
                                "price": "59,00"
                            },
                            {
                                "name": "Decathlon",
                                "url": "https://www.decathlon.nl/p/dagtent-met-tentbogen-voor-kamperen-arpenaz-base-arpenaz-base-fresh-10-personen/_/R-p-308178?mc=8554912",
                                "price": "179,99"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "1.6",
                "name": "Partytenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-partytenten.png",
                "resultList": [
                    {
                        "id": "1.6.1",
                        "name": "Coleman Event Shelter L partytent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/9/393070-393070-images_main-colemaneventshelterm-ecommerce.jpg",
                        "price": "145,00",
                        "discount": "299,00",
                        "rating": 5,
                        "ratingCount": "162",
                        "features": [
                            "Lengte 365 cm",
                            "Breedte 365 cm",
                            "Materiaal doek Polyester"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/coleman-event-shelter-l-partytent.html",
                                "price": "145,00"
                            },
                            {
                                "name": "Coleman",
                                "url": "https://www.coleman-shop.nl/coleman-event-shelter-l-3-65-x-3-65-m-21075.html",
                                "price": "169,00"
                            },
                            {
                                "name": "Dewit",
                                "url": "https://www.dewitschijndel.nl/coleman-event-shelter-l-partytent-365-x-365/",
                                "price": "159,00"
                            }
                        ]
                    },
                    {
                        "id": "1.6.2",
                        "name": "Coleman Event Shelter XL partytent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/5/250641-250641-images_main-colemaneventshelterxl-ecommerce.jpg",
                        "price": "169,00",
                        "discount": "269,99",
                        "rating": 5,
                        "ratingCount": "133",
                        "features": [
                            "Lengte 450 cm",
                            "Breedte 450 cm",
                            "Materiaal doek Polyester"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/coleman-event-shelter-xl-partytent.html",
                                "price": "169,00"
                            },
                            {
                                "name": "Coleman",
                                "url": "https://www.coleman-shop.nl/coleman-event-shelter-xl-4-50-x-4-50-m-21076.html",
                                "price": "169,00"
                            },
                            {
                                "name": "Dewit",
                                "url": "https://www.dewitschijndel.nl/coleman-event-shelter-xl-partytent-450-x-450/",
                                "price": "179,00"
                            }
                        ]
                    },
                    {
                        "id": "1.6.3",
                        "name": "Garden Impressions Esprit 400 x 300 cm paviljoen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/4/446097-446097-images_main-garden_impressions_esprit_3x4_paviljoen-ecommerce.jpg",
                        "price": "129,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "201",
                        "features": [
                            "Lengte 400 cm",
                            "Breedte 300 cm"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/garden-impressions-esprit-400-x-300-cm-paviljoen.html",
                                "price": "129,00"
                            }
                        ]
                    },
                    {
                        "id": "1.6.4",
                        "name": "Obelink Air Shelter 365 Deluxe Alu partytent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/7/472102-472102-images_main-obelink_air_shelter_365_deluxe_alu-ecommerce.jpg",
                        "price": "239,00",
                        "discount": null,
                        "rating": 4.4,
                        "ratingCount": "54",
                        "features": [
                            "Lengte 365 cm",
                            "Breedte 365 cm",
                            "Materiaal doek Polyester"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-air-shelter-365-deluxe-alu-partytent.html",
                                "price": "239,00"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.de/Kampa-Air-Shelter-300/dp/B01D9358TM",
                                "price": "349,99"
                            }
                        ]
                    },
                    {
                        "id": "1.6.5",
                        "name": "Coleman Event Shelter M partytent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/9/393070-393070-images_main-colemaneventshelterm-ecommerce.jpg",
                        "price": "135,00",
                        "discount": null,
                        "rating": 4.9,
                        "ratingCount": "3",
                        "features": [
                            "Lengte 300 cm",
                            "Breedte 300 cm",
                            "Materiaal doek Polyester"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/coleman-event-shelter-m-partytent.html",
                                "price": "135,00"
                            },
                            {
                                "name": "Dewit",
                                "url": "https://www.dewitschijndel.nl/coleman-event-shelter-m-partytent-300-x-300/",
                                "price": "139,00"
                            },
                            {
                                "name": "Coleman",
                                "url": "https://www.coleman-shop.nl/coleman-event-shelter-m-3-00-x-3-00-m-21074.html",
                                "price": "149,00"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "2",
        "name": "Outdoor & sport",
        "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-outdoor-sport-2.jpg",
        "key": "Outdoor",
        "subCategories": [
            {
                "id": "2.1",
                "name": "Kleding",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Kleding.png",
                "resultList": [
                    {
                        "id": "2.1.1",
                        "name": "Life-Line Pine 2 afritsbroek heren",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/1/416243-416243-images_main-lifeline_pine_2_afritsbroek_heren_donkerblauw-ecommerce.jpg",
                        "price": "44,95",
                        "discount": "59,95",
                        "rating": 0,
                        "ratingCount": "513",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/life-line-pine-2-afritsbroek-heren.html",
                                "price": "44,95"
                            },
                            {
                                "name": "golifeline",
                                "url": "https://www.golifeline.nl/nl/pine-2-heren-afritsbroek-in-marine.html",
                                "price": "59,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/f/life-line-pine-2-afritsbroek-heren/9200000057189430/",
                                "price": "59,95"
                            }
                        ]
                    },
                    {
                        "id": "2.1.2",
                        "name": "Regatta Corinne IV regenjas",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/7/475805-475805-images_main-regatta_corinne_iv_clematis-ecommerce.jpg",
                        "price": "29,95",
                        "discount": "54,95",
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/regatta-corinne-iv-regenjas.html",
                                "price": "29,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/f/regatta-corinne-iv-jas-dames/9200000090114574/",
                                "price": "33,90"
                            },
                            {
                                "name": "campz",
                                "url": "https://www.campz.nl/regatta-corinne-iv-jas-dames-blauw-903096.html",
                                "price": "27,99"
                            }
                        ]
                    },
                    {
                        "id": "2.1.3",
                        "name": "Regatta Lyle IV regenjas",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/1/519246-519246-images_main-regatta_lyle_lime_punch-ecommerce.jpg",
                        "price": "29,95",
                        "discount": "54,95",
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/regatta-lyle-iv-regenjas.html",
                                "price": "29,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/regatta-lyle-iii-regenjas/9200000090114577/",
                                "price": "33,90"
                            },
                            {
                                "name": "plutosport",
                                "url": "https://www.plutosport.nl/regatta-lyle-iv-rmw283-9y6",
                                "price": "34.95"
                            }
                        ]
                    },
                    {
                        "id": "2.1.4",
                        "name": "East Rock outdoor trekking sokken",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/6/467261-467261-images_main-wandelsok_trekking2_blauw_1-ecommerce.jpg",
                        "price": "9,95",
                        "rating": 0,
                        "discount": null,
                        "ratingCount": "48",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/east-rock-outdoor-trekking-sokken.html",
                                "price": "9,95"
                            },
                            {
                                "name": "bergfreunde",
                                "url": "https://www.bergfreunde.nl/icebreaker-hike-medium-crew-wandelsokken-bf/?cnid=9302bffe1c703f2a3d25f97896b18",
                                "price": "25,95"
                            }
                        ]
                    },
                    {
                        "id": "2.1.5",
                        "name": "Meindl Portland GTX wandelschoenen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/9/295200-295200-images_main-meindl_342931-ecommerce.jpg",
                        "price": "155,00",
                        "rating": 0,
                        "discount": "169,90",
                        "ratingCount": "42",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/meindl-portland-gtx-wandelschoenen.html",
                                "price": "155,00"
                            },
                            {
                                "name": "outdoor",
                                "url": "https://www.outdoorxl.nl/meindl-portland-gtx.html?prl=92-631x144-69",
                                "price": "169,95"
                            },
                            {
                                "name": "beslist",
                                "url": "https://www.beslist.nl/schoenen/d1033897748/Meindl_Portland_GTX_Antraciet_Wandelschoenen_Heren.html?productIdentifier=00000137439987369748999315432",
                                "price": "159,99"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "2.2",
                "name": "Wandelen",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Wandelen.png",
                "resultList": [
                    {
                        "id": "2.2.1",
                        "name": "Lowa Renegade GTX Mid Ws wandelschoenen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/536657-536657-images_main-lowarenegadegtxmidwsantracitemandarine-ecommerce.jpg",
                        "price": "159,95",
                        "discount": "199,90",
                        "rating": 0,
                        "ratingCount": "18",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/lowa-renegade-gtx-mid-ws-wandelschoenen.html",
                                "price": "159,95"
                            },
                            {
                                "name": "lowa",
                                "url": "https://www.lowaboots.com/mens/hiking/renegade-gtx-mid-sepia-sepia",
                                "price": "240,00"
                            },
                            {
                                "name": "rei",
                                "url": "https://www.rei.com/product/789686/lowa-renegade-gtx-mid-hiking-boots-mens",
                                "price": "240,00"
                            }
                        ]
                    },
                    {
                        "id": "2.2.2",
                        "name": "Abbey telescopisch verstelbare wandelstok",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/1/419537-419537-images_main-abbey_wandelstok_telescopisch_verstelbaar_zir-ecommerce.jpg",
                        "price": "7,95",
                        "discount": null,
                        "rating": 4.4,
                        "ratingCount": "8",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/abbey-telescopisch-verstelbare-wandelstok.html",
                                "price": "7,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/abbey-wandelstok-telescopisch-verstelbaar-alu-nordic-walking-zwart-rood-grijs/9200000018510663/",
                                "price": "9,95"
                            },
                            {
                                "name": "Internet sport & casual",
                                "url": "https://www.internet-sportandcasuals.com/336776-abbey-wandelstok-camino-telescopisch-verstelbaar-zilver-blauw/?utm_campaign=Abbey&utm_content=Wandelstokken&utm_source=beslist&utm_medium=vergelijk&utm_term=336776",
                                "price": "15,95"
                            }
                        ]
                    },
                    {
                        "id": "2.2.3",
                        "name": "Life-Line Pine 2 afritsbroek heren",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/1/416243-416243-images_main-lifeline_pine_2_afritsbroek_heren_donkerblauw-ecommerce.jpg",
                        "price": "44,95",
                        "rating": 0,
                        "discount": "59,95",
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/life-line-pine-2-afritsbroek-heren.html",
                                "price": "44,95"
                            },
                            {
                                "name": "golifeline",
                                "url": "https://www.golifeline.nl/nl/pine-2-heren-afritsbroek-in-marine.html",
                                "price": "59,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/f/life-line-pine-2-afritsbroek-heren/9200000057189430/",
                                "price": "59,95"
                            }
                        ]
                    },
                    {
                        "id": "2.2.4",
                        "name": "Meindl Portland GTX wandelschoenen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/9/295200-295200-images_main-meindl_342931-ecommerce.jpg",
                        "price": "155,00",
                        "rating": 0,
                        "discount": "169,90",
                        "ratingCount": "28",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/meindl-portland-gtx-wandelschoenen.html",
                                "price": "155,00"
                            },
                            {
                                "name": "outdoor",
                                "url": "https://www.outdoorxl.nl/meindl-portland-gtx.html?prl=92-631x144-69",
                                "price": "169,95"
                            },
                            {
                                "name": "beslist",
                                "url": "https://www.beslist.nl/schoenen/d1033897748/Meindl_Portland_GTX_Antraciet_Wandelschoenen_Heren.html?productIdentifier=00000137439987369748999315432",
                                "price": "159,99"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "2.3",
                "name": "Rugzakken & tassen",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Rugzakken.png",
                "resultList": [
                    {
                        "id": "2.3.1",
                        "name": "Bagage- en gasflesweger",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/1/9/192924-192924-images_main-bagageweger-ecommerce.jpg",
                        "price": "2,95",
                        "discount": "3,95",
                        "rating": 4.4,
                        "ratingCount": "12",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/bagage-en-gasflesweger.html",
                                "price": "2,95"
                            },
                            {
                                "name": "agradi",
                                "url": "https://www.agradi.nl/kerbl-hangweegschaal.htm",
                                "price": "17,33"
                            }
                        ]
                    },
                    {
                        "id": "2.3.2",
                        "name": "Pro Plus 50 kg bagageweegschaal",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/2/422578-422578-images_main-proplusbagageweegschaal-ecommerce.jpg",
                        "price": "5,95",
                        "rating": 4,
                        "discount": "7,95",
                        "ratingCount": "10",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/pro-plus-50-kg-bagageweegschaal.html",
                                "price": "5,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/proplus-digitale-bagageweegschaal-770448/9200000054147261/",
                                "price": "20,35"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.nl/dp/B01EL19XZQ/ref=asc_df_B01EL19XZQ1584536400000/?creative=380333&creativeASIN=B01EL19XZQ&linkCode=asn&tag=beslistnl20-21",
                                "price": "9,83"
                            }
                        ]
                    },
                    {
                        "id": "2.3.3",
                        "name": "Cruiser 3 wielen boodschappenkar",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/5/458089-458089-images_main-carryonboodschappenkar3wielen1-ecommerce.jpg",
                        "price": "75,00",
                        "discount": "99,95",
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/pro-plus-50-kg-bagageweegschaal.html",
                                "price": "5,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/proplus-digitale-bagageweegschaal-770448/9200000054147261/",
                                "price": "20,35"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.nl/dp/B01EL19XZQ/ref=asc_df_B01EL19XZQ1584536400000/?creative=380333&creativeASIN=B01EL19XZQ&linkCode=asn&tag=beslistnl20-21",
                                "price": "9,83"
                            }
                        ]
                    },
                    {
                        "id": "2.3.4",
                        "name": "Agu 16 liter rugtas",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/91ee885c030d6b6bac91d1651998b59b/4/7/472720-472720-images_main-agubackpackzwart16liter-ecommerce.jpg",
                        "price": "22,95",
                        "discount": "69,95",
                        "rating": 4.6,
                        "ratingCount": "9",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/agu-16-liter-rugtas.html",
                                "price": "22,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/agu-backpack-16-liter-zwart/9200000075493790/?bltgh=j668ErlsHSz3Iz-bg9ZNyA.1_4.5.ProductTitle",
                                "price": "62,50"
                            }
                        ]
                    },
                    {
                        "id": "2.3.5",
                        "name": "Nomad Thorite rugtas",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/9/496239-496239-images_main-nomadthoritedaypackdarkblue4-ecommerce.jpg",
                        "price": "29,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "7",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/nomad-thorite-daypack.html",
                                "price": "29,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/f/nomad-thorite-daypack-rugzak-20l/9200000087961043/",
                                "price": "52,91"
                            },
                            {
                                "name": "Frank",
                                "url": "https://www.rugzak-experts.nl/nomad-thorite-20l-rugzak",
                                "price": "24,90"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "2.4",
                "name": "Zwemmen",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Zwemmen.png",
                "resultList": [
                    {
                        "id": "2.4.1",
                        "name": "Intex Monster Truck zwemband",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/5/358258-358258-images_main-intexmonstertrucktube_opblaasbareband-ecommerce.jpg",
                        "price": "6,95",
                        "discount": "7,95",
                        "rating": 5,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/intex-monster-truck-zwemband.html",
                                "price": "6,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/intex-monster-truck-tube-opblaasband-114-cm/9200000036422977/?bltgh=l8vCEzKPAn9YHZNSlxpKBw.1_4.5.ProductTitle",
                                "price": "9,93"
                            },
                            {
                                "name": "Pelckmans",
                                "url": "https://www.pelckmans.net/nl/153441/intex-monster-truck-zwemband-1",
                                "price": "3,99"
                            }
                        ]
                    },
                    {
                        "id": "2.4.2",
                        "name": "Sevylor Caravelle KK55 opblaasboot",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/0/508068-508068-images_main-sevylor_caravelle_kk55_1-ecommerce.jpg",
                        "price": "39,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/sevylor-caravelle-kk55-opblaasboot.html",
                                "price": "39,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/sevylor-pvc-opblaasboot-caravelle-kk55-kit-1-persoons-blauw/9200000016497302/?bltgh=k-ntvjH2PxyCFVjFz-BPSg.1_4.5.ProductTitle",
                                "price": "69,99"
                            },
                            {
                                "name": "Rubberbootshop",
                                "url": "https://www.rubberbootshop.nl/sevylor-caravelle-kk55-rubberboot-set",
                                "price": "38,95"
                            }
                        ]
                    },
                    {
                        "id": "2.4.3",
                        "name": "Roeispanen / kanopeddel 225 cm",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/4/34733-34733-images_main-roeispaankanopeddelbdem225cm1-ecommerce.jpg",
                        "price": "19,95",
                        "discount": "27,75",
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/roeispanen-kanopeddel-225-cm.html",
                                "price": "19,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/waimea-peddels-2-transformeerbaar-tot-1-aluminium-wit/1004004012166571/?suggestionType=featured_product&suggestedFor=roeispan&originalSearchContext=media_all&originalSection=main",
                                "price": "20,29"
                            },
                            {
                                "name": "Rubberbootshop",
                                "url": "https://www.rubberbootshop.nl/steekpeddel-hout-deluxe-lahnakoski",
                                "price": "37,94"
                            }
                        ]
                    },
                    {
                        "id": "2.4.4",
                        "name": "Intex pizzapunt luchtbed",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/7/477209-477209-images_main-intex_pizza_punt_mat-ecommerce.jpg",
                        "price": "16,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/intex-pizzapunt-luchtbed.html",
                                "price": "16,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/intex-pizzapunt-luchtbed/9200000088236267/?bltgh=uZZHo4M6VKCadQrj45xM9A.1_4.6.ProductTitle",
                                "price": "28,81"
                            },
                            {
                                "name": "Frank",
                                "url": "https://www.zwembad-expert.nl/intex-pizzapunt-luchtbed",
                                "price": "15,04"
                            }
                        ]
                    },
                    {
                        "id": "2.4.5",
                        "name": "Intex Berry Pink Splash luchtbed",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/7/477212-477212-images_main-intex_berry_pink_splash_float-ecommerce.jpg",
                        "price": "13,95",
                        "discount": null,
                        "rating": 4,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/intex-berry-pink-splash-luchtbed.html",
                                "price": "13,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/intex-berry-pink-splash-float/9200000093365798/?bltgh=nUvk-5bAUfFungUyiupK-g.1_4.5.ProductTitle",
                                "price": "12,94"
                            },
                            {
                                "name": "Frank",
                                "url": "https://www.zwembad-expert.nl/intex-berry-pink-splash-luchtbed/1442827",
                                "price": "12,04"
                            }
                        ]
                    },
                    {
                        "id": "2.4.6",
                        "name": "Intex Sit 'n Lounge luchtbed",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/0/302634-302634-images_main-intexsitzloungesitnlounge-ecommerce.jpg",
                        "price": "10,95",
                        "discount": null,
                        "rating": 4.6,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/intex-sit-n-lounge-luchtbed.html",
                                "price": "11,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/sit-n-lounges/9200000084538814/?bltgh=voCjwMFtDVSV6tZMkVtJKQ.1_4.6.ProductTitle",
                                "price": "19,95"
                            },
                            {
                                "name": "ebay",
                                "url": "https://www.ebay.nl/p/Intex-Sit-n-Lounge-Inflatable-Pool-Float-47-Diameter-for-Ages-8-1/2255150531",
                                "price": "17,08"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "2.5",
                "name": "Hengelsport",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Hengelsport.png",
                "resultList": [
                    {
                        "id": "2.5.1",
                        "name": "Albatros Match Competition Pro opbergkist",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/0/301841-301841-images_main-albatros_match_box_competition_pro_02912-ecommerce.jpg",
                        "price": "175,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/albatros-match-competition-pro-opbergkist.html",
                                "price": "175,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/match-box-competition-pro/9200000016507255/?suggestionType=featured_product&suggestedFor=albatros%20m&originalSearchContext=media_all&originalSection=main",
                                "price": "175,00"
                            },
                            {
                                "name": "ebay",
                                "url": "https://www.aspius.nl/albatros-match-box-competition-pro.html",
                                "price": "140,00"
                            }
                        ]
                    },
                    {
                        "id": "2.5.2",
                        "name": "Albatros Keepnet Round leefnet",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/2/226395-226395-images_main-albatros_keepnet_round_leefnet_150_cm_79090-ecommerce.jpg",
                        "price": "26,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "17",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/albatros-keepnet-round-leefnet.html",
                                "price": "26,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/albatros-keepnet-round-leefnet-lengte-200-cm-maaswijdte-4-mm/9200000016510951/?bltgh=mkQuokz5sYK7-hfswnZNvg.1_4.5.ProductTitle",
                                "price": "27,99"
                            },
                            {
                                "name": "aspius",
                                "url": "https://www.aspius.nl/keepnet-rond-4-hoepels.html",
                                "price": "22,36"
                            }
                        ]
                    },
                    {
                        "id": "2.5.3",
                        "name": "Albatros viskoffer",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/2/226657-226657-images_main-albatrosviskoffer-ecommerce.jpg",
                        "price": "12,50",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "201",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/albatros-viskoffer.html",
                                "price": "12,50"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/albatros-viskoffer-met-3-laden-43-x-21-x-20-cm-groen/9200000016508024/?suggestionType=featured_product&suggestedFor=albatros%20v&originalSearchContext=media_all&originalSection=main",
                                "price": "23,99"
                            },
                            {
                                "name": "aspius",
                                "url": "https://www.aspius.nl/viskoffer-tackle-master-3-ladig-xl.html",
                                "price": "37,60"
                            }
                        ]
                    },
                    {
                        "id": "2.5.4",
                        "name": "Soul Bedchair Transformer veldbed",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/7/277853-277853-images_main-soul_transformerbedchair-ecommerce.jpg",
                        "price": "109,00",
                        "discount": "145,00",
                        "rating": 4.5,
                        "ratingCount": "3",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/albatros-viskoffer.html",
                                "price": "12,50"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/albatros-viskoffer-met-3-laden-43-x-21-x-20-cm-groen/9200000016508024/?suggestionType=featured_product&suggestedFor=albatros%20v&originalSearchContext=media_all&originalSection=main",
                                "price": "23,99"
                            },
                            {
                                "name": "aspius",
                                "url": "https://www.aspius.nl/viskoffer-tackle-master-3-ladig-xl.html",
                                "price": "37,60"
                            }
                        ]
                    },
                    {
                        "id": "2.5.5",
                        "name": "Albatros X Frame Chair Backrest",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/2/226640-226640-images_main-albatros_x_frame_chair_backrest-ecommerce.jpg",
                        "price": "14,95",
                        "discount": "21,95",
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/albatros-x-frame-chair-backrest.html",
                                "price": "14,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/albatros-x-frame-backrest-chair-visstoel-42-cm-zwart/9200000016507441/?bltgh=gBgBf0wD2uEXTD2hgK1PiQ.1_4.6.ProductTitle",
                                "price": "26,50"
                            },
                            {
                                "name": "aspius",
                                "url": "https://www.aspius.nl/albatros-x-frame-chair.html",
                                "price": "12,76"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "2.6",
                "name": "Sportartikelen",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Sport.png",
                "resultList": [
                    {
                        "id": "2.6.1",
                        "name": "Avento 10 kg halter",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/5/455241-455241-images_main-aventohalter10kgzwart-ecommerce.jpg",
                        "price": "29,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "162",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/avento-10-kg-halter.html",
                                "price": "29,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/avento-halter-10-kg-zwart-zwart-chroom/9200000020300400/?bltgh=tDfyV8nhzAWgbGcHfVZDpw.1_4.5.ProductTitle",
                                "price": "42,95"
                            },
                            {
                                "name": "beslist",
                                "url": "https://www.beslist.nl/sport_outdoor_vrije-tijd/d1034245659/HALTER_10_KG__ZWART.html?productIdentifier=00000000885797508635956319820",
                                "price": "39,95"
                            }
                        ]
                    },
                    {
                        "id": "2.6.2",
                        "name": "Hummel Elite Micro short",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/8/382374-382374-images_main-hummelelitemicroshortnavy-ecommerce.jpg",
                        "price": "24,95",
                        "discount": "27,50",
                        "rating": 0,
                        "ratingCount": "133",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hummel-elite-micro-short.html",
                                "price": "24,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/hummel-elite-micro-sportbroek-performance-maat-m-unisex-zwart/9200000081905956/?bltgh=ihkXkg5qwlvn06xGuLtO9Q.1_4.5.ProductTitle",
                                "price": "29,90"
                            },
                            {
                                "name": "handballshop",
                                "url": "https://www.handballshop.com/hummel-elite-micro-short.html",
                                "price": "24,95"
                            }
                        ]
                    },
                    {
                        "id": "2.6.3",
                        "name": "Avento foam yoga roller",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/5/455257-455257-images_main-aventoyogarollerantraciet-ecommerce.jpg",
                        "price": "21,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "201",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/avento-foam-yoga-roller.html",
                                "price": "21,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/avento-yoga-roller-foam-antraciet/9200000056504668/?bltgh=mYXlCvVr9BIEY-iezbpBAA.1_4.6.ProductTitle",
                                "price": "27,50"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/avento-yoga-roller-foam-rood/9200000056504664/?bltgh=mYXlCvVr9BIEY-iezbpBAA.1_4.7.ProductTitle",
                                "price": "30,99"
                            }
                        ]
                    },
                    {
                        "id": "2.6.4",
                        "name": "Avento volleybalnet",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/0/305193-305193-images_main-aventovolleybalnet16ne-ecommerce.jpg",
                        "price": "19,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "54",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/avento-volleybalnet.html",
                                "price": "19,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/volleybalnet-9-5-x-1-meter-zwart-wit/1004004012166613/?bltgh=rfvWgT9zkqyQ3LaHlqhFow.1_4.5.ProductTitle",
                                "price": "28,50"
                            },
                            {
                                "name": "blokker",
                                "url": "https://www.blokker.nl/p/avento-volleybalnet-9-5-x-1-m-zwart/1562617",
                                "price": "29,95"
                            }
                        ]
                    },
                    {
                        "id": "2.6.5",
                        "name": "Avento 2 x 3 kg halter",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/5/455240-455240-images_main-aventohalter2x3kg-ecommerce.jpg",
                        "price": "17,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/avento-2-x-3-kg-halter.html",
                                "price": "17,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/avento-dumbbells-2-x-3-kg-kobalt/9200000022063498/",
                                "price": "28,50"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "3",
        "name": "Kampeerartikelen",
        "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-kampeerartikelen.jpg",
        "key": "Kampeerartikelen",
        "subCategories": [
            {
                "id": "3.1",
                "name": "Barbecues & skottelbraai",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Barbecue_en_skottelbraai.png",
                "resultList": [
                    {
                        "id": "3.1.1",
                        "name": "Weber Master-Touch GBS kolenbarbecue",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/4/344110-344110-images_main-weber_master-touch_57_cm_spring_green_gbs-ecommerce.jpg",
                        "price": "235,00",
                        "discount": "329,00",
                        "rating": 0,
                        "ratingCount": "513",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/weber-master-touch-gbs-kolenbarbecue.html",
                                "price": "235,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/master-touch-gbs-premium-e-5775-zwart/9200000107878877/?bltgh=l6SVSbb9FEIdIjQWuGVKrQ.1_4.6.ProductTitle",
                                "price": "328,99"
                            }
                        ]
                    },
                    {
                        "id": "3.1.2",
                        "name": "Outwell Asado gasbarbecue",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/6/469455-469455-images_main-asadogasgril-ecommerce.jpg",
                        "price": "38,95",
                        "discount": "59,95",
                        "rating": 4.4,
                        "ratingCount": "81",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/outwell-asado-gasbarbecue.html",
                                "price": "38,95"
                            },
                            {
                                "name": "outwell",
                                "url": "https://www.outwell.com/en-gb/ecommerce-1/accessories/asado-gas-grill",
                                "price": "59,95"
                            },
                            {
                                "name": "leisureoutlet",
                                "url": "https://www.leisureoutlet.com/caravans-and-motorhomes/cooking/barbeques/131220-outwell-asado-gas-grill-barbeque",
                                "price": "38,99"
                            }
                        ]
                    },
                    {
                        "id": "3.1.3",
                        "name": "Cadac Safari Chef 2 LP gasbarbecue",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/9/391248-391248-images_main-cadac_safari_chef_2-ecommerce.jpg",
                        "price": "105,90",
                        "discount": "129,95",
                        "rating": 4.8,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/cadac-safari-chef-2-lp-gasbarbecue.html",
                                "price": "109,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/cadac-safari-chef-2-lite-lp-gasbarbecue-30mb/9200000087753684/?bltgh=jzL3eoC33N5s9x70LLBKSA.1_4.5.ProductTitle",
                                "price": "109,95"
                            },
                            {
                                "name": "amazon",
                                "url": "https://www.amazon.nl/Cadac-Safari-Chef-HP-cartridge/dp/B01BLFCP6E/ref=sr_1_fkmr0_1?__mk_nl_NL=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1TWSROS5MKEFW&keywords=cadac+safari+chef+2+lp&qid=1584610250&sprefix=cadac+safar%2Caps%2C354&sr=8-1-fkmr0",
                                "price": "123,91"
                            }
                        ]
                    },
                    {
                        "id": "3.1.4",
                        "name": "Campingaz 1 Series Compact LX R gasbarbecue",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/5/357075-357075-images_main-campingaz1seriescompactlxr-ecommerce.jpg",
                        "price": "109,00",
                        "discount": "159,99",
                        "rating": 4.1,
                        "ratingCount": "23",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/campingaz-1-series-compact-lx-r-gasbarbecue.html",
                                "price": "109,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/campingaz-1-series-compact-r-gasbarbecue/9200000039427806/?bltgh=sw5LTBbPOgl0XmpCPOtHVQ.1_4.5.ProductTitle",
                                "price": "147,09"
                            },
                            {
                                "name": "agrieuro",
                                "url": "https://www.agrieuro.co.uk/campingaz-1-series-compact-lx-foldable-gas-barbecue-with-oven-and-grate-p-7450.html",
                                "price": "194,00"
                            }
                        ]
                    },
                    {
                        "id": "3.1.5",
                        "name": "Weber Q 1200 model 2019 gasbarbecue",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/1/318815-318815-images_main-weber_q-1200_black_51010075-2-ecommerce.jpg",
                        "price": "249,00",
                        "discount": "289,00",
                        "rating": 5,
                        "ratingCount": "18",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/weber-q-1200-stand-model-2019-gasbarbecue.html",
                                "price": "309,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/campingaz-1-series-compact-r-gasbarbecue/9200000039427806/?bltgh=sw5LTBbPOgl0XmpCPOtHVQ.1_4.5.ProductTitle",
                                "price": "147,09"
                            },
                            {
                                "name": "walmart",
                                "url": "https://www.walmart.com/ip/Weber-Q-1200-Portable-Gas-Grill/39601434",
                                "price": "209,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "3.2",
                "name": "Bolderkarren & trolleys",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Bolderkarren_en_trolleys.png",
                "resultList": [
                    {
                        "id": "3.2.1",
                        "name": "Obelink Duo Solar bolderkar",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/537151-537151-images_main-obelink_bolderkar_duo_solar_1-ecommerce.jpg",
                        "price": "119,00",
                        "discount": null,
                        "rating": 4.1,
                        "ratingCount": "4",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-duo-solar-bolderkar.html",
                                "price": "119,00"
                            },
                            {
                                "name": "GroteKadoShop",
                                "url": "https://www.marktplaats.nl/a/kinderen-en-baby-s/speelgoed-buiten-voertuigen-en-loopfietsen/a1298294788-bolderkar-bagagewagen-opvouwbaar-met-dak-blauw.html?c=d97e27c274e75147b4afd0f5eb58c81b&casData=BKNfmDrn2-Unm3TqgUHuFYBBDyc0IM0eFfsc9JPfHPHf00UfceFFYdHGJmbM6Q1-_C_dxYZUKnGC_WMmV_5WvUeZrAgrazoKHnLTvJzwIZrF5zZWsl55HYC2ri4fCjlZ1CZGRLKYI71S58kPpIUxEZ3GXF5saaKH_FoGKZLfTZoYa9zG_wexVJFFaCPlsHHB1KAFMQAdUduwiQeAEpsuFa50T6SlFxolxkEqJ2oJq6ICpZfenSzzSuK0Vaf8vl07Tm88DfDzJ8MEtV6cPBRkUBVYNo4KctVzcy-ws6-1n8O0DqhUX6uk9Cm0tqEoIeMHUs424BpWioHzgWCQiBxrwOxn8sayXgnqij0eTar3_EoyjqvF1ltFjeLHXu3dLWevmicj-Y_pXLFWoecGu6haPmnK8bcoSKsjACT_6MRey0yIOOCovLw9-PqXBn4tcf71rEOneFociNbeNp0QhNkVvHgs8QgmVEsxuwlikXPc27wEvTt9KXygaSzrTlX8qQUBY5noxHc8ih3eNNHXDl6viWVDFRq-9wPRzZf0yvKFe37gUwsy-CrZ2j6I5CLftIIVOcPFTN_-BZf9Pl39GFDhx8qF8XM3wcKRTPdPbfxPdv2n0MPiOh3ibKyGBXQR9NmgSzf5FO5bChfDAAuuzvzrvg&previousPage=lr",
                                "price": "82,50"
                            },
                            {
                                "name": "Trampolinewinkel",
                                "url": "https://www.marktplaats.nl/a/kinderen-en-baby-s/speelgoed-buiten-trampolines-en-springkussens/a1270359632-bolderkar-bolderwagen-opvouwbaar-zwart-limited-black-editi.html?c=d97e27c274e75147b4afd0f5eb58c81b&casData=LGWi1vCaeuu65P9VW_G5Jx27TTofIoNB0534CWfnEqMNBM73Lu85MWgrDVBLMaTbuUYy2GS5zIDcZ2cOgb8tWOP301tei0_1d1fGLgOAlKOF-Y61MS36TxZnVhJNU56sWENekaapZ8R1xwdO1ie3qPMPeNbNbx-TdEpW7-JHgAs52pyObjVIMKCg6V-LLtt7fTaBM5fldnrMQNtqbL3g7qrhcTZg8PcFFGP4OtzP4OOx0yCeh00vzcKU6JrdcU7ENxK_OSzQEYiY4FDXD8fOT7x6bXsMAGctAWn6bC1hrP1OxcqGiq1dEq8mAW_nv1_ENIW5az3uQnq8FPXGN2yRM0p1atyHkHR-M5dxH4HvY4C-jfsJKNAttfsUlyJNstRSNDyqSS5E5woXqj_rgcMWQs1MAkHEYpdhPt0VCjl5Ohj91zVO3G1VbyOXDernSYdwHyH5xgRYoviTU6RuRQObue5CqQ0XkPPPnGiigmiJDcb_ZIsVPSmVRp_2BXX_tpkd34ME_ThEGSb13LFJKApSKKAncDgn7bXFbK46nEaku5HzGoZ8z96BPoNG1yLtFmlVlMAhCuIEWCCq_UonbJ_JE_TvVOCBAaljT2pH8aarYiBsPPDpZ8LfEcBAMRX0gRDYqLR4WtRDGYC_Sf_DpWwT1Q&previousPage=lr",
                                "price": "99,95"
                            }
                        ]
                    },
                    {
                        "id": "3.2.2",
                        "name": "Eurotrail Beach Car Sunny bolderkar",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/1/419279-419279-images_main-eurotrailrobbiebeachwagon1-ecommerce.jpg",
                        "price": "99,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "30",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/eurotrail-beach-car-sunny-bolderkar.html",
                                "price": "99,00"
                            },
                            {
                                "name": "kampeerdump",
                                "url": "https://www.kampeerdump.nl/eurotrail-beach-car-sunny.html",
                                "price": "94,95"
                            },
                            {
                                "name": "Soellaart",
                                "url": "https://www.soellaart.nl/nl/1307830/eurotrail-beach-car-sunny-bolderkar/",
                                "price": "134,95"
                            }
                        ]
                    },
                    {
                        "id": "3.2.3",
                        "name": "Wander Wonder bolderkar",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/9/391719-391719-images_main-wander_wonder_bolderkar-ecommerce.jpg",
                        "price": "79,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "43",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/wander-wonder-bolderkar.html",
                                "price": "89,95"
                            }
                        ]
                    },
                    {
                        "id": "3.2.4",
                        "name": "Cruiser 3 wielen boodschappenkar",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/5/458089-458089-images_main-carryonboodschappenkar3wielen1-ecommerce.jpg",
                        "price": "75,00",
                        "rating": 0,
                        "discount": "99,95",
                        "ratingCount": "28",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/cruiser-boodschappenkar-3-wielen.html",
                                "price": "75,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/shopping-cruiser-boodschappentrolley-3-wheels-boodschappen-wagen-met-koeltas-zwart/9200000068684405/?bltgh=meEJWFnVV8pR8zNh-wxGkQ.1_4.5.ProductTitle",
                                "price": "99,90"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/shopping-cruiser-boodschappentrolley-3-wheels-boodschappenwagen-met-koeltas-grijs/9200000082380761/?bltgh=meEJWFnVV8pR8zNh-wxGkQ.1_4.6.ProductTitle",
                                "price": "86,90"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "3.3",
                "name": "Campingmeubelen",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Campingmeubelen.png",
                "resultList": [
                    {
                        "id": "3.3.1",
                        "name": "Obelink Prestige standenstoel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/1/317127-317127-images_main-obelinkprestigekampeerstoel1-ecommerce.jpg",
                        "price": "69,95",
                        "discount": "79,00",
                        "rating": 0,
                        "ratingCount": "71",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-prestige-standenstoel.html",
                                "price": "69,95"
                            },
                            {
                                "name": "bsacchi",
                                "url": "http://www.bsacchi.eu/904-obelink-outdoor-strandstoel-982.html",
                                "price": "30,99"
                            }
                        ]
                    },
                    {
                        "id": "3.3.2",
                        "name": "Obelink Cyprus tafel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/9/392993-392993-images_main-obelink_cyprus_alu_150_x_80_cm_1-ecommerce.jpg",
                        "price": "79,00",
                        "discount": "99,95",
                        "rating": 4.5,
                        "ratingCount": "285",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-prestige-standenstoel.html",
                                "price": "69,95"
                            },
                            {
                                "name": "bsacchi",
                                "url": "http://www.bsacchi.eu/904-obelink-outdoor-strandstoel-982.html",
                                "price": "30,99"
                            }
                        ]
                    },
                    {
                        "id": "3.3.3",
                        "name": "Obelink Chairman vouwstoel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/2/429345-429345-images_main-obelinkchairmanblauw01-ecommerce.jpg",
                        "price": "29,90",
                        "discount": "39,90",
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-chairman-vouwstoel.html",
                                "price": "29,90"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/outwell-catamarca-xl-campingstoel-zwart-zilver/9200000020650579/?bltgh=rKA-bRzCg6vHp5JL20dMPA.1_4.6.ProductTitle",
                                "price": "37,58"
                            }
                        ]
                    },
                    {
                        "id": "3.3.4",
                        "name": "Obelink Avignon Luxe kast",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/0/504123-504123-images_main-obelink_avignon_luxe_1-ecommerce.jpg",
                        "price": "49,90",
                        "discount": null,
                        "rating": 4,
                        "ratingCount": "778",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-avignon-luxe-kast.html",
                                "price": "58,90"
                            },
                            {
                                "name": "marktplace",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-toebehoren/a1277494472-tectake-campingkeuken-97x47-5x78-cm-a402923.html?c=827ab967057e9f5ff33a0fad145c2c0e&previousPage=VIP_SIMILAR_LISTING",
                                "price": "75,90"
                            },
                            {
                                "name": "marktplace",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-toebehoren/a1277494472-tectake-campingkeuken-97x47-5x78-cm-a402923.html?c=827ab967057e9f5ff33a0fad145c2c0e&previousPage=VIP_SIMILAR_LISTING",
                                "price": "75,90"
                            }
                        ]
                    },
                    {
                        "id": "3.3.5",
                        "name": "Obelink Rodeo Luxe keuken",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/0/504121-504121-images_main-obelink_rodeo_lux_1-ecommerce.jpg",
                        "price": "79,90",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "167",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-rodeo-luxe-keuken.html",
                                "price": "79,90"
                            },
                            {
                                "name": "marktplace",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-toebehoren/a1277494472-tectake-campingkeuken-97x47-5x78-cm-a402923.html?c=827ab967057e9f5ff33a0fad145c2c0e&previousPage=VIP_SIMILAR_LISTING",
                                "price": "75,90"
                            },
                            {
                                "name": "marktplace",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-toebehoren/a1277494472-tectake-campingkeuken-97x47-5x78-cm-a402923.html?c=827ab967057e9f5ff33a0fad145c2c0e&previousPage=VIP_SIMILAR_LISTING",
                                "price": "75,90"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "3.4",
                "name": "Dierenartikelen",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Dierenartikelen.png",
                "resultList": [
                    {
                        "id": "3.4.1",
                        "name": "Hondenpen met riem",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/9/391696-391696-images_main-bocamp_hondenpen_tentanker_met_riem_4114973-ecommerce.jpg",
                        "price": "7,95",
                        "discount": "9,95",
                        "rating": 4.7,
                        "ratingCount": "43",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hondenpen-met-riem.html",
                                "price": "7,95"
                            },
                            {
                                "name": "kampeerdump",
                                "url": "https://www.kampeerdump.nl/bo-camp-hondenpen-tentanker-riem.html",
                                "price": "11,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/bo-camp-hondenpen-tentanker-met-riem-40-cm/9200000080250227/",
                                "price": "8,95"
                            }
                        ]
                    },
                    {
                        "id": "3.4.2",
                        "name": "Flamingo trainingsbal",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/2/420989-420989-images_main-karlietrainingsbal_-ecommerce.jpg",
                        "price": "5,95",
                        "discount": "7,89",
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/flamingo-trainingsbal.html",
                                "price": "5,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/flamingo-trainingsbal-hondenspeelgoed-8-cm-roze/9200000106695331/?bltgh=tfMR3pFV3iLxzzmzo3BD5Q.1_4.6.ProductTitle",
                                "price": "12,45"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.de/Flamingo-Ball-Training-f%C3%BCr-Hunde/dp/B00EE8BMR4?th=1",
                                "price": "13,75"
                            }
                        ]
                    },
                    {
                        "id": "3.4.3",
                        "name": "Wespenvanger",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/1/9/192913-192913-images_main-wespenvanger-ecommerce.jpg",
                        "price": "1,95",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "26",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/wespenvanger-192913.html",
                                "price": "1,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/wespenvanger-glas-18x12cm-assorti/9200000025014855/?bltgh=kg83xfpFkcgaiTcvWR1POA.1_12.29.ProductTitle",
                                "price": "11,95"
                            },
                            {
                                "name": "dille-kamille",
                                "url": "dille-kamille.nl/wespenvanger-glas-00003376.html",
                                "price": "2,95"
                            }
                        ]
                    },
                    {
                        "id": "3.4.4",
                        "name": "Citronella gel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/1/1/11377-11377-images_main-citronellagel-ecommerce.jpg",
                        "price": "0,95",
                        "discount": null,
                        "rating": 4,
                        "ratingCount": "3",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/citronella-gel.html",
                                "price": "0,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/citronella-gel/9200000117124663/?bltgh=pcCyz5BbMF5zXXFOlHhCnQ.1_4.5.ProductTitle",
                                "price": "5,45"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.nl/NAF-C185-Off-Citronella-Gel/dp/B005N7KB2W/ref=sr_1_2?__mk_nl_NL=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=Citronella+gel&qid=1584678726&sr=8-2",
                                "price": "18,56"
                            }
                        ]
                    },
                    {
                        "id": "3.4.5",
                        "name": "Vliegenmepper",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/0/208745-208745-images_main-vliegenmepper-ecommerce.jpg",
                        "price": "0,95",
                        "discount": "1,15",
                        "rating": 4.8,
                        "ratingCount": "14",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/vliegenmepper.html",
                                "price": "0,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/relaxdays-elektrische-vliegenmepper-tegen-muggen-vliegen-vliegen-mepper-elektrisch-rood/9200000110132437/?bltgh=ii8NrIBqjC2QHU4cYmpO4w.1_4.6.ProductTitle",
                                "price": "2,50"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.nl/P-TORRENTE-vliegenmepper-kunststof-47-assortiment/dp/B01MS648Q3/ref=sr_1_3?__mk_nl_NL=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=Vliegenmepper&qid=1584679066&sr=8-3&swrs=A3D4A3D465B1BE1787AFC447A1B32569",
                                "price": "0,52"
                            }
                        ]
                    },
                    {
                        "id": "3.4.6",
                        "name": "Eurom Fly Away 11 ovaal insectenlamp ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/9/490611-490611-images_main-eurom_insectenlamp_fly_away_11_ovaal_1-ecommerce.jpg",
                        "price": "11,95",
                        "discount": null,
                        "rating": 4.3,
                        "ratingCount": "10",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/eurom-fly-away-11-ovaal-insectenlamp.html",
                                "price": "11,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/eurom-fly-away-11-insectenlamp-11-watt-uv-90m-vliegenlamp/9200000118386922/?bltgh=k6i2I5wjgO0P7Q3eDLlGhQ.1_4.5.ProductTitle",
                                "price": "22,70"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/eurom-fly-away-11-insectenlamp-11-watt-uv-90m-vliegenlamp/9200000118386981/?bltgh=k6i2I5wjgO0P7Q3eDLlGhQ.1_4.6.ProductTitle",
                                "price": "22,95"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "3.5",
                "name": "Speelgoed & spellen",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Speelgoed_en_spellen.png",
                "resultList": [
                    {
                        "id": "3.5.1",
                        "name": "Slee + stuurwiel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/8/288785-288785-images_main-slee___stuurwiel-ecommerce.jpg",
                        "price": "18,95",
                        "discount": null,
                        "rating": 4.5,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/slee-stuurwiel.html",
                                "price": "18,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/stratos-slee-met-stuurwiel-rood/9200000095616500/?bltgh=gajBRS0SdW1OGXX6eBBNuA.1_4.5.ProductTitle",
                                "price": "79,38"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/stiga-rodelen-bob-met-snowpower-stuurwiel-roze/9200000132352519/?bltgh=gajBRS0SdW1OGXX6eBBNuA.1_4.6.ProductTitle",
                                "price": "68,74"
                            }
                        ]
                    },
                    {
                        "id": "3.5.2",
                        "name": "Donnay badmintonset",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/7/470332-470332-images_main-donnaybadmintonset1-ecommerce.jpg",
                        "price": "4,95",
                        "discount": "6,95",
                        "rating": 4,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/donnay-badmintonset.html",
                                "price": "4,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/donnay-badmintonset-staal-rood-per-set/9200000060656984/?bltgh=oInl-Hr6MIJClR--tFhBig.1_4.8.ProductTitle",
                                "price": "15,38"
                            },
                            {
                                "name": "Amazon",
                                "url": "https://www.amazon.nl/Senston-Badmintonset-professionele-badmintonracket-entertainment/dp/B07Y3719DC/ref=sr_1_fkmr0_1?__mk_nl_NL=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Donnay+badmintonset&qid=1584679339&sr=8-1-fkmr0&swrs=A3D4A3D465B1BE1787AFC447A1B32569",
                                "price": "32,99"
                            }
                        ]
                    },
                    {
                        "id": "3.5.3",
                        "name": "Waimea palm springs beach peddelbal set",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/2/528802-528802-images_main-waimeapeddelbalsetgeelblauw-ecommerce.jpg",
                        "price": "3,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "201",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/waimea-palm-springs-beach-peddelbal-set.html",
                                "price": "3,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/waimea-beach-peddelbal-set-met-foam-grip-palm-springs-blauw-oranje-wit/9200000096225916/?bltgh=oBQ3msgs6hF2bDG45f9IRQ.1_4.5.ProductTitle",
                                "price": "10,99"
                            },
                            {
                                "name": "vidaxl",
                                "url": "https://www.vidaxl.nl/e/8716404316853/waimea-beachball-set-palm-springs-4-delig-geellichtblauw?utm_source=vidaxl_bigshopper&utm_medium=price_comparison&utm_campaign=nl_mplace&utm_term=8716404316853&utm_content=Sporting_Goods",
                                "price": "10,50"
                            }
                        ]
                    },
                    {
                        "id": "3.5.4",
                        "name": "Get & Go catchball vangset",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/5/455269-455269-images_main-getgocatchball-ecommerce.jpg",
                        "price": "3,50",
                        "discount": null,
                        "rating": 4.1,
                        "ratingCount": "6",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/get-go-catchball-vangset.html",
                                "price": "3,50"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/get-go-catchball-vangset-blauw-oranje/9200000074081256/?bltgh=rRuFYjGm-IIUl2rCaxtAww.1_4.5.ProductTitle",
                                "price": "12,50"
                            },
                            {
                                "name": "vidaxl",
                                "url": "https://www.vidaxl.nl/e/8716404283261/get-go-tafeltennisset-recreatief",
                                "price": "10,99"
                            }
                        ]
                    },
                    {
                        "id": "3.5.5",
                        "name": "Simex 1150 badmintonset",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/2/220045-220045-images_main-simex_badmintonset_1150-ecommerce.jpg",
                        "price": "14,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "7",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/simex-1150-badmintonset.html",
                                "price": "14,95"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "3.6",
                "name": "Kachels",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Kachels.png",
                "resultList": [
                    {
                        "id": "3.6.1",
                        "name": "MacGyver draagbare gaskachel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/2/1/213510-213510-images_main-draagbare_gaskachel-ecommerce.jpg",
                        "price": "29,90",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "112",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/macgyver-draagbare-gaskachel.html",
                                "price": "29,90"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/macgyver-draagbare-gaskachel-buitenkachel-heater-kacheltje-terrasverwarming/9200000071486911/?bltgh=nAQNPgLRrlOSeS8Q0JhPvw.1_4.5.ProductTitle",
                                "price": "34,95"
                            }
                        ]
                    },
                    {
                        "id": "3.6.2",
                        "name": "Eurom Safe-T-heater 1500 elektrische kachel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/0/303706-303706-images_main-euromsafetheater-ecommerce.jpg",
                        "price": "23,50",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "214",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/eurom-safe-t-heater-1500-elektrische-kachel.html",
                                "price": "23,50"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/eurom-safe-t-heater-1500-verwarming/9200000025128934/?bltgh=s8a9GlP-fRXmf-fv8QBAnA.1_4.5.ProductTitle",
                                "price": "33,95"
                            },
                            {
                                "name": "frank",
                                "url": "https://www.kachel-expert.nl/eurom-safe-t-heater-1500",
                                "price": "29,04"
                            }
                        ]
                    },
                    {
                        "id": "3.6.3",
                        "name": "Tosaï 241 petroleumkachel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/2/429840-429840-images_main-tosai-kachel-petroleum-ecommerce.jpg",
                        "price": "99,00",
                        "discount": "129,00",
                        "rating": 5,
                        "ratingCount": "39",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/tosai-241-petroleumkachel.html",
                                "price": "99,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.ts24.be/product_info.php/info/125143/Kero-Petroleumkachel-241/",
                                "price": "114,95"
                            },
                            {
                                "name": "frank",
                                "url": "https://www.promobutler.be/fr/chauffage-et-climatisation/poeles-a-petrole/petroleumkachel-tosai-241-3033552",
                                "price": "82,00"
                            }
                        ]
                    },
                    {
                        "id": "3.6.4",
                        "name": "Gimeg infrarood rolkachel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/3/2/325022-325022-images_main-gimegrolkachelgroot-ecommerce.jpg",
                        "price": "65,00",
                        "discount": "99,95",
                        "rating": 0,
                        "ratingCount": "54",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/gimeg-infrarood-rolkachel.html",
                                "price": "65,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/gimeg-infrarood-rolkachel-groot-zwart/9200000037386692/?bltgh=hUn5g63gikFLXkhzrIxNzQ.1_4.5.ProductTitle",
                                "price": "79,00"
                            },
                            {
                                "name": "teun",
                                "url": "https://www.teun.nl/nl/169623/gimeg-infrarood-rolkachel-groot/",
                                "price": "99,95"
                            }
                        ]
                    },
                    {
                        "id": "3.6.5",
                        "name": "Kampa Hottie gaskachel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/0/507378-507378-images_main-kampa-hottie-gaskachel-ecommerce.jpg",
                        "price": "34,95",
                        "discount": "45,90",
                        "rating": 4.8,
                        "ratingCount": "43",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/kampa-hottie-gaskachel.html",
                                "price": "34,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/kampa-hottie-draagbare-verwarming/9200000095893183/?bltgh=h3vhbf2FHz9dyFviVoJPeg.1_4.5.ProductTitle",
                                "price": "39,99"
                            },
                            {
                                "name": "mazzelshop",
                                "url": "https://www.mazzelshop.nl/product/kampeeraccessoires/kachels/kampa-hottie-draagbare-gaskachel-heater/",
                                "price": "39,95"
                            },
                            {
                                "name": "baxrecreatieshop",
                                "url": "https://www.baxrecreatieshop.nl/kampa-hottie-draagbare-gaskachel.html",
                                "price": "34,95"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "4",
        "name": "Caravans & campers",
        "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-caravans-vouwwagens.jpg",
        "key": "Caravans, campers & vouwwagens",
        "subCategories": [
            {
                "id": "4.1",
                "name": "Caravans nieuw",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-caravans-nieuw.png",
                "resultList": [
                    {
                        "id": "4.1.1",
                        "name": "Hobby Prestige 720 KWFU caravan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/6/0/600102-600102-images_main-hobby_prestige_720_kwfu_nieuw-ecommerce.jpg",
                        "price": "34.600,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "513",
                        "features": [
                            "Modeljaar 2020   ",
                            "Rijklaar gewicht 1824  kg ",
                            "Max. toelaatbaar gewicht 2200  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hobby-prestige-720-kwfu-caravan.html",
                                "price": "34.600,00"
                            },
                            {
                                "name": "cannenburg",
                                "url": "https://www.cannenburg.nl/voertuig/hobby-prestige-720-kwfu/",
                                "price": "33.950,00"
                            }
                        ]
                    },
                    {
                        "id": "4.1.2",
                        "name": "Hobby Prestige 720 WLC caravan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/6/0/600103-600103-images_main-hobby_prestige_720_wlc_nieuw-ecommerce.jpg",
                        "price": "34.300,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "81",
                        "features": [
                            "Modeljaar 2020   ",
                            "Rijklaar gewicht 1815  kg ",
                            "Max. toelaatbaar gewicht 2200  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hobby-prestige-720-wlc-caravan.html",
                                "price": "34.300,00"
                            },
                            {
                                "name": "cannenburg",
                                "url": "https://www.cannenburg.nl/voertuig/hobby-prestige-720-wlc/",
                                "price": "33.700,00"
                            }
                        ]
                    },
                    {
                        "id": "4.1.3",
                        "name": "Hobby Prestige 720 UKFe caravan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/6/0/600101-600101-images_main-hobby_prestige_720_ukfe_nieuw-ecommerce.jpg",
                        "price": "32.400,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [
                            "Modeljaar 2020   ",
                            "Rijklaar gewicht 1865  kg ",
                            "Max. toelaatbaar gewicht 2200  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hobby-prestige-720-ukfe-caravan.html",
                                "price": "32.400,00"
                            },
                            {
                                "name": "cannenburg",
                                "url": "https://www.cannenburg.nl/voertuig/hobby-prestige-720-ukfe/",
                                "price": "31.800,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "4.2",
                "name": "Caravans occasions",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-caravans-occasions.png",
                "resultList": [
                    {
                        "id": "4.2.1",
                        "name": "Knaus Azur Premium Line 500 ES caravan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/535722-535722-images_main-knaus_azur_premium_line_500_es_2009_1-ecommerce.jpg",
                        "price": "12.750,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "4",
                        "features": [
                            "Modeljaar 2009   ",
                            "Rijklaar gewicht 1230  kg ",
                            "Max. toelaatbaar gewicht 1500  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/knaus-azur-premium-line-500-es-caravan.html",
                                "price": "12.750,00"
                            },
                            {
                                "name": "speurders",
                                "url": "https://www.speurders.nl/overzicht/caravans-en-kamperen/caravans-knaus/f_particulier_zakelijk-zakelijk/f_slaapplaatsen_caravan-2%2C6%2C5/page/3/knaus-sudwind-500-fsd-mover-187114514.html?sr_ab=0&sr_ab=0&order_direction=desc&order_by=price",
                                "price": "10.750,00"
                            }
                        ]
                    },
                    {
                        "id": "4.2.2",
                        "name": "Kip GreyLine 53 TEB caravan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/535723-535723-images_main-kip_greyline_53_teb_2008_1-ecommerce.jpg",
                        "price": "11.500,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "30",
                        "features": [
                            "Modeljaar 2008   ",
                            "Rijklaar gewicht 1320  kg ",
                            "Max. toelaatbaar gewicht 1700  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/kip-greyline-53-teb-caravan.html",
                                "price": "11.500,00"
                            },
                            {
                                "name": "kampeerdump",
                                "url": "https://www.caravans.nl/kip/kip-grey-line-special-53-teb-enkele-bedden_-mover-749639.html",
                                "price": "11.500,00"
                            }
                        ]
                    },
                    {
                        "id": "4.2.3",
                        "name": "Fendt Bianco Activ 550 KMG caravan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/9/498570-498570-images_main-fendt_bianco_activ_550_kmg_2018_1-ecommerce.jpg",
                        "price": "24.950,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "43",
                        "features": [
                            "Modeljaar 2018   ",
                            "Rijklaar gewicht 1473  kg ",
                            "Max. toelaatbaar gewicht 1700  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/fendt-bianco-activ-550-kmg-caravan-498570.html",
                                "price": "24.950,00"
                            }
                        ]
                    },
                    {
                        "id": "4.2.4",
                        "name": "Hobby Excellent 560 LU caravan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/4/7/473031-473031-images_main-hobby_excellent_560_lu_2018_1-ecommerce.jpg",
                        "price": "21.950,00",
                        "rating": 0,
                        "discount": null,
                        "ratingCount": "28",
                        "features": [
                            "Modeljaar 2018   ",
                            "Rijklaar gewicht 1380  kg ",
                            "Max. toelaatbaar gewicht 1750  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hobby-excellent-560-lu-caravan-473031.html",
                                "price": "12.950,00"
                            },
                            {
                                "name": "caravans",
                                "url": "https://www.caravans.nl/hobby/hobby-excellent-560-lu-724547.html",
                                "price": "24.990,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "4.3",
                "name": "Vouwwagens opruiming",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-vouwwagens-opruiming.png",
                "resultList": [
                    {
                        "id": "4.3.1",
                        "name": "Isabella Camp-let Passion 2019 vouwwagen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/2/525696-525696-images_main-isabella_camp-let_passion_nieuw-ecommerce.jpg",
                        "price": "10.250,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "71",
                        "features": [
                            "Modeljaar 2019   ",
                            "Rijklaar gewicht 300  kg ",
                            "Max. toelaatbaar gewicht 500  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/isabella-camp-let-passion-2019-vouwwagen.html",
                                "price": "10.250,00"
                            }
                        ]
                    },
                    {
                        "id": "4.3.2",
                        "name": "Campooz Fat Freddy* 2019 vouwwagen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/2/524734-524734-images_main-campooz_fat_freddy_nieuw-ecommerce.jpg",
                        "price": "6.950,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "285",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/campooz-fat-freddy-2019-vouwwagen.html",
                                "price": "6.950,00"
                            },
                            {
                                "name": "dewitschijndel",
                                "url": "https://www.dewitschijndel.nl/campooz-fat-freddy-camping-vouwwagen-inclusief-voortent/",
                                "price": "7.695,00"
                            }
                        ]
                    },
                    {
                        "id": "4.3.3",
                        "name": "Jamet Dakota Obelink Special 2019 vouwwagen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/530944-530944-images_main-jamet_dakota_obelink_special_nieuw-ecommerce.jpg",
                        "price": "6.495,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [
                            "Modeljaar 2019   ",
                            "Rijklaar gewicht 395  kg ",
                            "Max. toelaatbaar gewicht 650  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/jamet-dakota-obelink-special-2019-vouwwagen.html",
                                "price": "6.495,00"
                            }
                        ]
                    },
                    {
                        "id": "4.3.4",
                        "name": "Isabella Camp-let Dream 2019 vouwwagen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/2/525778-525778-images_main-isabella_camp-let_dream_nieuw-ecommerce.jpg",
                        "price": "6.495,00",
                        "discount": null,
                        "rating": 4,
                        "ratingCount": "778",
                        "features": [
                            "Modeljaar 2019   ",
                            "Rijklaar gewicht 292  kg ",
                            "Max. toelaatbaar gewicht 500  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/isabella-camp-let-dream-2019-vouwwagen.html",
                                "price": "6.495,00"
                            }
                        ]
                    },
                    {
                        "id": "4.3.5",
                        "name": "Jamet Jametic Outdoor 50 Jubilee 2019 vouwwagen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/0/504037-504037-images_main-jamet_jametic_outdoor_nieuw-ecommerce.jpg",
                        "price": "6.495,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "167",
                        "features": [
                            "Modeljaar 2019   ",
                            "Rijklaar gewicht 385  kg ",
                            "Max. toelaatbaar gewicht 650  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/jamet-jametic-outdoor-50-jubilee-2019-vouwwagen.html",
                                "price": "6.495,00"
                            },
                            {
                                "name": "marktplace",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-toebehoren/a1277494472-tectake-campingkeuken-97x47-5x78-cm-a402923.html?c=827ab967057e9f5ff33a0fad145c2c0e&previousPage=VIP_SIMILAR_LISTING",
                                "price": "75,90"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "4.4",
                "name": "Campers",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-campers.png",
                "resultList": [
                    {
                        "id": "4.4.1",
                        "name": "Dethleffs Pulse I 7051 EB camper",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/6/0/600186-600186-images_main-dethleffs_pulse_i_7051_eb-ecommerce.jpg",
                        "price": "91.500,00",
                        "discount": "96.487,00",
                        "rating": 0,
                        "ratingCount": "71",
                        "features": [
                            "Laadvermogen 459  kg ",
                            "Lengte 740  cm ",
                            "Modeljaar 2019   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/dethleffs-pulse-i-7051-eb-camper.html",
                                "price": "91.500,00"
                            },
                            {
                                "name": "booijcampers",
                                "url": " https://www.booijcampers.nl/camper-kopen/dethleffs-campers/pulse-2019/dethleffs-pulse-t-7051-eb",
                                "price": "75.290,00"
                            }
                        ]
                    },
                    {
                        "id": "4.4.2",
                        "name": "Dethleffs Pulse T 7051 DBM camper",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/6/0/600184-600184-images_main-dethleffs_pulse_t_7051_dbm-ecommerce.jpg",
                        "price": "82.950,00",
                        "discount": "87.287,00",
                        "rating": 0,
                        "ratingCount": "285",
                        "features": [
                            "Laadvermogen 504  kg ",
                            "Lengte 741  cm ",
                            "Modeljaar 2019   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/dethleffs-pulse-t-7051-dbm-camper.html",
                                "price": "82.950,00"
                            },
                            {
                                "name": "dewitschijndel",
                                "url": "https://www.dejonghattem.nl/campers/dethleffs/pulse/t-7051-dbm/",
                                "price": "75.290,00"
                            }
                        ]
                    },
                    {
                        "id": "4.4.3",
                        "name": "Jamet Dakota Obelink Special 2019 vouwwagen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/215e674a619627c0b718e499ab5fa517/5/3/530944-530944-images_main-jamet_dakota_obelink_special_nieuw-ecommerce.jpg",
                        "price": "6.495,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [
                            "Modeljaar 2019   ",
                            "Rijklaar gewicht 395  kg ",
                            "Max. toelaatbaar gewicht 650  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/jamet-dakota-obelink-special-2019-vouwwagen.html",
                                "price": "6.495,00"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "5",
        "name": "Tuin",
        "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-tuin.jpg",
        "key": "Tuin",
        "subCategories": [
            {
                "id": "5.1",
                "name": "Tuinmeubelen",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Tuinmeubelen.png",
                "resultList": [
                    {
                        "id": "5.1.1",
                        "name": "Outdoor Feelings Tomar Evora 240 diningset",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/9/199565-199565-images_main-outdoor_feelings_tomar_evora_240_diningset-ecommerce.jpg",
                        "price": "1.479,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "513",
                        "features": [
                            "Vergelijken"
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/catalog/product/view/id/27249/s/outdoor-feelings-tomar-evora-240-diningset/",
                                "price": "1.479,00"
                            }
                        ]
                    },
                    {
                        "id": "5.1.2",
                        "name": "Outdoor Feelings First Tunis diningset",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/5/558206-558206-images_main-outdoor_feelings_first_tunis_diningset_1-ecommerce.jpg",
                        "price": "399,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "81",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/outdoor-feelings-first-tunis-diningset.html",
                                "price": "399,00"
                            },
                            {
                                "name": "wayfair",
                                "url": "https://www.wayfair.com/furniture/pdx/langley-street-tunis-5-piece-dining-set-with-straight-table-legs-lgly4360.html",
                                "price": "469,00"
                            }
                        ]
                    },
                    {
                        "id": "5.1.3",
                        "name": "Outdoor Feelings Tunis tafel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/4/548414-548414-images_main-outdoor_feelings_tunis_tafel_1-ecommerce.jpg",
                        "price": "249,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/outdoor-feelings-tunis-tafel.html",
                                "price": "249,00"
                            },
                            {
                                "name": "feelings-collectie",
                                "url": "https://www.feelings-collectie.nl/product/boomstamtafel-curve-220cm/",
                                "price": "699,00"
                            }
                        ]
                    },
                    {
                        "id": "5.1.4",
                        "name": "Outdoor Feelings Palais hocker",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/4/540216-540216-images_main-outdoor_feelings_palais_hocker_1-ecommerce.jpg",
                        "price": "119,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/outdoor-feelings-palais-hocker.html",
                                "price": "119,00"
                            },
                            {
                                "name": "feelings-collectie",
                                "url": "https://www.feelings-collectie.nl/product/hocker-ruben/",
                                "price": "88,00"
                            }
                        ]
                    },
                    {
                        "id": "5.1.5",
                        "name": "Outdoor Feelings Tuna hocker",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/4/540529-540529-images_main-outdoor_feelings_tuna_hocker_1-ecommerce.jpg",
                        "price": "119,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/outdoor-feelings-tuna-hocker.html",
                                "price": "119,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/4-seasons-outdoor-tavira-hocker-85-x-90-cm-grijs/9200000092433146/?bltgh=jH9tkS-b51uN9rFOR49OQw.1_26.27.ProductTitle",
                                "price": "195,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/4so-coast-voetenbank/9200000105393486/?bltgh=jH9tkS-b51uN9rFOR49OQw.1_26.28.ProductTitle",
                                "price": "165,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "5.2",
                "name": "Kussens",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Kussens.png",
                "resultList": [
                    {
                        "id": "5.2.1",
                        "name": "Madison Rib Black hocker kussen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/8/188212-188212-images_main-madison_rib_black_zitkussen-ecommerce.jpg",
                        "price": "19,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "4",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/madison-rib-black-hocker-kussen.html",
                                "price": "19,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/madison-rib-black-kussen-50x50cm/9200000046264062/",
                                "price": "19,95"
                            }
                        ]
                    },
                    {
                        "id": "5.2.2",
                        "name": "Madison Outdoor Panama Grey Wicker universeel ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/7/478952-478952-images_main-madison_wicker_universeel_panama_grey-ecommerce.jpg",
                        "price": "17,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "30",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/madison-outdoor-panama-grey-wicker-universeel.html",
                                "price": "17,95"
                            },
                            {
                                "name": "tuinkussenstore",
                                "url": "https://www.tuinkussenstore.nl/wickerkussen-madison-panama-grey-universeel.html?etrias_source=2&etrias_campaign=15&utm_campaign=beslist&utm_medium=cpc&utm_source=beslist&utm_content=beslist-Tuinkussen&bl3nlclid=0dfda01d-62e4-4966-8c94-68c5957451c4",
                                "price": "13,95"
                            },
                            {
                                "name": "beslist",
                                "url": "https://www.beslist.nl/tuin_accessoires/d0004833358/Wickerkussen_Madison_Panama_Grey_Universeel.html?productIdentifier=00000154618827489358826579055",
                                "price": "13,95"
                            }
                        ]
                    },
                    {
                        "id": "5.2.3",
                        "name": "Madison rib black bankkussen ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/0/300070-300070-images_main-madison_bankkussen_120_rib_black_1-ecommerce.jpg",
                        "price": "35,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "43",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/madison-rib-black-bankkussen.html",
                                "price": "35,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/220x48-bankkussen-rib-black/9200000083761291/",
                                "price": "37,00"
                            }
                        ]
                    },
                    {
                        "id": "5.2.4",
                        "name": "Madison Fantasy Orange bankkussen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/4/443943-443943-images_main-madisonbankkussenfantasyorange-ecommerce.jpg",
                        "price": "25,00",
                        "rating": 0,
                        "discount": null,
                        "ratingCount": "28",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/madison-fantasy-orange-bankkussen.html",
                                "price": "25,00"
                            },
                            {
                                "name": "4seizoenentuinkussens",
                                "url": "https://www.4seizoenentuinkussens.nl/sierkussen-fantasy-orange-50x50cm.html",
                                "price": "10,95"
                            }
                        ]
                    },
                    {
                        "id": "5.2.5",
                        "name": "Madison Panama Grey luxe loungekussen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/1/517647-517647-images_main-madison_panama_grey_luxe_loungekussen-ecommerce.jpg",
                        "price": "22,00",
                        "discount": "22,99",
                        "rating": 0,
                        "ratingCount": "28",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/madison-panama-grey-luxe-loungekussen.html",
                                "price": "22,00"
                            },
                            {
                                "name": "4seizoenentuinkussens",
                                "url": "https://www.4seizoenentuinkussens.nl/loungekussen-pure-luxe-grey-60x60cm.html",
                                "price": "22,50"
                            }
                        ]
                    },
                    {
                        "id": "5.2.6",
                        "name": "Madison sierkussen 50 x 50 Victoria Khaki",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/7/473696-473696-images_main-madison_sierkussen_50_x_50_victoria_khaki-ecommerce.jpg",
                        "price": "13,95",
                        "discount": "14,99",
                        "rating": 0,
                        "ratingCount": "28",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/madison-sierkussen-50-x-50-victoria-khaki.html",
                                "price": "13,95"
                            },
                            {
                                "name": "warentuin",
                                "url": "https://www.warentuin.nl/sierkussen-50x50-cm-victoria-khaki-madison/",
                                "price": "29,89"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "5.3",
                "name": "Parsols",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Parasols.png",
                "resultList": [
                    {
                        "id": "5.3.1",
                        "name": "Outdoor Feelings Mylo aluminium vouwpaviljoen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/2/429815-429815-images_main-outdoorfeelingsvouwpaviljoenmylo3x3alulightgrey-ecommerce.jpg",
                        "price": "239,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "71",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/outdoor-feelings-mylo-aluminium-300-x-300-cm-vouwpaviljoen.html",
                                "price": "239,00"
                            },
                            {
                                "name": "partytent-online",
                                "url": "https://www.partytent-online.be/3x3-pro-40-aluminium-easy-up-pvc-wit",
                                "price": "479,00"
                            }
                        ]
                    },
                    {
                        "id": "5.3.2",
                        "name": "Outdoor Feelings Mylo staal vouwpaviljoen ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/2/429813-429813-images_main-outdoorfeelingsvouwpaviljoenmylo3x3lightgrey-ecommerce.jpg",
                        "price": "189,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "285",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/outdoor-feelings-mylo-staal-300-x-300-cm-vouwpaviljoen.html",
                                "price": "189,00"
                            }
                        ]
                    },
                    {
                        "id": "5.3.3",
                        "name": "Bo-Camp parasol",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/5/454758-454758-images_main-parasol_met_knikarm__165_cm_grijs-ecommerce.jpg",
                        "price": "17,95",
                        "discount": "19,95",
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/bo-camp-165-cm-parasol.html",
                                "price": "17,95"
                            },
                            {
                                "name": "outdoorsupply",
                                "url": "https://www.outdoorsupply.nl/tarp-bo-camp-travel-ruit-grijs.html?etrias_source=2&etrias_campaign=15&utm_campaign=beslist&utm_medium=cpc&utm_source=beslist&utm_content=beslist-Luifel&bl3nlclid=925d0000-42c3-4bff-b8c0-c5f34b18b784",
                                "price": "47,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/bo-camp-parasol-met-knikarm-250-cm-sand/9200000103590381/?Referrer=ADVNLGBL000&utm_source=google&utm_medium=cpc&utm_campaign=beslist-Tuin-en-Kerst&utm_term=9200000103590381",
                                "price": "37,99"
                            }
                        ]
                    },
                    {
                        "id": "5.3.4",
                        "name": "Madison Malta parasol  ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/7/474404-474404-images_main-madisonparasolmalta2mgrijs-ecommerce.jpg",
                        "price": "15,95",
                        "discount": null,
                        "rating": 4,
                        "ratingCount": "778",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/madison-malta-o-200-cm-parasol.html",
                                "price": "15,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/madison-delos-parasol-stokparasol-300x200-cm-ecru-knikmechanisme/9200000057833918/?bltgh=nCGsYkoTaK6SXXDfYB9N8w.1_27.28.ProductTitle",
                                "price": "105,00"
                            }
                        ]
                    },
                    {
                        "id": "5.3.5",
                        "name": "Lesli Living Sylvia/Sunny kruisvoet ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/7/472222-472222-images_main-leslilivingkruisvoetsylviasunny-ecommerce.jpg",
                        "price": "9,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "167",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/lesli-living-sylvia-sunny-kruisvoet.html",
                                "price": "9,95"
                            },
                            {
                                "name": "fonq",
                                "url": "https://www.fonq.nl/product/lesli-living-kruisvoet-voor-parasol/368755/",
                                "price": "29,95"
                            }
                        ]
                    },
                    {
                        "id": "5.3.6",
                        "name": "Balkon parasolhouder",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/7/479488-479488-images_main-balkon_parasolhouder_1-ecommerce.jpg",
                        "price": "5,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "167",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/balkon-parasolhouder.html",
                                "price": "5,95"
                            },
                            {
                                "name": "gamma",
                                "url": "https://www.gamma.nl/assortiment/parasolhouder-balkon-staal/p/B588097",
                                "price": "6,95"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "5.4",
                "name": "Terrasverwarming",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Terrasverwarmer.png",
                "resultList": [
                    {
                        "id": "5.4.1",
                        "name": "Garden Impressions Bordeaux hangende heater",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/535912-535912-images_main-garden_impressions_bordeaux_hangende_heater-ecommerce.jpg",
                        "price": "69,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "71",
                        "features": [
                            "Vermogen 1500  w "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/garden-impressions-bordeaux-hangende-heater.html",
                                "price": "69,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/garden-impressions-bordeaux-hangende-heater-60cm-carbon-grey-2000w/9200000051337635/",
                                "price": "99,95"
                            }
                        ]
                    },
                    {
                        "id": "5.4.2",
                        "name": "Eurom partytent heater 2100 terrasverwarmer",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/8/489371-489371-images_main-eurompartyheater-ecommerce.jpg",
                        "price": "84,00",
                        "discount": "89,00",
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [
                            "Vermogen 2100  w "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/eurom-partytent-heater-2100-terrasverwarmer.html",
                                "price": "84,00"
                            },
                            {
                                "name": "coolblue",
                                "url": "https://www.coolblue.nl/product/718143/eurom-partytent-heater-2100.html",
                                "price": "83,90"
                            }
                        ]
                    },
                    {
                        "id": "5.4.3",
                        "name": "Zweedse fakkel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/7/274571-274571-images_main-zweedse_fakkel_1-ecommerce.jpg",
                        "price": "3,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/zweedse-fakkel.html",
                                "price": "3,95"
                            }, {
                                "name": "praxis",
                                "url": "https://www.praxis.nl/tuin-buitenleven/terrasverwarming/vuurkorven/golden-flame-zweedse-fakkel/5328853",
                                "price": "3,19"
                            }
                        ]
                    },
                    {
                        "id": "5.4.4",
                        "name": "Cosi Fire Cosiburner square inbouwbrander ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/7/473657-473657-images_main-cosiburnersquarervsmettopplaat-ecommerce.jpg",
                        "price": "269,00",
                        "discount": "329,00",
                        "rating": 5,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/cosi-fire-cosiburner-square-inbouwbrander.html",
                                "price": "269,00"
                            }, {
                                "name": "beslist",
                                "url": "https://www.beslist.nl/tuin_accessoires/d1018200249/Cosi_Fires_inbouwbrander_Cosiburner__vierkant_black__afm_40_x_40_cm__hoogte_16_5_cm__vermogen_9kW.html?productIdentifier=00000154619840856249826729465",
                                "price": "269,00"
                            }, {
                                "name": "fonq",
                                "url": "https://www.fonq.nl/product/cosi-fires-cosiburner-rectangular-rvs/205741/?channel_code=4&s2m_product_id=194707&utm_source=beslist&utm_medium=cpc&utm_content=194707",
                                "price": "279,00"
                            }
                        ]
                    },
                    {
                        "id": "5.4.5",
                        "name": "Cosi Fire Cosiscoop gaslantaarn ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/7/473658-473658-images_main-cosifirescosiscooplantaarnzwart15155104671-ecommerce.jpg",
                        "price": "69,00",
                        "discount": "79,00",
                        "rating": 0,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/cosi-fire-cosiscoop-gaslantaarn.html",
                                "price": "69,00"
                            }, {
                                "name": "fonq",
                                "url": "https://www.fonq.nl/product/cosi-fires-cosiscoop-gaslantaarn/234453/",
                                "price": "69,00"
                            }
                        ]
                    },
                    {
                        "id": "5.4.6",
                        "name": "Eurom Flameheater terrasverwarmer",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/3/339046-339046-images_main-eurom_flameheater-ecommerce.jpg",
                        "price": "175,00",
                        "discount": "219,00",
                        "rating": 0,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/eurom-flameheater-terrasverwarmer.html",
                                "price": "175,00"
                            }, {
                                "name": "fonq",
                                "url": "https://www.fonq.nl/product/eurom-flameheater-terrasverwarmer/121203/",
                                "price": "229,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "5.5",
                "name": "Zwembaden",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Zwembaden.png",
                "resultList": [
                    {
                        "id": "5.5.1",
                        "name": "Intex frame zwembad",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/8/180440-180440-images_main-intex_frame_pool_366-ecommerce.jpg",
                        "price": "128,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "71",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/intex-frame-zwembad.html",
                                "price": "128,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/garden-impressions-bordeaux-hangende-heater-60cm-carbon-grey-2000w/9200000051337635/",
                                "price": "99,95"
                            }
                        ]
                    },
                    {
                        "id": "5.5.2",
                        "name": "Bestway Steel Pro frame zwembad",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/535903-535903-images_main-bestway-steel-pro-300-201-zwembad-ecommerce.jpg",
                        "price": "64,95",
                        "discount": "99,95",
                        "rating": 4.8,
                        "ratingCount": "6",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/bestway-steel-pro-frame-zwembad.html",
                                "price": "64,95"
                            },
                            {
                                "name": "blokker",
                                "url": "https://www.blokker.nl/p/bestway-steel-pro-frame-zwembad-rond-300-x-76-cm/2374274?utm_campaign=productencontinu&utm_medium=vergelijkers&utm_source=beslist&utm_content=2374274&bl3nlclid=3dc62be9-0d13-47e5-ae43-4e0906af6d18",
                                "price": "90,98"
                            },
                            {
                                "name": "vidaxl",
                                "url": "https://www.vidaxl.nl/e/8719883755465/bestway-zwembad-steel-pro-frame-305x66-cm?utm_source=vidaxl_beslist.nl&utm_medium=price_comparison&utm_campaign=nl_webshop&utm_term=8719883755465&utm_content=Home_Garden&bl3nlclid=ae106f7c-eaea-44b7-86bc-6a6101def4ef",
                                "price": "126,98"
                            }
                        ]
                    },
                    {
                        "id": "5.5.3",
                        "name": "Intex family frame zwembad",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/8/385883-385883-images_main-intexfamliyframepool450x220-ecommerce.jpg",
                        "price": "149,00",
                        "discount": "399,00",
                        "rating": 0,
                        "ratingCount": "102",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/intex-family-frame-zwembad.html",
                                "price": "149,00"
                            }, {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/intex-family-frame-zwembad-450x220x84cm-opzetzwembad/9200000040709668/",
                                "price": "149,00"
                            }
                        ]
                    },
                    {
                        "id": "5.5.4",
                        "name": "Interline thermometer",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/5/151659-151659-images_main-interline_thermometer_vlak_koord-ecommerce.jpg",
                        "price": "2,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/interline-thermometer.html",
                                "price": "2,95"
                            }, {
                                "name": "interline-products",
                                "url": "https://interline-products.nl/zwembaden_2/waterbehandeling_1/testapparatuur_1/interline-draadloze-thermometer",
                                "price": "2,80"
                            }
                        ]
                    },
                    {
                        "id": "5.5.5",
                        "name": "Interline large starterset ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/3/232680-232680-images_main-interlinestarterspakketlarge-ecommerce.jpg",
                        "price": "35,95",
                        "discount": "39,75",
                        "rating": 4.9,
                        "ratingCount": "3",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/interline-large-starterset.html",
                                "price": "35,95"
                            }, {
                                "name": "fonq",
                                "url": "https://www.welkoop.nl/winkel/tuin/genieten-in-de-tuin/zwembaden-zwembadonderhoud/zwembadonderhoud-accessoires/interline-large-starterspakket-2-8kg_1169241",
                                "price": "39,90"
                            }
                        ]
                    },
                    {
                        "id": "5.5.6",
                        "name": "Interline 200 grams chloortabletten",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/2/129209-129209-images_main-interlinechloortablong90200gr-ecommerce.jpg",
                        "price": "19,95",
                        "discount": "21,50",
                        "rating": 5,
                        "ratingCount": "5",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/interline-200-grams-chloortabletten.html",
                                "price": "19,95"
                            }, {
                                "name": "fonq",
                                "url": "https://www.bol.com/nl/p/interline-zwembad-interline-chloortabletten-200-grams-5-kg/9200000026785903/",
                                "price": "90,95"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "6",
        "name": "Acties",
        "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-actie.JPG",
        "key": "Acties",
        "subCategories": [
            {
                "id": "6.1",
                "name": "Acties",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-actie.JPG",
                "resultList": [
                    {
                        "id": "6.1.1",
                        "name": "Obelink Summer 4 Easy Air tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/8/387981-387981-images_main-obelink_summer_4_ea_afb1-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "8",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-summer-4-easy-air-tunneltent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528993726-obelink-summer-4-easy-air-opblaasbare-tent.html?c=be2da871b0d84f75c3056cc6db858f31&previousPage=lr",
                                "price": "250,00"
                            }
                        ]
                    },
                    {
                        "id": "6.1.2",
                        "name": "Obelink Mallorca Easy Air Tall bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530584-530584-images_main-obelink_mallorca_easy_air_1_3-ecommerce.jpg",
                        "price": "479,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "81",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-mallorca-easy-air-tall-bustent.html",
                                "price": "479,00"
                            }
                        ]
                    },
                    {
                        "id": "6.1.3",
                        "name": "Obelink Sahara 400 Bell tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/2/123895-123895-images_main-obelink_sahara_400_101-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "71",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sahara-400-bell-tent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "AliExpress",
                                "url": "https://nl.aliexpress.com/item/32279588317.html",
                                "price": "429,00"
                            },
                            {
                                "name": "Belltent Boutique",
                                "url": "https://belltentboutique.co.uk/products/4m-bell-tent?variant=15864271667243",
                                "price": "379,90"
                            }
                        ]
                    },
                    {
                        "id": "6.1.4",
                        "name": "Obelink Rimini Air Shelter",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530583-530583-images_main-thumbnail_obelink_air-shelter-365_hd_logo_positions-ecommerce.jpg",
                        "price": "269,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-rimini-air-shelter.html",
                                "price": "269,00"
                            }
                        ]
                    },
                    {
                        "id": "6.1.5",
                        "name": "Obelink Sunroof Window 400 Easy Air caravanluifel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/535827-535827-images_main-obelink_sunroof_easy_air-ecommerce.jpg",
                        "price": "269,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sunroof-window-400-easy-air-caravanluifel.html",
                                "price": "269,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/4-seasons-outdoor-tavira-hocker-85-x-90-cm-grijs/9200000092433146/?bltgh=jH9tkS-b51uN9rFOR49OQw.1_26.27.ProductTitle",
                                "price": "195,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/4so-coast-voetenbank/9200000105393486/?bltgh=jH9tkS-b51uN9rFOR49OQw.1_26.28.ProductTitle",
                                "price": "165,00"
                            }
                        ]
                    }, {
                        "id": "6.1.6",
                        "name": "Obelink Manitoba 4 tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/469417-469417-images_main-obelink_manitoba4_tc-ecommerce.jpg",
                        "price": "495,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-manitoba-4-tunneltent.html",
                                "price": "495,00"
                            },
                            {
                                "name": "marktplaats.com",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1536427550-tunneltent-obelink-porto-ercole.html?c=be2da871b0d84f75c3056cc6db858f31&previousPage=lr",
                                "price": "280,00"
                            }
                        ]
                    }, {
                        "id": "6.1.7",
                        "name": "Tristar Icecool KB-7245 45C koelbox",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/8/182524-182524-images_main-tristar_kb-7245_45c-ecommerce.jpg",
                        "price": "199,00",
                        "discount": null,
                        "rating": 4.9,
                        "ratingCount": "174",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/tristar-icecool-kb-7245-45c-koelbox.html",
                                "price": "199,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/tristar-kb-7245-elektrische-koelbox-230v-41-l-blauw-zilver/9200000008138866/?Referrer=ADVNLGBL000&utm_source=google&utm_medium=cpc&utm_campaign=beslist-Fiets-Kamperen-en-Outdoor-Hardwaren&utm_term=9200000008138866",
                                "price": "201,00"
                            },
                            {
                                "name": "blokker",
                                "url": "https://www.blokker.nl/p/tristar-kb-7532-elektrische-koelbox-30-liter/1340050?utm_campaign=productencontinu&utm_medium=vergelijkers&utm_source=beslist&utm_content=1340050&bl3nlclid=091cdc9d-d71b-4115-9f83-d55c834e2edb",
                                "price": "133,93"
                            }
                        ]
                    }
                ]
            },

            {
                "id": "6.2",
                "name": "Acties",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-actie.JPG",
                "resultList": [
                    {
                        "id": "6.1.1",
                        "name": "Obelink Summer 4 Easy Air tunneltent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/8/387981-387981-images_main-obelink_summer_4_ea_afb1-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "8",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-summer-4-easy-air-tunneltent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528993726-obelink-summer-4-easy-air-opblaasbare-tent.html?c=be2da871b0d84f75c3056cc6db858f31&previousPage=lr",
                                "price": "250,00"
                            }
                        ]
                    },
                    {
                        "id": "6.1.2",
                        "name": "Obelink Mallorca Easy Air Tall bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530584-530584-images_main-obelink_mallorca_easy_air_1_3-ecommerce.jpg",
                        "price": "479,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "81",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-mallorca-easy-air-tall-bustent.html",
                                "price": "479,00"
                            }
                        ]
                    },
                    {
                        "id": "6.1.3",
                        "name": "Obelink Sahara 400 Bell tent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/2/123895-123895-images_main-obelink_sahara_400_101-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "71",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sahara-400-bell-tent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "AliExpress",
                                "url": "https://nl.aliexpress.com/item/32279588317.html",
                                "price": "429,00"
                            },
                            {
                                "name": "Belltent Boutique",
                                "url": "https://belltentboutique.co.uk/products/4m-bell-tent?variant=15864271667243",
                                "price": "379,90"
                            }
                        ]
                    },
                    {
                        "id": "6.1.4",
                        "name": "Obelink Rimini Air Shelter",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530583-530583-images_main-thumbnail_obelink_air-shelter-365_hd_logo_positions-ecommerce.jpg",
                        "price": "269,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-rimini-air-shelter.html",
                                "price": "269,00"
                            }
                        ]
                    },
                    {
                        "id": "6.1.5",
                        "name": "Obelink Sunroof Window 400 Easy Air caravanluifel",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/535827-535827-images_main-obelink_sunroof_easy_air-ecommerce.jpg",
                        "price": "269,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-sunroof-window-400-easy-air-caravanluifel.html",
                                "price": "269,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/4-seasons-outdoor-tavira-hocker-85-x-90-cm-grijs/9200000092433146/?bltgh=jH9tkS-b51uN9rFOR49OQw.1_26.27.ProductTitle",
                                "price": "195,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/4so-coast-voetenbank/9200000105393486/?bltgh=jH9tkS-b51uN9rFOR49OQw.1_26.28.ProductTitle",
                                "price": "165,00"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "7",
        "name": "Voortenten & luifels",
        "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-voortenten-luifels.jpg",
        "key": "Voortenten & luifels",
        "subCategories": [
            {
                "id": "7.1",
                "name": "Caravanvoortenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-caravanvoortenten.png",
                "resultList": [
                    {
                        "id": "7.1.1",
                        "name": "Hypercamp Onyx 240 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/6/0/600013-600013-images_main-hypercamp_onyx_240_caravanvoortent-ecommerce.jpg",
                        "price": "559,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "8",
                        "features": [
                            "Diepte 240  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-onyx-240-caravanvoortent.html",
                                "price": "599,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1539287509-voortent-hypercamp-geneve-350-omloopmaat-11.html?c=a2384ef0ece270f44503df9f8598c624&previousPage=lr",
                                "price": "600,00"
                            }
                        ]
                    },
                    {
                        "id": "7.1.2",
                        "name": "Hypercamp Sorrento 240 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/6/0/600014-600014-images_main-hypercamp_sorrento_240_caravanvoortent-ecommerce.jpg",
                        "price": "619,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "81",
                        "features": [
                            "Diepte 240  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-sorrento-240-caravanvoortent.html",
                                "price": "619,00"
                            },
                            {
                                "name": "speurders",
                                "url": "https://www.speurders.nl/overzicht/caravans-en-kamperen/voortenten-en-luifels/f_particulier_zakelijk-particulier/page/2/nieuwe-dorema-mistral-all-season-187369033.html?sr_ab=1&query=hoeslaken+90+x+200+x+30&local-distance_value=0",
                                "price": "495,00"
                            }
                        ]
                    },
                    {
                        "id": "7.1.3",
                        "name": "Hypercamp Casa Grande 300 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/6/0/600006-600006-images_main-hypercamp_casa_grande_300-ecommerce.jpg",
                        "price": "919,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "71",
                        "features": [
                            "Diepte 320  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-casa-grande-300-caravanvoortent.html",
                                "price": "919,00"
                            },
                            {
                                "name": "AliExpress",
                                "url": "https://nl.aliexpress.com/item/32279588317.html",
                                "price": "429,00"
                            },
                            {
                                "name": "Belltent Boutique",
                                "url": "https://belltentboutique.co.uk/products/4m-bell-tent?variant=15864271667243",
                                "price": "379,90"
                            }
                        ]
                    },
                    {
                        "id": "7.1.4",
                        "name": "Goldcamp Windsor 275 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/6/0/600019-600019-images_main-hypercamp_windsor_275_caravanvoortent-ecommerce.jpg",
                        "price": "789,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [
                            "Diepte 275  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/goldcamp-windsor-275-caravanvoortent.html",
                                "price": "789,00"
                            },
                            {
                                "name": "goldcamp",
                                "url": "https://www.goldcamp.nl/",
                                "price": "789,00"
                            }
                        ]
                    },
                    {
                        "id": "7.1.5",
                        "name": "Hypercamp Granada 270 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/6/0/600005-600005-images_main-hypercamp_granada_270-ecommerce.jpg",
                        "price": "549,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [
                            "Diepte 270  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-granada-270-caravanvoortent.html",
                                "price": "549,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/caravan-accessoires/m1539284637-hypercamp-obelink-voortent-mt-13-met-powergrip-alu-frame.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "500,00"
                            }
                        ]
                    }, {
                        "id": "7.1.6",
                        "name": "Goldcamp Bonaire 350 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/6/0/600021-600021-images_main-goldcamp_bonaire_350_caravanvoortent-ecommerce.jpg",
                        "price": "1.099,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [
                            "Diepte 350  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/goldcamp-bonaire-350-caravanvoortent.html",
                                "price": "1.099,00"
                            },
                            {
                                "name": "lamainscampingshow.com",
                                "url": "https://www.lamainscampingshow.nl/goldcamp-bonaire-350/t21e5735/",
                                "price": "999,00"
                            }
                        ]
                    }, {
                        "id": "7.1.7",
                        "name": "Hypercamp Vario Front 275 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/6/0/600027-600027-images_main-hypercamp_vario_front-ecommerce.jpg",
                        "price": "889,00",
                        "discount": null,
                        "rating": 4.9,
                        "ratingCount": "174",
                        "features": [
                            "Diepte 275  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-vario-front-275-caravanvoortent.html",
                                "price": "889,00"
                            },
                            {
                                "name": "speurders",
                                "url": "https://www.speurders.nl/overzicht/caravans-en-kamperen/voortenten-en-luifels/hypercamp-genve-350-voortent-excl-erker-maat-3-186994335.html?sr_ab=1&query=Hypercamp+dominant+maat+12",
                                "price": "550,00"
                            }
                        ]
                    }
                ]
            },

            {
                "id": "7.2",
                "name": "Lichtgewicht voortenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-opblaasbare-voortenten.png",
                "resultList": [
                    {
                        "id": "7.2.1",
                        "name": "Obelink Fiesta Comfort caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/5/354857-354857-images_main-fiestacomfort-ecommerce.jpg",
                        "price": "199,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "159",
                        "features": [
                            "Diepte 250  cm ",
                            "Lengte 350  cm ",
                            "Min. rechte raillengte 250  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-fiesta-comfort-caravanvoortent.html",
                                "price": "199,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528993726-obelink-summer-4-easy-air-opblaasbare-tent.html?c=be2da871b0d84f75c3056cc6db858f31&previousPage=lr",
                                "price": "250,00"
                            }
                        ]
                    },
                    {
                        "id": "7.2.2",
                        "name": "Obelink San Antonia caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/1/315208-315208-images_main-obelink_san_antonia_afb1-ecommerce.jpg",
                        "price": "199,00",
                        "discount": "398,00",
                        "rating": 4.6,
                        "ratingCount": "208",
                        "features": [

                            "Diepte 240  cm ",
                            "Lengte 300  cm ",
                            "Min. rechte raillengte 290  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-san-antonia-caravanvoortent.html",
                                "price": "199,00"
                            }
                        ]
                    },
                    {
                        "id": "7.2.3",
                        "name": "Obelink Baroness Window caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/8/382519-382519-images_main-obelink_baroness_window-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "86",
                        "features": [

                            "Diepte 240  cm ",
                            "Lengte 300  cm ",
                            "Min. rechte raillengte 290  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-baroness-window-caravanvoortent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/a1322666903-quick-pitch-luifel-rechts.html?c=c31218e179beb5dcf8f82c79cca58d38&casData=rVyHDU1Nei6wl_TFnzYyZWdZBl5EbTQxlM9-ccbe2YY1D1_482FO3C6RKrgJyulf9M9Zuz1rQ4VDGDoyUvpUD0QMFATAzbubArFLSrk2G_2H_7QkjrdU7GgUEJIc5o4Ah4cFChtm_ld7UD1EykuA20wiBmOc3kEHKsJz1m6nmJmCmmZuEjYshaFCpkuYLJUsBm6vQzeoYwxVS027Q9HV4lHpiEW4DLELNOt2wlJlFDrZRaqpGXIrB9Lu3QI7CqvdbottZVz4-X_oHOV23ww1mvJBozs2aSMJb9Fo0BmrCuyFP4x4twZ1k60TN5eMSSJwtfXVT95qMHfr_OmsDdhM5BNU6PGMaS4VERqCAh92qY6rQeCbp5w22_z_TeJI9afIAgnK7ctgcZdAwbMcRKcNOosTwGbHsqMFaNZ5cqf-_MXfvAaTHC5sR87tdUWTaAE_xTYtwIMCVkpUa4QlaxKvx2EoaYEveswlaLlnlGl-1v8tfY9DBzW9Bb5Tr-0pXk_KJmbHIdDtlOJAxfdL6bbVPveKGhoqRt0uu5L_UjzjakGobobO3Kp5XqFeXvzSrWWynln78cftMbX3lrJfVK6XKmb3OK3BaHjIAiSK06AqMmvJmWofK9QScIT_deIodJwO3sfb-TxivLd6u8f9zJyXPqb3bNGvkYub3_lXJKiWzJc&previousPage=lr",
                                "price": "298,00"
                            }
                        ]
                    },
                    {
                        "id": "7.2.4",
                        "name": "Obelink Treffer Plus caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/8/281275-281275-images_main-obelink_treffer_plus-ecommerce.jpg",
                        "price": "129,00",
                        "discount": "198,00",
                        "rating": 4.8,
                        "ratingCount": "156",
                        "features": [

                            "Diepte 200  cm ",
                            "Lengte 300  cm ",
                            "Min. rechte raillengte 290  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-treffer-plus-caravanvoortent.html",
                                "price": "129,00"
                            },
                            {
                                "name": "camperforum",
                                "url": "https://www.camperforum.nl/viewtopic.php?t=8468376",
                                "price": "129,00"
                            }
                        ]
                    },
                    {
                        "id": "7.2.5",
                        "name": "Obelink Viera 420 Easy Air caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/460201-460201-images_main-obelink_viera_420_easy_air-ecommerce.jpg",
                        "price": "599,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "47",
                        "features": [

                            "Diepte 300  cm ",
                            "Lengte 420  cm ",
                            "Min. rechte raillengte 290  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-viera-420-easy-air-caravanvoortent.html",
                                "price": "599,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528016956-voortent-kampa-ace-air-400.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "598,00"
                            }
                        ]
                    },

                    {
                        "id": "7.2.6",
                        "name": "Obelink Viera Space 390 300D Easy Air caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/0/507273-507273-images_main-obelink_viera_space_ea_390_1-ecommerce.jpg",
                        "price": "549,00",
                        "discount": "1.098,00",
                        "rating": 5,
                        "ratingCount": "47",
                        "features": [

                            "Diepte 250  cm ",
                            "Lengte 400  cm ",
                            "Min. rechte raillengte 290  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-viera-space-390-300d-easy-air-caravanvoortent.html",
                                "price": "549,00"
                            },
                            {
                                "name": "wohnwagen-forum",
                                "url": "http://wohnwagen-forum.de/index.php?thread/98922-viera-easy-air-390-frage-zu-luftbef%C3%BCllung/",
                                "price": "500,00"
                            }
                        ]
                    },

                    {
                        "id": "7.2.7",
                        "name": "Obelink Viera Space 390 Easy Air caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/0/507272-507272-images_main-obelink_viera_space_ea_390_1-ecommerce.jpg",
                        "price": "479,00",
                        "discount": "958,00",
                        "rating": 5,
                        "ratingCount": "12",
                        "features": [

                            "Diepte 250  cm ",
                            "Lengte 390  cm ",
                            "Min. rechte raillengte 290  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-viera-space-390-easy-air-caravanvoortent.html",
                                "price": "479,00"
                            },
                            {
                                "name": "ebay",
                                "url": "https://www.ebay.de/p/1747485967?iid=323090638668",
                                "price": "489,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "7.3",
                "name": "Campertenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/tmp/category/camper-tenten-categorieafbeelding.png",
                "resultList": [
                    {
                        "id": "7.3.1",
                        "name": "Hypercamp Tramper campertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/3/339317-339317-images_main-hypercamp_tramper-ecommerce.jpg",
                        "price": "450,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "159",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-tramper-campertent.html",
                                "price": "450,00"
                            },
                            {
                                "name": "ebay",
                                "url": "https://www.ebay-kleinanzeigen.de/s-anzeige/vorzelt-hypercamp-winterberg-1/1378700559-230-18314",
                                "price": "150,00"
                            }
                        ]
                    },
                    {
                        "id": "7.3.2",
                        "name": "Hypercamp Mobil Camper 420 grey campertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/4/247622-247622-images_main-pop_mobil_camper_grijs-ecommerce.jpg",
                        "price": "795,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "208",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-mobil-camper-420-grey-campertent.html",
                                "price": "795,00"
                            }
                        ]
                    },
                    {
                        "id": "7.3.3",
                        "name": "Obelink Comet 420 PVC campertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/8/382919-382919-images_main-obelink_comet_420_pvc-ecommerce.jpg",
                        "price": "795,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "86",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-comet-420-pvc-campertent.htm",
                                "price": "795,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/a1322666903-quick-pitch-luifel-rechts.html?c=c31218e179beb5dcf8f82c79cca58d38&casData=rVyHDU1Nei6wl_TFnzYyZWdZBl5EbTQxlM9-ccbe2YY1D1_482FO3C6RKrgJyulf9M9Zuz1rQ4VDGDoyUvpUD0QMFATAzbubArFLSrk2G_2H_7QkjrdU7GgUEJIc5o4Ah4cFChtm_ld7UD1EykuA20wiBmOc3kEHKsJz1m6nmJmCmmZuEjYshaFCpkuYLJUsBm6vQzeoYwxVS027Q9HV4lHpiEW4DLELNOt2wlJlFDrZRaqpGXIrB9Lu3QI7CqvdbottZVz4-X_oHOV23ww1mvJBozs2aSMJb9Fo0BmrCuyFP4x4twZ1k60TN5eMSSJwtfXVT95qMHfr_OmsDdhM5BNU6PGMaS4VERqCAh92qY6rQeCbp5w22_z_TeJI9afIAgnK7ctgcZdAwbMcRKcNOosTwGbHsqMFaNZ5cqf-_MXfvAaTHC5sR87tdUWTaAE_xTYtwIMCVkpUa4QlaxKvx2EoaYEveswlaLlnlGl-1v8tfY9DBzW9Bb5Tr-0pXk_KJmbHIdDtlOJAxfdL6bbVPveKGhoqRt0uu5L_UjzjakGobobO3Kp5XqFeXvzSrWWynln78cftMbX3lrJfVK6XKmb3OK3BaHjIAiSK06AqMmvJmWofK9QScIT_deIodJwO3sfb-TxivLd6u8f9zJyXPqb3bNGvkYub3_lXJKiWzJc&previousPage=lr",
                                "price": "799,00"
                            }
                        ]
                    },
                    {
                        "id": "7.3.4",
                        "name": "Hypercamp Mobil Camper 320 grey campertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/2/428484-428484-images_main-pop_mobil_camper_grijs-ecommerce.jpg",
                        "price": "627,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "156",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-mobil-camper-320-grey-campertent.html",
                                "price": "627,00"
                            },
                            {
                                "name": "camperforum",
                                "url": "https://www.camperforum.nl/viewtopic.php?t=8468376",
                                "price": "129,00"
                            }
                        ]
                    },
                    {
                        "id": "7.3.5",
                        "name": "Obelink Rosario Deluxe campertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/2/422565-422565-images_main-obelink_rosario-ecommerce.jpg",
                        "price": "399,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "47",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-rosario-deluxe-campertent.html",
                                "price": "339,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528016956-voortent-kampa-ace-air-400.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "598,00"
                            }
                        ]
                    },

                    {
                        "id": "7.3.6",
                        "name": "Obelink Travel Master campertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/4/243280-243280-images_main-obelink_travel_master-ecommerce.jpg",
                        "price": "299,00",
                        "discount": "518,00",
                        "rating": 5,
                        "ratingCount": "143",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-travel-master-campertent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "wohnwagen-forum",
                                "url": "http://wohnwagen-forum.de/index.php?thread/98922-viera-easy-air-390-frage-zu-luftbef%C3%BCllung/",
                                "price": "300,00"
                            }
                        ]
                    },

                    {
                        "id": "7.3.7",
                        "name": "Kampa Rally Air All Season 330 Drive-Away campertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/4/540054-540054-images_main-kampa_rally_air_all_season_330_drive_away_2020_1-ecommerce.jpg",
                        "price": "1.790,00",
                        "discount": "2.250,00",
                        "rating": 0,
                        "ratingCount": "12",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/kampa-rally-air-all-season-330-drive-away-campertent.html",
                                "price": "1.790,00"
                            },
                            {
                                "name": "tevelde",
                                "url": "https://www.tevelde.nl/kamperen/caravan-camper-accessoires/bus-en-campertenten/kampa-dometic-opblaasbare-campervoortent-rally-air-all-season-drive-away-330-detail.html",
                                "price": "1.790,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "7.4",
                "name": "Bustenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-bustenten.png",
                "resultList": [
                    {
                        "id": "7.4.1",
                        "name": "Obelink Trinity bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/1/315212-315212-images_main-obelink_trinity_afb1-ecommerce.jpg",
                        "price": "99,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "393",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-trinity-bustent.html",
                                "price": "99,00"
                            },
                            {
                                "name": "ebay",
                                "url": "https://www.ebay-kleinanzeigen.de/s-anzeige/vorzelt-hypercamp-winterberg-1/1378700559-230-18314",
                                "price": "150,00"
                            }
                        ]
                    },
                    {
                        "id": "7.4.2",
                        "name": "Obelink Maritime bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/7/1/71136-71136-images_main-obelink_maritime_1-ecommerce.jpg",
                        "price": "169,00",
                        "discount": null,
                        "rating": 4.6,
                        "ratingCount": "82",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-maritime-bustent.html",
                                "price": "795,00"
                            },
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-maritime-bustent.html",
                                "price": "795,00"
                            }
                        ]
                    },
                    {
                        "id": "7.4.3",
                        "name": "Obelink Traveller bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/3/430607-430607-images_main-obelinktraveller3-ecommerce.jpg",
                        "price": "199,00",
                        "discount": null,
                        "rating": 4.5,
                        "ratingCount": "60",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-traveller-bustent.html",
                                "price": "199,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.vrijbuiter.nl/dorema-traveller-bustent_cbaf0029/",
                                "price": "489,00"
                            }
                        ]
                    },
                    {
                        "id": "7.4.4",
                        "name": "Obelink Trinity Heavy Duty bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530244-530244-images_main-obelink_trinity-ecommerce.jpg",
                        "price": "129,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "4",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-trinity-heavy-duty-bustent.html",
                                "price": "129,00"
                            },
                            {
                                "name": "ebay",
                                "url": "https://www.ebay.com.au/p/27024418688",
                                "price": "129,00"
                            }
                        ]
                    },
                    {
                        "id": "7.4.5",
                        "name": "Obelink Paradise VK bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/5/459904-459904-images_main-obelink_paradise_vk_1-ecommerce.jpg",
                        "price": "199,00",
                        "discount": "398,00",
                        "rating": 4.8,
                        "ratingCount": "108",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-paradise-vk-bustent.html",
                                "price": "339,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-toebehoren/m1539705142-obelink-dublin-camping-kast.html?c=a2384ef0ece270f44503df9f8598c624&previousPage=lr",
                                "price": "300,00"
                            }
                        ]
                    },

                    {
                        "id": "7.4.6",
                        "name": "Obelink Mallorca Easy Air Tall bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530584-530584-images_main-obelink_mallorca_easy_air_1_3-ecommerce.jpg",
                        "price": "479,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "143",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-mallorca-easy-air-tall-bustent.html",
                                "price": "479,00"
                            },
                            {
                                "name": "wohnwagen-forum",
                                "url": "http://wohnwagen-forum.de/index.php?thread/98922-viera-easy-air-390-frage-zu-luftbef%C3%BCllung/",
                                "price": "300,00"
                            }
                        ]
                    },

                    {
                        "id": "7.4.7",
                        "name": "Obelink Traveller Tall bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/5/458381-458381-images_main-obelinktravellertall-ecommerce.jpg",
                        "price": "249,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "12",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-traveller-tall-bustent.html",
                                "price": "249,00"
                            },
                            {
                                "name": "tevelde",
                                "url": "https://www.tevelde.nl/kamperen/caravan-camper-accessoires/bus-en-campertenten/kampa-dometic-opblaasbare-campervoortent-rally-air-all-season-drive-away-330-detail.html",
                                "price": "1.790,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "7.5",
                "name": "Opblaasbare voortenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-lichtgewicht-voortenten.png",
                "resultList": [
                    {
                        "id": "7.5.1",
                        "name": "Obelink Viera 420 Easy Air caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/460201-460201-images_main-obelink_viera_420_easy_air-ecommerce.jpg",
                        "price": "599,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "47",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-viera-420-easy-air-caravanvoortent.html",
                                "price": "599,00"
                            }
                        ]
                    },
                    {
                        "id": "7.5.2",
                        "name": "Obelink Maritime bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/9/390269-390269-images_main-obelink_hartland_390_easy_air-ecommerce.jpg",
                        "price": "369,00",
                        "discount": "900,00",
                        "rating": 4.6,
                        "ratingCount": "82",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "Obelink Hartland 390 Easy Air caravanvoortent",
                                "price": "369,00"
                            },
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-maritime-bustent.html",
                                "price": "795,00"
                            }
                        ]
                    },
                    {
                        "id": "7.5.3",
                        "name": "Obelink Viera 280 Easy Air caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/3/435354-435354-images_main-obelink_viera_280_easy_air-ecommerce.jpg",
                        "price": "299,00",
                        "discount": "598,00",
                        "rating": 4.5,
                        "ratingCount": "60",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-viera-280-easy-air-caravanvoortent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528993726-obelink-summer-4-easy-air-opblaasbare-tent.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "250,00"
                            }
                        ]
                    },
                    {
                        "id": "7.5.4",
                        "name": "Obelink Hartland 280 Easy Air caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/465953-465953-images_main-obelinkhartland280easyair-ecommerce.jpg",
                        "price": "299,00",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "38",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-hartland-280-easy-air-caravanvoortent.html",
                                "price": "299,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/m1537090273-hartland-easy-air-280-lucht-caravan-voortent-opblaas-tent.html",
                                "price": "200,00"
                            }
                        ]
                    },
                    {
                        "id": "7.5.5",
                        "name": "Obelink Viera Space 390 Easy Air caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/0/507272-507272-images_main-obelink_viera_space_ea_390_1-ecommerce.jpg",
                        "price": "479,00",
                        "discount": "958,00",
                        "rating": 4.8,
                        "ratingCount": "12",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-viera-space-390-easy-air-caravanvoortent.html",
                                "price": "479,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528993726-obelink-summer-4-easy-air-opblaasbare-tent.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "250,00"
                            }
                        ]
                    },

                    {
                        "id": "7.5.6",
                        "name": "Obelink Mallorca Easy Air Tall bustent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530584-530584-images_main-obelink_mallorca_easy_air_1_3-ecommerce.jpg",
                        "price": "479,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "143",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-mallorca-easy-air-tall-bustent.html",
                                "price": "479,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528993726-obelink-summer-4-easy-air-opblaasbare-tent.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "470,00"
                            }
                        ]
                    },

                    {
                        "id": "7.5.7",
                        "name": "Kampa Ace Air All Season 400 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/9/490620-490620-images_main-kampa_ace_air_allseason_400_2018-ecommerce.jpg",
                        "price": "1.499,00",
                        "discount": "2.250,00",
                        "rating": 4.5,
                        "ratingCount": "4",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/kampa-ace-air-all-season-400-caravanvoortent.html",
                                "price": "1.499,00"
                            },
                            {
                                "name": "v",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/a1315028433-kampa-dometic-opblaasbare-voortent-ace-air-all-season-400.html",
                                "price": "1.799,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "7.6",
                "name": "Wintertenten",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/subcat-overzicht-wintertenten.png",
                "resultList": [
                    {
                        "id": "7.6.1",
                        "name": "Hypercamp Winterberg 3 wintertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/3/339760-339760-images_main-hypercamp_winterberg_3-ecommerce.jpg",
                        "price": "499,00",
                        "discount": "858,00",
                        "rating": 0,
                        "ratingCount": "47",
                        "features": [
                            "Diepte 200  cm ",
                            "Lengte 300  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-winterberg-3-wintertent.html",
                                "price": "499,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/caravan-accessoires/m1539609655-hypercamp-voortent-omloop-11.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "375,00"
                            }
                        ]
                    },
                    {
                        "id": "7.6.2",
                        "name": "Hypercamp Arlberg 3 wintertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/5/357033-357033-images_main-hypercamp_arlberg_3-ecommerce.jpg",
                        "price": "499,00",
                        "discount": "798,00",
                        "rating": 4.6,
                        "ratingCount": "18",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-arlberg-3-wintertent.html",
                                "price": "369,00"
                            },
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-maritime-bustent.html",
                                "price": "795,00"
                            }
                        ]
                    },
                    {
                        "id": "7.6.3",
                        "name": "Hypercamp Zurich 1 wintertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/3/339755-339755-images_main-hypercamp_zurich_1_wintertent-ecommerce.jpg",
                        "price": "199,00",
                        "discount": "378,00",
                        "rating": 4.5,
                        "ratingCount": "40",
                        "features": [

                            "Diepte 150  cm ",
                            "Lengte 160  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-zurich-1-wintertent.html",
                                "price": "199,00"
                            },
                            {
                                "name": "speurders",
                                "url": "https://www.speurders.nl/overzicht/caravans-en-kamperen/voortenten-en-luifels/hypercamp-genve-350-voortent-excl-erker-maat-3-186994335.html?sr_ab=1&order_direction=asc&order_by=price&local-distance_value=0&query=hypercamp+sierra+trend+voortent",
                                "price": "599,00"
                            }
                        ]
                    },
                    {
                        "id": "7.6.4",
                        "name": "Hypercamp Java 4 wintertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/3/339762-339762-images_main-hypercamp_java4grey-ecommerce.jpg",
                        "price": "659,00",
                        "discount": "1.158,00",
                        "rating": 4.8,
                        "ratingCount": "39",
                        "features": [

                            "Diepte 235  cm ",
                            "Lengte 385  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-java-4-wintertent.html",
                                "price": "659,00"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/m1538817278-hypercamp-350.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "300,00"
                            }
                        ]
                    },
                    {
                        "id": "7.6.5",
                        "name": "Hypercamp Java 2 wintertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/3/339761-339761-images_main-hypercamp_java2grijs-ecommerce.jpg",
                        "price": "499,00",
                        "discount": "998,00",
                        "rating": 4.8,
                        "ratingCount": "27",
                        "features": [

                            "Diepte 235  cm ",
                            "Lengte 385  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-java-2-wintertent.html",
                                "price": "499,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528993726-obelink-summer-4-easy-air-opblaasbare-tent.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "250,00"
                            }
                        ]
                    },

                    {
                        "id": "7.6.6",
                        "name": "Hypercamp Winterberg 4 wintertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/2/428925-428925-images_main-hypercamp_winterberg_4-ecommerce.jpg",
                        "price": "499,00",
                        "discount": "938,00",
                        "rating": 0,
                        "ratingCount": "143",
                        "features": [

                            "Diepte 200  cm ",
                            "Lengte 350  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-winterberg-4-wintertent.html",
                                "price": "499,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/kamperen-tenten/m1528993726-obelink-summer-4-easy-air-opblaasbare-tent.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "250,00"
                            }
                        ]
                    },

                    {
                        "id": "7.6.7",
                        "name": "Hypercamp Winterberg 2 wintertent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/3/339759-339759-images_main-winterberg2-ecommerce.jpg",
                        "price": "399,00",
                        "discount": "758,00",
                        "rating": 4.5,
                        "ratingCount": "4",
                        "features": [

                            "Diepte 200  cm ",
                            "Lengte 350  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-winterberg-2-wintertent.html",
                                "price": "399,00"
                            },
                            {
                                "name": "v",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/a1315028433-kampa-dometic-opblaasbare-voortent-ace-air-all-season-400.html",
                                "price": "499,00"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "8",
        "name": "Technische accessoires",
        "imageUrl": "https://www.obelink.nl/media/catalog/category/cat-technische-accessoires.jpg",
        "key": "Technische accessoires",
        "subCategories": [
            {
                "id": "8.1",
                "name": "Movers",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/movers.png",
                "resultList": [
                    {
                        "id": "8.1.1",
                        "name": "Obelink Excellent Automatic II mover ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/7/472723-472723-images_main-obelink_excellent_automatic_ii-ecommerce.jpg",
                        "price": "799,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "8",
                        "features": [
                            "Type mover Automatisch   ",
                            "Max. toelaatbaar gewicht 1800  kg ",
                            "Totaalgewicht 34  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-excellent-automatic-ii-mover.html",
                                "price": "799,00"
                            },
                            {
                                "name": "camping4fun",
                                "url": "https://camping4fun.de/421-Mover-Hypercamp-Prestige-II-von-Obelink.htm",
                                "price": "799,00"
                            }
                        ]
                    },
                    {
                        "id": "8.1.2",
                        "name": "Obelink Prestige II mover",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/2/422549-422549-images_main-obelinkprestigeiib-ecommerce.jpg",
                        "price": "699,00",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "854",
                        "features": [
                            "Type mover Half automatisch   ",
                            "Max. toelaatbaar gewicht 1800  kg ",
                            "Totaalgewicht 36  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-prestige-ii-mover.html",
                                "price": "699,00"
                            },
                            {
                                "name": "caravanmovershop",
                                "url": "https://www.caravanmovershop.nl/Obelink/producten-44946-Obelink-afstandsbediening-voor-Prestige-II-.php",
                                "price": "698,00"
                            }
                        ]
                    },
                    {
                        "id": "8.1.3",
                        "name": "Enduro EM305+ mover",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/531277-531277-images_main-enduro-em305_-mover-ecommerce.jpg",
                        "price": "1.395,00",
                        "discount": "1.295",
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [
                            "Type mover Half automatisch   ",
                            "Max. toelaatbaar gewicht 2000  kg ",
                            "Totaalgewicht 33  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/enduro-em305-mover-531277.html",
                                "price": "919,00"
                            },
                            {
                                "name": "campingtrend",
                                "url": "https://www.campingtrend.nl/enduro-em-305-nieuwe-krachtige-caravanmover/?cli_action=1587448234.625",
                                "price": "429,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/enduro-em305-volautomaat-caravanmover/9200000121677039/",
                                "price": "1.525,90"
                            }
                        ]
                    },
                    {
                        "id": "8.1.4",
                        "name": "Truma SX mover ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/467716-467716-images_main-trumamoversx-ecommerce.jpg",
                        "price": "1.224,00",
                        "discount": "1.295,00",
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [
                            "Type mover Half automatisch   ",
                            "Max. toelaatbaar gewicht 2000  kg ",
                            "Totaalgewicht 35  kg "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/truma-sx-mover.html",
                                "price": "1.295,00"
                            },
                            {
                                "name": "reinders-rekreatie",
                                "url": "https://www.reinders-rekreatie.nl/truma-mover-sx/",
                                "price": "1.295,00"
                            }
                        ]
                    },
                    {
                        "id": "8.1.5",
                        "name": "Hypercamp Granada 270 caravanvoortent",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/0/202770-202770-images_main-adapterplatenlaagchassis-ecommerce.jpg",
                        "price": "549,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [
                            "Diepte 270  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-granada-270-caravanvoortent.html",
                                "price": "549,00"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/caravan-accessoires/m1539284637-hypercamp-obelink-voortent-mt-13-met-powergrip-alu-frame.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "500,00"
                            }
                        ]
                    }, {
                        "id": "8.1.6",
                        "name": "Hypercamp Prestige II afstandsbediening",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/5/356371-356371-images_main-hypercampafstandsbedieninge203prestigeii-ecommerce.jpg",
                        "price": "49,99",
                        "discount": "70,00",
                        "rating": 0,
                        "ratingCount": "576",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/hypercamp-prestige-ii-afstandsbediening.html",
                                "price": "49,99"
                            },
                            {
                                "name": "marktplaats.nl",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/caravan-accessoires/m1532758633-afstandbediening-mover-alko.html?c=be2da871b0d84f75c3056cc6db858f31&previousPage=lr",
                                "price": "49,00"
                            }
                        ]
                    }, {
                        "id": "8.1.7",
                        "name": "Beschermrubber voor spindel van mover",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/3/430017-430017-images_main-spindelrubbervoorem203-ecommerce.jpg",
                        "price": "4,95",
                        "discount": null,
                        "rating": 4.9,
                        "ratingCount": "174",
                        "features": [
                            "Diepte 275  cm ",
                            "Materiaal buitendoek PVC   "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/beschermrubber-voor-spindel-van-mover.html",
                                "price": "4,95"
                            },
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/beschermrubber-voor-spindel-van-mover.html",
                                "price": "4,95"
                            }
                        ]
                    }
                ]
            },

            {
                "id": "8.2",
                "name": "Chassis",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Chassis.png",
                "resultList": [
                    {
                        "id": "8.2.1",
                        "name": "Pro Plus neuswiel met weegfunctie",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/6/167312-167312-images_main-neuswielweegfunctie-ecommerce.jpg",
                        "price": "34,95",
                        "discount": "39,95",
                        "rating": 4.4,
                        "ratingCount": "117",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/pro-plus-neuswiel-met-weegfunctie.html",
                                "price": "34,95"
                            },
                            {
                                "name": "kampeerwereld",
                                "url": "https://www.kampeerwereld.nl/proplus-neuswiel-48mm-met-weegfunctie-341510/",
                                "price": "47,95"
                            }
                        ]
                    },
                    {
                        "id": "8.2.2",
                        "name": "Obelink spiegelkappen korte arm",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/2/528391-528391-images_main-obelink_spiegelkappen_wit_kort_1-ecommerce.jpg",
                        "price": "49,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "208",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-spiegelkappen-korte-arm.html",
                                "price": "49,95"
                            }
                        ]
                    },
                    {
                        "id": "8.2.3",
                        "name": "Obelink spiegelkappen lange arm",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/2/528389-528389-images_main-obelink_spiegelkappen_wit_lang_1-ecommerce.jpg",
                        "price": "39,95",
                        "discount": null,
                        "rating": 4.8,
                        "ratingCount": "86",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-spiegelkappen-lange-arm.html",
                                "price": "39,95"
                            },
                            {
                                "name": "campersalon",
                                "url": "https://www.campersalon.nl/detail/2296491/spiegelprotector-fiat-ducato-x250-x290-korte-arm-wit.htm",
                                "price": "39,00"
                            }
                        ]
                    },
                    {
                        "id": "8.2.4",
                        "name": "Westfalia SSK koppeling gasveer",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/5/458933-458933-images_main-gasveersskkoppeling-ecommerce.jpg",
                        "price": "24,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "35",
                        "features": [

                            "Diepte 200  cm ",
                            "Lengte 300  cm ",
                            "Min. rechte raillengte 290  cm "
                        ],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/westfalia-ssk-koppeling-gasveer.html",
                                "price": "24,95"
                            },
                            {
                                "name": "caravan-winkel",
                                "url": "https://www.caravan-winkel.nl/westfalia-gasveer-ssk-1-2-3.html",
                                "price": "38,99"
                            }
                        ]
                    },
                    {
                        "id": "8.2.5",
                        "name": "Obelink Gibson leveller ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/2/529107-529107-images_main-obelink_leveller_gibson-ecommerce.jpg",
                        "price": "22,90",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "11",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-gibson-leveller.html",
                                "price": "22,90"
                            },
                            {
                                "name": "obelink.nl",
                                "url": "https://www.obelink.nl/milenco-aluminium-leveller.html",
                                "price": "139,00"
                            }
                        ]
                    },

                    {
                        "id": "8.2.6",
                        "name": "Obelink 300N Hobby gasveer  ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/8/384433-384433-images_main-disselbakgasveer200n-ecommerce.jpg",
                        "price": "19,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "47",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-300n-hobby-gasveer.html",
                                "price": "19,95"
                            },
                            {
                                "name": "mammuth",
                                "url": "https://www.mammuth.nl/hang-en-sluitwerk/gasveren/gasveren/gasdrukveer-300-n",
                                "price": "19,95"
                            }
                        ]
                    },

                    {
                        "id": "8.2.7",
                        "name": "Bo-Camp disselhoes ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/2/329347-329347-images_main-bocamp_disselhoes_4117351-ecommerce.jpg",
                        "price": "10,95",
                        "discount": "12,75",
                        "rating": 4.8,
                        "ratingCount": "108",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/bo-camp-disselhoes.html",
                                "price": "10,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/bo-camp-disselhoes-met-sluitkoord-61x82-cm-antraciet/9200000087756702/",
                                "price": "15,99"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "8.3",
                "name": "Elektriciteit",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Elektriciteit.png",
                "resultList": [
                    {
                        "id": "8.3.1",
                        "name": "Beaut AGM accu",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/3/4/341579-341579-images_main-beaut_agmaccu_24ah-ecommerce.jpg",
                        "price": "54,95",
                        "discount": "71,40",
                        "rating": 0,
                        "ratingCount": "159",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/beaut-agm-accu.html",
                                "price": "54,95"
                            },
                            {
                                "name": "beautsolar",
                                "url": "http://www.beautsolar.nl/accus/AGM_accu_100ah/",
                                "price": "55,00"
                            }
                        ]
                    },
                    {
                        "id": "8.3.2",
                        "name": "Coleman Euro omvormer",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/2/124571-124571-images_main-capingaz_euro_transformer_230vac_12vdc-ecommerce.jpg",
                        "price": "17,95",
                        "discount": "24,99",
                        "rating": 4.8,
                        "ratingCount": "56",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/coleman-euro-omvormer.html",
                                "price": "17,95"
                            },
                            {
                                "name": "obelink",
                                "url": "https://www.jankrul.nl/winkel/coleman-campingaz-omvormer-voor-koelkasten/",
                                "price": "29,95"
                            }
                        ]
                    },
                    {
                        "id": "8.3.3",
                        "name": "Obelink c-Go acculader",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/468358-468358-images_main-obelink_acculader_cgo_20a-ecommerce.jpg",
                        "price": "69,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "18",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-c-go-acculader.html",
                                "price": "69,95"
                            },
                            {
                                "name": "ivra-electronics",
                                "url": "http://www.ivra-electronics.nl/NL/P5/producten-IVRA-Electronics/c-Go-24V-acculader/c-Go-Acculader-24V-6A-12A",
                                "price": "70,00"
                            }
                        ]
                    },
                    {
                        "id": "8.3.4",
                        "name": "Pro Plus CEE verlengkabel ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/466895-466895-images_main-pro-plus-cee-verlengkabel-10-ecommerce.jpg",
                        "price": "11,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "156",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/pro-plus-cee-verlengkabel.html",
                                "price": "11,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/proplus-cee-verlengkabel-3g-1-5mm-neopreen-10-meter/9200000088753441/",
                                "price": "29,50"
                            }
                        ]
                    },
                    {
                        "id": "8.3.5",
                        "name": "Pro Plus haakse CEE verlengkabel ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/466898-466898-images_main-pro-plus-cee-haakse-verlengkabel-ecommerce.jpg",
                        "price": "16,95",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "47",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/pro-plus-haakse-cee-verlengkabel.html",
                                "price": "16,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/proplus-cee-verlengkabel-3g-1-5mm-neopreen-10-meter/9200000088753441/",
                                "price": "29,50"
                            }
                        ]
                    },

                    {
                        "id": "8.3.6",
                        "name": "Haba C-Line USB contactdoos",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/4/442400-442400-images_main-habaclineusbinbouwdoos-ecommerce.jpg",
                        "price": "12,50",
                        "discount": "19,95",
                        "rating": 4.8,
                        "ratingCount": "15",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/haba-c-line-usb-contactdoos.html",
                                "price": "12,50"
                            },
                            {
                                "name": "haba",
                                "url": "https://www.haba.nl/schakelmateriaal%2012v/c-line-usb-kontaktdoos-dubbel-3-1a-1",
                                "price": "19,95"
                            }
                        ]
                    },

                    {
                        "id": "8.3.7",
                        "name": "Pro Plus 4-weg CEE stekkerdoos",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/466911-466911-images_main-pro-plus-4-weg-cee-stekkerdoos1-ecommerce.jpg",
                        "price": "1.790,00",
                        "discount": "2.250,00",
                        "rating": 0,
                        "ratingCount": "12",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/pro-plus-4-weg-cee-stekkerdoos.html",
                                "price": "1.790,00"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/proplus-cee-4-weg-schuko-stekkerdoos-1-5-meter/9200000088753343/",
                                "price": "22,90"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "8.4",
                "name": "Water",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Water.png",
                "resultList": [
                    {
                        "id": "8.4.1",
                        "name": "Reich TWIN dompelpomp ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/4/143267-143267-images_main-reich_twin19l-ecommerce.jpg",
                        "price": "38,50",
                        "discount": "59,20",
                        "rating": 5,
                        "ratingCount": "96",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/reich-twin-dompelpomp.html",
                                "price": "38,50"
                            },
                            {
                                "name": "dewitschijndel",
                                "url": "https://www.dewitschijndel.nl/reich-twin-19l-dompelpomp/",
                                "price": "38,50"
                            }
                        ]
                    },
                    {
                        "id": "8.4.2",
                        "name": "Shurflo Trailking waterpomp",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/4/247928-247928-images_main-shurflo_trailking13400077874fdee56be374b-ecommerce.jpg",
                        "price": "68,50",
                        "discount": "97,00",
                        "rating": 0,
                        "ratingCount": "82",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/shurflo-trailking-waterpomp.html",
                                "price": "68,50"
                            },
                            {
                                "name": "dewitschijndel",
                                "url": "https://www.dewitschijndel.nl/shurflo-trail-king-waterpomp-12-volt-6-8-liter-1-4-bar/",
                                "price": "68,99"
                            }
                        ]
                    },
                    {
                        "id": "8.4.3",
                        "name": "Reich Style 3005 mengkraan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/7/5/75672-75672-images_main-reich_mengkraan_style_3005_653030500b-ecommerce.jpg",
                        "price": "27,95",
                        "discount": "50,50",
                        "rating": 4.5,
                        "ratingCount": "60",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/reich-style-3005-mengkraan.html",
                                "price": "27,95"
                            },
                            {
                                "name": "jasicamp",
                                "url": "https://www.jasicamp.nl/reich-mengkraan-trend-style-3005-met-neerklapbare-uitloop-gatmaat-27mm-hoogte-40mm-nr653-030500b/p8147",
                                "price": "38,95"
                            }
                        ]
                    },
                    {
                        "id": "8.4.4",
                        "name": "Reich Keramik Samba mengkraan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/9/3/93882-93882-images_main-reich_mengkraan_keramik_samba_6750400401b-ecommerce.jpg",
                        "price": "42,50",
                        "discount": "64,70",
                        "rating": 5,
                        "ratingCount": "4",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/reich-keramik-samba-mengkraan.html",
                                "price": "42,50"
                            },
                            {
                                "name": "dewitschijndel",
                                "url": "https://www.dewitschijndel.nl/reich-keramik-samba-mengkraan-33-mm/",
                                "price": "53,99"
                            }
                        ]
                    },
                    {
                        "id": "8.4.5",
                        "name": "Killian Din 96 Jerrycan",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/6/165414-165414-images_main-jerrycan13literdin96-ecommerce.jpg",
                        "price": "8,95",
                        "discount": "9,95",
                        "rating": 0,
                        "ratingCount": "108",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/killian-din-96-jerrycan.html",
                                "price": "8,95"
                            },
                            {
                                "name": "camperencaravanonderdelen",
                                "url": "https://www.camperencaravanonderdelen.nl/Killian-10-liter-Jerrycan-/-Gieter-DIN-96",
                                "price": "26,95"
                            }
                        ]
                    },

                    {
                        "id": "8.4.6",
                        "name": "Obelink watervuldop",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/469300-469300-images_main-obelinkwatervuldop-ecommerce.jpg",
                        "price": "17,50",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "4",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/obelink-watervuldop.html",
                                "price": "17,50"
                            },
                            {
                                "name": "caravan-winkel",
                                "url": "https://www.caravan-winkel.nl/water/water-verzorging/watervuldoppen/",
                                "price": "18,00"
                            }
                        ]
                    },

                    {
                        "id": "8.4.7",
                        "name": "Kinzo tuinslang ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/6/463438-463438-images_main-tuinslang10meter-ecommerce.jpg",
                        "price": "7,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/kinzo-tuinslang.html",
                                "price": "7,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/kinzo-tuinslang-15-meter/9200000076970939/?bltgh=ijhlKfTngwXpPPhX5ShKVQ.1_26.34.ProductTitle",
                                "price": "21,99"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "8.5",
                "name": "Airco's & ventilatoren",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/Airco-ventilatoren.png",
                "resultList": [
                    {
                        "id": "8.5.1",
                        "name": "Outwell Christianos camping ventilator",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/0/508317-508317-images_main-outwell_christianos_camping_ventilator-ecommerce.jpg",
                        "price": "19,95",
                        "discount": "24,95",
                        "rating": 0,
                        "ratingCount": "47",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/outwell-christianos-camping-ventilator.html",
                                "price": "19,95"
                            },
                            {
                                "name": "outwell",
                                "url": "https://www.outwell.com/nl-nl/ecommerce/accessories/christianos-camping-ventilator",
                                "price": "27,95"
                            }
                        ]
                    },
                    {
                        "id": "8.5.2",
                        "name": "All Ride ronde autoventilator ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/0/503918-503918-images_main-all_ride_autoventilator-ecommerce.jpg",
                        "price": "16,95",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/all-ride-ronde-autoventilator.html",
                                "price": "16,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/all-ride-autoventilator-12-volt-o-15-cm-bevestiging-met-klem-zuignap-kunststof-zwart/9200000081534731/",
                                "price": "24,95"
                            }
                        ]
                    },
                    {
                        "id": "8.5.3",
                        "name": "All Ride 12 volt twin ventilator ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/0/503919-503919-images_main-all_ride_dubbele_ventilator_12_volt-ecommerce.jpg",
                        "price": "14,95",
                        "discount": "598,00",
                        "rating": 4.8,
                        "ratingCount": "12",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/all-ride-12-volt-twin-ventilator.html",
                                "price": "14,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/all-ride-auto-ventilator-met-zuignap/9200000060188514/",
                                "price": "14,95"
                            }
                        ]
                    },
                    {
                        "id": "8.5.4",
                        "name": "All Ride 12 volt ventilator ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/7/57854-57854-images_main-12voltventilator-ecommerce.jpg",
                        "price": "4,95",
                        "discount": "6,95",
                        "rating": 3.5,
                        "ratingCount": "17",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/all-ride-12-volt-ventilator.html",
                                "price": "4,95"
                            },
                            {
                                "name": "bol.com",
                                "url": "https://www.bol.com/nl/p/all-ride-auto-ventilator-met-zuignap/9200000060188514/",
                                "price": "14,95"
                            }
                        ]
                    },
                    {
                        "id": "8.5.5",
                        "name": "Truma EM eindstukmoer ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/9/2/92664-92664-images_main-truma_eindstukmoer_em_agaatgrijs-ecommerce.jpg",
                        "price": "3,50",
                        "discount": null,
                        "rating": 5,
                        "ratingCount": "3",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/truma-em-eindstukmoer.html",
                                "price": "3,50"
                            },
                            {
                                "name": "dewitschijndel.nl",
                                "url": "https://www.dewitschijndel.nl/truma-em-05-eindstukmoer/",
                                "price": "2,99"
                            }
                        ]
                    },

                    {
                        "id": "8.5.6",
                        "name": "Telair Dualclima 8400H airco",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/3/430322-430322-images_main-telairdualclima8400h-ecommerce.jpg",
                        "price": "1.635,00",
                        "discount": "1.729,00",
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/telair-dualclima-8400h-airco.html",
                                "price": "1.635,00"
                            },
                            {
                                "name": "bombeeck-digital.nl",
                                "url": "https://www.bombeeck-digital.nl/telair-dualclima-8400h-airco-verwarming",
                                "price": "1.700,00"
                            }
                        ]
                    },

                    {
                        "id": "8.5.7",
                        "name": "Telair Silent 8400H airco",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/4/3/430321-430321-images_main-telairsilent8400h-ecommerce.jpg",
                        "price": "1.589,00",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "4",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/telair-silent-8400h-airco.html",
                                "price": "1.589,00"
                            },
                            {
                                "name": "deschotelshop",
                                "url": "https://www.deschotelshop.nl/telair-silent-8400h-airconditioner",
                                "price": "1.589,00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "8.6",
                "name": "Ventilatie",
                "imageUrl": "https://www.obelink.nl/media/catalog/category/ventilatie.png",
                "resultList": [
                    {
                        "id": "8.6.1",
                        "name": "Pro Plus autoraam ventilatierooster ",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/5/3/530422-530422-images_main-pro-plus-ventilatierooster-voor-autoraam-ecommerce.jpg",
                        "price": "8,95",
                        "discount": "14,95",
                        "rating": 4.9,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/pro-plus-autoraam-ventilatierooster.html",
                                "price": "8,95"
                            },
                            {
                                "name": "allesvoorjeauto",
                                "url": "https://www.allesvoorjeauto.shop/ventilatierooster-voor-autoraam.html",
                                "price": "11,10"
                            }
                        ]
                    },
                    {
                        "id": "8.6.2",
                        "name": "Opbouw ventilatierooster met gaas",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/7/177724-177724-images_main-ventilatieroostercreme-ecommerce.jpg",
                        "price": "14,60",
                        "discount": "14,50",
                        "rating": 4.8,
                        "ratingCount": "3",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/opbouw-ventilatierooster-met-gaas.html",
                                "price": "14,50"
                            },
                            {
                                "name": "gamma",
                                "url": "https://www.gamma.nl/assortiment/sanivesk-luchtventiel-kunststof-wit-o125-mm/p/B118129",
                                "price": "9,99"
                            }
                        ]
                    },
                    {
                        "id": "8.6.3",
                        "name": "Dakluchter met rooster",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/1/0/101248-101248-images_main-dakluchterhobby-ecommerce.jpg",
                        "price": "12,50",
                        "discount": null,
                        "rating": 0,
                        "ratingCount": "40",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/dakluchter-met-rooster.html",
                                "price": "12,50"
                            },
                            {
                                "name": "bedrijfswageninbouwwinkel",
                                "url": "https://www.bedrijfswageninbouwwinkel.nl/Ventilatie-vloer-en-dak-bedrijfswagen",
                                "price": "13,00"
                            }
                        ]
                    },
                    {
                        "id": "8.6.4",
                        "name": "Rooster voor ventilatie",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/4/247558-247558-images_main-ventilatierooster25-ecommerce.jpg",
                        "price": "2,95",
                        "discount": "3,25",
                        "rating": 3,
                        "ratingCount": "1",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/rooster-voor-ventilatie.html",
                                "price": "2,95"
                            },
                            {
                                "name": "marktplaats",
                                "url": "https://www.marktplaats.nl/a/caravans-en-kamperen/voortenten-en-luifels/m1538817278-hypercamp-350.html?c=08c285449651fa109c354bbabe740c1b&previousPage=lr",
                                "price": "3,00"
                            }
                        ]
                    },
                    {
                        "id": "8.6.5",
                        "name": "Opbouw ventilatieroosters",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/1/219350-219350-images_main-rozetinbouwbruinopbouw-ecommerce.jpg",
                        "price": "2,25",
                        "discount": "2,45",
                        "rating": 0,
                        "ratingCount": "27",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/opbouw-ventilatieroosters.html",
                                "price": "2,25"
                            },
                            {
                                "name": "ventilatieshop",
                                "url": "https://www.ventilatieshop.com/ventilatieroosters-ventielen/ventilatieroosters-binnen/afsluitbare-schuifroosters/",
                                "price": "5,49"
                            }
                        ]
                    },

                    {
                        "id": "8.6.6",
                        "name": "Ventilatierooster voorzien van gaas",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/3/234950-234950-images_main-roostercrememetgaas-ecommerce.jpg",
                        "price": "17,95",
                        "discount": "18,75",
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/ventilatierooster-voorzien-van-gaas.html",
                                "price": "17,95"
                            },
                            {
                                "name": "ventilatieland",
                                "url": "https://www.ventilatieland.nl/artikel/19149/ventilatierooster-vierkant-met-grill-250x250-wit-vr2525.html?utm_source=beslistNL&utm_campaign=beslistNLFeed",
                                "price": "6,99"
                            },
                            {
                                "name": "ventilatieland",
                                "url": "https://www.ventilatieland.nl/artikel/19149/ventilatierooster-vierkant-met-grill-250x250-wit-vr2525.html?utm_source=beslistNL&utm_campaign=beslistNLFeed",
                                "price": "6,99"
                            }
                        ]
                    },

                    {
                        "id": "8.6.7",
                        "name": "Ventilatierooster met lamellen",
                        "imageUrl": "https://www.obelink.nl/media/catalog/product/cache/3a30501cd296c3f2e2ec563c3b26af77/2/1/219349-219349-images_main-rozetinbouwrondwit-ecommerce.jpg",
                        "price": "5,50",
                        "discount": "6,10",
                        "rating": 5,
                        "ratingCount": "2",
                        "features": [],
                        "links": [
                            {
                                "name": "obelink",
                                "url": "https://www.obelink.nl/ventilatierooster-met-lamellen.html",
                                "price": "5,50"
                            },
                            {
                                "name": "hornbach",
                                "url": "https://www.hornbach.nl/shop/ROTHEIGNER-Ventilatierooster-rond-wit-125-mm/8610844/artikel.html",
                                "price": "2,75"
                            },
                            {
                                "name": "ventilatieland",
                                "url": "https://www.ventilatieland.be/artikel/19165/lamellenrooster-overdruk-ventilatierooster-190x190-diameter-125-grijs-nd12zp.html",
                                "price": "8,49"
                            }
                        ]
                    }
                ]
            }
        ]
    }

];


export const campingJson = [
    {
        "id": "1",
        "name": "Campings aan zee",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2Fcampingaanzee.jpg?alt=media",
        "key": "Campings aan zee",
        "resultList": [
            {
                "campsiteId": 105222,
                "campsiteName": "Camping Poljana ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7e0c53e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7fdda59.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a48a63.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c009e8a.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa833f512.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d398d18a6894.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/9/5d398d1a9d805.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ca69d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/9/5d3995a7bd439.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d3995a9d6217.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/d/9/e/5d9efa7e282e9.jpeg",
                    "https://cdn3.suncamp.eu/5/d/9/e/5d9efa8003bfc.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a65d71.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e8c02953a.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa8355c11.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d398d18c25bd.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398d1ac6ef9.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ea800.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d3995a7da26b.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d3995aa02a5d.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Mali Lošinj",
                    "region": "Primorje-Gorski Kotar"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 115",
                "price": "€ 115",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "44.55586",
                "longitude": "14.44228",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/kroatie/primorje-gorski-kotar/mali-losinj/camping-poljana-105222/#bookingPriceGridFilter",
                        "price": "1.041,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/hr/poljana-camping-village-resort.html",
                        "price": "230,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/primorje-gorski-kotar/mali-losinj/camping-poljana-105222/",
                        "price": "212,00"
                    }
                ]
            },
            {
                "campsiteId": 104032,
                "campsiteName": "Camping Le Sérignan Plage *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8b8955.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae497911f8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49915a09.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a7c761.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae49c02c89.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54d9e4e1.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae54f54675.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae550f2795.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae55299fe1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5543d115.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8cf118.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae497aaa74.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae4992c179.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a96020.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49c1a630.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54dba52a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54f72fa6.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae55119b0b.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae552b599a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5545925e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Sérignan",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 273",
                "price": "€ 273",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "43.26389",
                "longitude": "3.32119",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.leserignanplage.com/",
                        "price": "78,30"
                    },
                    {
                        "name": "campoala",
                        "url": "https://www.campoala.com/camping/france/languedoc-roussillon/camping-le-serignan-plage",
                        "price": "80,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/frankrijk/languedoc-roussillon/herault/serignan/camping-le-serignan-plage-104032/",
                        "price": "411,00"
                    }
                ]
            },
            {
                "campsiteId": 105205,
                "campsiteName": "Krk Premium Camping Resort *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4dc2b4a0.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4de20ab1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4e01e933.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4e1df9f7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e3e378a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e458c2da5.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e45a760f3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45c2a8db.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e45e44e6c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e45fe7a48.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4dc4896c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4de3ddb2.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e03b10e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4e2073c8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e40b4e8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e458e33f9.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45a90ec7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45c42baa.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e45e5f2fd.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4600e235.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Krk",
                    "region": "Primorje-Gorski Kotar"
                },
                "reviewScore": "8,3",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 123",
                "price": "€ 123",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.02433",
                "longitude": "14.59161",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.camping-adriatic.com/nl/camping-krk-politin?utm_source=eurocampings.nl&utm_medium=listing&utm_campaign=camping_ongoing&utm_content=nl_krk_generic_consideration",
                        "price": "66,20"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/primorje-gorski-kotar/krk/krk-premium-camping-resort-105205/",
                        "price": "225,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/hr/mobile-homes-krk.nl.html",
                        "price": "230,00"
                    }
                ]
            },
            {
                "campsiteId": 105177,
                "campsiteName": "Camping Bijela Uvala ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7be14e7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7db4a05.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7f94e23.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add81c82e4.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add83bdc51.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce4d5ec1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce679357.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adce8147d2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce9bae6c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adceb6fc7e.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7c0a0e7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7dd12f1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7fb42a5.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2add81e8aef.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add83dbf83.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adce4f33e8.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce69362c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce82f846.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce9d6775.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adceb88d25.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Poreč",
                    "region": "Istrië"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 92",
                "price": "€ 92",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.19153",
                "longitude": "13.59689",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/kroatie/istrie/porec/camping-bijela-uvala-105177/",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/istrie/porec/camping-bijela-uvala-105177/",
                        "price": "177,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/hr/caravans-bijela-uvala-porec.nl.html",
                        "price": "300"
                    }
                ]
            },
            {
                "campsiteId": 105172,
                "campsiteName": "Camping Park Umag ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/7/3/5e737402e2a12.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/3/5e7374050133b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737406f2e15.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737408f411d.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03885f5.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9056113.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd96226c0.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd97f137d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd99d549d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9bbe04d.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/7/3/5e7374030eb42.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737405219b5.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/3/5e7374071e3c6.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e73740927241.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03a8fb2.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9071f7b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd963ff10.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd981a0c1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9a02df8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd9bdb8a6.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Umag",
                    "region": "Istrië"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 88",
                "price": "€ 88",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.36717",
                "longitude": "13.54731",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/kroatie/istrie/umag/camping-park-umag-105172/",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/istrie/umag/camping-park-umag-105172/",
                        "price": "180,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/hr/mobile-homes-park-umag.nl.html",
                        "price": "176,00"
                    }
                ]
            },
            {
                "campsiteId": 105237,
                "campsiteName": "Camping Zaton Holiday Resort ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/b/e/1/5be16142e5703.jpeg",
                    "https://cdn2.suncamp.eu/5/b/e/1/5be16145163a1.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614704fb1.jpeg",
                    "https://cdn2.suncamp.eu/5/b/e/1/5be16148f2796.jpeg",
                    "https://cdn3.suncamp.eu/5/b/e/1/5be1614b0715e.jpeg",
                    "https://cdn3.suncamp.eu/5/b/e/1/5be1614de253f.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614fce0f3.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be16151b7cee.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1615397756.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be161557cf50.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614314cb3.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be16145386c7.jpeg",
                    "https://cdn2.suncamp.eu/5/b/e/1/5be161472a4b0.jpeg",
                    "https://cdn3.suncamp.eu/5/b/e/1/5be16149213e6.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614b29fed.jpeg",
                    "https://cdn2.suncamp.eu/5/b/e/1/5be1614e10237.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614feff83.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be16151d8a92.jpeg",
                    "https://cdn3.suncamp.eu/5/b/e/1/5be16153b9b39.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be161559dc3f.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Zaton",
                    "region": "Zadar"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 191",
                "price": "€ 191",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "44.22806",
                "longitude": "15.16917",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/kroatie/zadar/zaton/camping-zaton-holiday-resort-105237/",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/zadar/zaton/camping-zaton-holiday-resort-105237/",
                        "price": "311,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/hr/zaton-holiday-resort-mobile-homes.nl.html",
                        "price": "320,00"
                    }
                ]
            },
            {
                "campsiteId": 110977,
                "campsiteName": "Camping Bi-Village ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/7/5e17011a8477c.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/0/5d80b4900558c.jpeg",
                    "https://cdn3.suncamp.eu/5/8/7/4/5874c033afae7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011c70324.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011e48e2a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6cceaff.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6e73930.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc701d841.jpeg",
                    "https://cdn1.suncamp.eu/5/6/f/2/56f250626e863.jpeg",
                    "https://cdn2.suncamp.eu/5/8/7/4/5874c04d6ac7f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011aa1426.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/0/5d80b4901f40b.jpeg",
                    "https://cdn1.suncamp.eu/5/8/7/4/5874c033d2726.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/7/5e17011c8cbb9.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/7/5e17011e67aa7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6ce5e11.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6e906a2.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc7037228.jpeg",
                    "https://cdn1.suncamp.eu/5/6/f/2/56f2506293114.jpeg",
                    "https://cdn1.suncamp.eu/5/8/7/4/5874c04d927e3.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Fažana",
                    "region": "Istrië"
                },
                "reviewScore": "7,9",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 123",
                "price": "€ 123",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "44.91739",
                "longitude": "13.81104",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/kroatie/istrie/fazana/camping-bi-village-110977/#bookingPriceGridFilter",
                        "price": "1.041,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/istrie/fazana/camping-bi-village-110977/",
                        "price": "250,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/hr/bi-village.nl.html",
                        "price": "280,00"
                    }
                ]
            },
            {
                "campsiteId": 105180,
                "campsiteName": "Camping Valkanela ***",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d3d21a9e.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d3f20908.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4145e3c.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d431ad6a.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d44dbe44.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4834f99.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4a0a473.jpeg",
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d4baa5d7.jpeg",
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d4d60e1f.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4f225c9.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d3d3d5c5.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d3f3fda4.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4168395.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4333f2c.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4501282.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4855d00.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4a23fe5.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4bc6a6b.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4d7943c.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4f3cd0f.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Funtana",
                    "region": "Istrië"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 80",
                "price": "€ 80",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.165",
                "longitude": "13.60772",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/kroatie/istrie/funtana/camping-valkanela-105180/",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/istrie/funtana/camping-valkanela-105180/",
                        "price": "156,00"
                    },
                    {
                        "name": "adriacamps",
                        "url": "https://adriacamps.com/nl/camping/camping-valkanela/",
                        "price": "160,00"
                    }
                ]
            },
            {
                "campsiteId": 100685,
                "campsiteName": "Lanterna Premium Camping Resort *****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb028272.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb204c6b.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3cae92.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb58fb40.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb764f87.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd236fc35.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd25322ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26c2182.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd28c25ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a6fd2c.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb04571e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb221653.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3e9717.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb5acfee.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb784f01.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd2387f7d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd254cc55.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26dcd6c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1edd28dcfc1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a89dad.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Poreč",
                    "region": "Istrië"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 94",
                "price": "€ 94",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.29714",
                "longitude": "13.59439",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.camping-adriatic.com/nl/lanterna-camping-porec?utm_source=eurocampings.nl&utm_medium=listing&utm_campaign=camping_ongoing&utm_content=nl_lanterna_generic_consideration",
                        "price": "54,80"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/istrie/porec/lanterna-premium-camping-resort-100685/",
                        "price": "186,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/hr/mobile-homes-laterna.nl.html",
                        "price": "190,00"
                    }
                ]
            },
            {
                "campsiteId": 104864,
                "campsiteName": "Camping Pra'delle Torri ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a48967f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a694c0f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a873536.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464aa26b90.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464abecce5.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464af1e829.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464b0e092b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464b2a0a37.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464b47d021.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b64121f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464a4a6cef.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464a6b0ab6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464a8902a7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464aa43167.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464ac156af.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464af3e1ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b109b86.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b2bdb7f.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b498847.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464b65dc41.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Caorle",
                    "region": "Veneto"
                },
                "reviewScore": "8,5",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 232",
                "price": "€ 232",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "45.57311",
                "longitude": "12.81253",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/italie/veneto/venetie/caorle/camping-pradelle-torri-104864/#bookingPriceGridFilter",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/italie/veneto/venetie/caorle/camping-pradelle-torri-104864/",
                        "price": "231,00"
                    },
                    {
                        "name": "pradelletorri",
                        "url": "https://www.pradelletorri.it/nl/prebooking/",
                        "price": "250,00"
                    }
                ]
            }
        ]
    },
    {
        "id": "2",
        "name": "Kleine campings",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2Fkleinecampings.jpg?alt=media",
        "key": "Kleine campings",
        "resultList": [
            {
                "campsiteId": 109105,
                "campsiteName": "Camping La Vallée Verte *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129969f5.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633060554.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633208b93.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406333b6196.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e56d36e.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063373723a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339b0711.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e7778b8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633ce469f.jpeg",
                    "https://cdn2.suncamp.eu/5/7/1/7/5717213a43b3b.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129b18ea.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063307aa85.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633223e34.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c406333d1dd2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e58fd2a.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/0/5c40633753028.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339c9cc7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e7956e1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633d0bdb6.jpeg",
                    "https://cdn1.suncamp.eu/5/7/1/7/5717213a6172e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "La Roque-sur-Cèze",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 124",
                "price": "€ 124",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "44.18132",
                "longitude": "4.53537",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/",
                        "price": "311,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/fr/camping-la-vallee-verte-ambert.html?aid=305168&lang=nl",
                        "price": "320,00"
                    }
                ]
            },
            {
                "campsiteId": 100345,
                "campsiteName": "Camping Huttopia Étang de Fouché ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/6/8/e/568e3420dda08.jpeg",
                    "https://cdn2.suncamp.eu/5/6/8/e/568e34227da23.jpeg",
                    "https://cdn3.suncamp.eu/5/8/2/d/582d84237b599.jpeg",
                    "https://cdn2.suncamp.eu/5/8/2/d/582d842512a10.jpeg",
                    "https://cdn3.suncamp.eu/5/8/2/d/582d8426a4e9c.jpeg",
                    "https://cdn3.suncamp.eu/5/6/8/e/568e3427938fd.jpeg",
                    "https://cdn3.suncamp.eu/5/6/8/e/568e34295e7da.jpeg",
                    "https://cdn2.suncamp.eu/5/6/8/e/568e342b34935.jpeg",
                    "https://cdn2.suncamp.eu/5/6/8/e/568e342cd2469.jpeg",
                    "https://cdn3.suncamp.eu/5/6/8/e/568e342e793cd.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/6/8/e/568e34210cff1.jpeg",
                    "https://cdn2.suncamp.eu/5/6/8/e/568e34229e0f7.jpeg",
                    "https://cdn1.suncamp.eu/5/8/2/d/582d84239d583.jpeg",
                    "https://cdn3.suncamp.eu/5/8/2/d/582d842533de3.jpeg",
                    "https://cdn2.suncamp.eu/5/8/2/d/582d8426c67da.jpeg",
                    "https://cdn3.suncamp.eu/5/6/8/e/568e3427b603d.jpeg",
                    "https://cdn3.suncamp.eu/5/6/8/e/568e342981110.jpeg",
                    "https://cdn2.suncamp.eu/5/6/8/e/568e342b55ce4.jpeg",
                    "https://cdn3.suncamp.eu/5/6/8/e/568e342cf16a6.jpeg",
                    "https://cdn3.suncamp.eu/5/6/8/e/568e342e99743.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Arnay-le-Duc",
                    "region": "Bourgondië"
                },
                "reviewScore": "7,6",
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 82",
                "price": "€ 82",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "47.13389",
                "longitude": "4.49844",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/frankrijk/bourgondie/cote-dor/arnay-le-duc/camping-huttopia-etang-de-fouche-100345/#bookingPriceGridFilter",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/frankrijk/bourgondie/cote-dor/arnay-le-duc/camping-huttopia-etang-de-fouche-100345/",
                        "price": "162,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/fr/camping-etang-de-fouche.nl.html",
                        "price": "170,00"
                    }
                ]
            },
            {
                "campsiteId": 122300,
                "campsiteName": "Mobile Homes Kastanija (resort Kastanija ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7df7e2b4.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e1235e3.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e2bd8f6.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e44befd.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e5cf90a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e82eb8d.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e9b7ff4.jpeg",
                    "https://cdn1.suncamp.eu/5/c/9/9/5c99f7eb580a5.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7ecce0d6.jpeg",
                    "https://cdn1.suncamp.eu/5/c/9/9/5c99f7ee6116a.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/c/9/9/5c99f7df9a57a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e13d8f7.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/9/5c99f7e2dbb10.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e46821e.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e5e9226.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e84990b.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7e9d3a08.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7eb71d6c.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7ecea401.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/9/5c99f7ee7c79a.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Novigrad",
                    "region": "Istrië"
                },
                "reviewScore": "8,9",
                "isSunlodgeCategory": false,
                "isGlampingCategory": true,
                "discount": 9,
                "fromPrice": "€ 210",
                "price": "€ 191",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.329722",
                "longitude": "13.5425",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "leserignanplage",
                        "url": "https://www.leserignanplage.com/",
                        "price": "78,30"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/kroatie/istrie/novigrad/mobile-homes-kastanija-resort-kastanija-122300/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 107237,
                "campsiteName": "Camping Sokol Praha ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/c/9/a/5c9a15b7a910f.jpeg",
                    "https://cdn1.suncamp.eu/5/c/9/a/5c9a15b927fae.jpeg",
                    "https://cdn1.suncamp.eu/5/c/9/a/5c9a15ba8f663.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a15bbf36bb.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a15bd5b963.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a14fad35ce.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a14fc46b93.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/a/5c9a14fdc521a.jpeg",
                    "https://cdn1.suncamp.eu/5/c/9/a/5c9a14ff48d3b.jpeg",
                    "https://cdn1.suncamp.eu/5/c/9/a/5c9a1500c9f40.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a15b7bf582.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a15b93e700.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a15baa5ac0.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/a/5c9a15bc17716.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/a/5c9a15bd72075.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a14fae68a9.jpeg",
                    "https://cdn1.suncamp.eu/5/c/9/a/5c9a14fc6070c.jpeg",
                    "https://cdn1.suncamp.eu/5/c/9/a/5c9a14fddf476.jpeg",
                    "https://cdn3.suncamp.eu/5/c/9/a/5c9a14ff63ffa.jpeg",
                    "https://cdn2.suncamp.eu/5/c/9/a/5c9a1500e5d8f.jpeg"
                ],
                "geo": {
                    "country": "Tsjechië",
                    "place": "Praag",
                    "region": "Praag"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 121",
                "price": "€ 121",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "50.08819",
                "longitude": "14.58328",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/tsjechie/praag/praag/camping-sokol-praha-107237/#bookingPriceGridFilter",
                        "price": "147,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/tsjechie/praag/praag/camping-sokol-praha-107237/",
                        "price": "199,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/cz/camping-sokol-praha.nl.html",
                        "price": "200,00"
                    }
                ]
            },
            {
                "campsiteId": 112407,
                "campsiteName": "Knaus Camping- und Ferienhauspark Rügen",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/2/6/5e26f82084112.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/6/5e26f6d7425d1.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/6/5e26f6d91c992.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/6/5e26f6dabad83.jpeg",
                    "https://cdn2.suncamp.eu/5/8/8/0/58809c8c7541a.jpeg",
                    "https://cdn3.suncamp.eu/5/8/8/0/58809c8e7ea4f.jpeg",
                    "https://cdn1.suncamp.eu/5/8/8/0/58809c8fcb739.jpeg",
                    "https://cdn2.suncamp.eu/5/8/8/0/58809c911b0df.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/6/5e26f6dc8dc7a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/6/5e26f6de8a25f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/2/6/5e26f820a0978.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/6/5e26f6d75aaa2.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/6/5e26f6d93554f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/6/5e26f6dad5079.jpeg",
                    "https://cdn1.suncamp.eu/5/8/8/0/58809c8c930ab.jpeg",
                    "https://cdn3.suncamp.eu/5/8/8/0/58809c8e99a2c.jpeg",
                    "https://cdn1.suncamp.eu/5/8/8/0/58809c8fe7a1c.jpeg",
                    "https://cdn3.suncamp.eu/5/8/8/0/58809c9138d8e.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/6/5e26f6dcb0277.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/6/5e26f6deaa02e.jpeg"
                ],
                "geo": {
                    "country": "Duitsland",
                    "place": "Altenkirchen",
                    "region": "Mecklenburg-Voor-Pommeren"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 130",
                "price": "€ 130",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "54.63642",
                "longitude": "13.37533",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/duitsland/mecklenburg-voor-pommeren/altenkirchen/knaus-camping-und-ferienhauspark-rugen-112407/",
                        "price": "32,10"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.suncamp.nl/duitsland/mecklenburg-voor-pommeren/altenkirchen/knaus-camping-und-ferienhauspark-rugen-112407/",
                        "price": "272,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.booking.com/hotel/de/holiday-home-knaus-camping-und-ferienhauspark-rugen.nl.html",
                        "price": "280,00"
                    }
                ]
            },
            {
                "campsiteId": 111285,
                "campsiteName": "Camping Le Rotja ***",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/c/6/b/5c6bd1abcac17.jpeg",
                    "https://cdn2.suncamp.eu/5/6/6/a/566a6c2c4018c.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c2e59823.jpeg",
                    "https://cdn1.suncamp.eu/5/6/6/a/566a6c2fac3e6.jpeg",
                    "https://cdn2.suncamp.eu/5/6/6/a/566a6c3111d39.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c356e3b0.jpeg",
                    "https://cdn2.suncamp.eu/5/6/6/a/566a6c395ac37.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c3aeb829.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c3c112bf.jpeg",
                    "https://cdn2.suncamp.eu/5/6/6/a/566a6c3d1fb79.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/c/6/b/5c6bd1abe3173.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c2c5cca7.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c2e7ae1a.jpeg",
                    "https://cdn1.suncamp.eu/5/6/6/a/566a6c2fc9adc.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c312940a.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c35cb507.jpeg",
                    "https://cdn1.suncamp.eu/5/6/6/a/566a6c398b8c8.jpeg",
                    "https://cdn2.suncamp.eu/5/6/6/a/566a6c3b13023.jpeg",
                    "https://cdn2.suncamp.eu/5/6/6/a/566a6c3c29b54.jpeg",
                    "https://cdn3.suncamp.eu/5/6/6/a/566a6c3d37385.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Fuilla",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,7",
                "isSunlodgeCategory": false,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 117",
                "price": "€ 117",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "42.56239",
                "longitude": "2.35944",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/pyrenees-orientales/fuilla/camping-le-rotja-111285/",
                        "price": "78,30"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/pyrenees-orientales/fuilla/camping-le-rotja-111285/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/pyrenees-orientales/fuilla/camping-le-rotja-111285/",
                        "price": "33,00"
                    }
                ]
            },
            {
                "campsiteId": 112630,
                "campsiteName": "Camping Butterfly ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f1c98ee2.jpeg",
                    "https://cdn1.suncamp.eu/5/8/0/f/580f4f1e03eec.jpeg",
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f1f60661.jpeg",
                    "https://cdn1.suncamp.eu/5/8/0/f/580f4f20b72a6.jpeg",
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f221fbe9.jpeg",
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f1b62f74.jpeg",
                    "https://cdn1.suncamp.eu/5/6/5/f/565f135464477.jpeg",
                    "https://cdn3.suncamp.eu/5/6/5/f/565f135612108.jpeg",
                    "https://cdn2.suncamp.eu/5/6/5/f/565f1357af67d.jpeg",
                    "https://cdn2.suncamp.eu/5/8/0/f/580f4f1cc876d.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f1cb65a7.jpeg",
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f1e23009.jpeg",
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f1f7d48e.jpeg",
                    "https://cdn1.suncamp.eu/5/8/0/f/580f4f20d23da.jpeg",
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f223ad33.jpeg",
                    "https://cdn1.suncamp.eu/5/8/0/f/580f4f1b81bcc.jpeg",
                    "https://cdn2.suncamp.eu/5/6/5/f/565f13548b47c.jpeg",
                    "https://cdn2.suncamp.eu/5/6/5/f/565f135638a7a.jpeg",
                    "https://cdn1.suncamp.eu/5/6/5/f/565f1357d61d7.jpeg",
                    "https://cdn3.suncamp.eu/5/8/0/f/580f4f1ce65f6.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Peschiera del Garda",
                    "region": "Veneto"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 148",
                "price": "€ 148",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.44511",
                "longitude": "10.6945",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/peschiera-del-garda/camping-butterfly-112630/#bookingPriceGridFilter",
                        "price": "378,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/peschiera-del-garda/camping-butterfly-112630/#bookingPriceGridFilter",
                        "price": "388,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/peschiera-del-garda/camping-butterfly-112630/#bookingPriceGridFilter",
                        "price": "390,00"
                    }
                ]
            },
            {
                "campsiteId": 100315,
                "campsiteName": "Camping Huttopia Les Châteaux ***",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/c/1/c/5c1cdf62e6846.jpeg",
                    "https://cdn1.suncamp.eu/5/c/1/c/5c1cdf648211b.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdf660703b.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdf67886a9.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdf690bf58.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdeec36c31.jpeg",
                    "https://cdn3.suncamp.eu/5/c/1/c/5c1cdeeda20f6.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdeef0b27e.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdef09216f.jpeg",
                    "https://cdn3.suncamp.eu/5/c/1/c/5c1cdef209a33.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdf630cf05.jpeg",
                    "https://cdn3.suncamp.eu/5/c/1/c/5c1cdf649bd3c.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdf6620ecd.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdf67a3d40.jpeg",
                    "https://cdn3.suncamp.eu/5/c/1/c/5c1cdf6926176.jpeg",
                    "https://cdn2.suncamp.eu/5/c/1/c/5c1cdeec4ecb7.jpeg",
                    "https://cdn3.suncamp.eu/5/c/1/c/5c1cdeedba1d2.jpeg",
                    "https://cdn3.suncamp.eu/5/c/1/c/5c1cdeef228be.jpeg",
                    "https://cdn1.suncamp.eu/5/c/1/c/5c1cdef0aa748.jpeg",
                    "https://cdn1.suncamp.eu/5/c/1/c/5c1cdef22340a.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Bracieux",
                    "region": "Centre"
                },
                "reviewScore": "8,4",
                "isSunlodgeCategory": false,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 86",
                "price": "€ 86",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "47.55142",
                "longitude": "1.53914",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/frankrijk/centre/loir-et-cher/bracieux/camping-huttopia-les-chateaux-100315/#bookingPriceGridFilter",
                        "price": "185,50"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/centre/loir-et-cher/bracieux/camping-huttopia-les-chateaux-100315/#bookingPriceGridFilter",
                        "price": "190,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/centre/loir-et-cher/bracieux/camping-huttopia-les-chateaux-100315/#bookingPriceGridFilter",
                        "price": "200,00"
                    }
                ]
            },
            {
                "campsiteId": 110209,
                "campsiteName": "Camping Huttopia Beaulieu sur Dordogne ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/8/5/8/5858ef42dd795.jpeg",
                    "https://cdn1.suncamp.eu/5/8/5/8/5858ef44e048e.jpeg",
                    "https://cdn1.suncamp.eu/5/8/5/8/5858ef4623659.jpeg",
                    "https://cdn2.suncamp.eu/5/8/5/8/5858ef4770093.jpeg",
                    "https://cdn1.suncamp.eu/5/9/7/6/5976f2d23e2ac.jpeg",
                    "https://cdn3.suncamp.eu/5/9/7/6/5976f2d3ea59f.jpeg",
                    "https://cdn1.suncamp.eu/5/9/7/6/5976f2d5b0a5d.jpeg",
                    "https://cdn2.suncamp.eu/5/9/7/6/5976f2d76aa6a.jpeg",
                    "https://cdn3.suncamp.eu/5/9/7/6/5976f2d928afc.jpeg",
                    "https://cdn3.suncamp.eu/5/9/7/6/5976f2dba2068.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/8/5/8/5858ef43062c5.jpeg",
                    "https://cdn2.suncamp.eu/5/8/5/8/5858ef4509421.jpeg",
                    "https://cdn2.suncamp.eu/5/8/5/8/5858ef463f6f6.jpeg",
                    "https://cdn2.suncamp.eu/5/8/5/8/5858ef478bca5.jpeg",
                    "https://cdn1.suncamp.eu/5/9/7/6/5976f2d25bda3.jpeg",
                    "https://cdn3.suncamp.eu/5/9/7/6/5976f2d413487.jpeg",
                    "https://cdn1.suncamp.eu/5/9/7/6/5976f2d5ced13.jpeg",
                    "https://cdn2.suncamp.eu/5/9/7/6/5976f2d7871c8.jpeg",
                    "https://cdn2.suncamp.eu/5/9/7/6/5976f2d944923.jpeg",
                    "https://cdn1.suncamp.eu/5/9/7/6/5976f2dbbe9c6.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Beaulieu-sur-Dordogne",
                    "region": "Limousin"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": false,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 78",
                "price": "€ 78",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "44.97956",
                "longitude": "1.84031",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/frankrijk/limousin/correze/beaulieu-sur-dordogne/camping-huttopia-beaulieu-sur-dordogne-110209/#bookingPriceGridFilter",
                        "price": "749,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/limousin/correze/beaulieu-sur-dordogne/camping-huttopia-beaulieu-sur-dordogne-110209/#bookingPriceGridFilter",
                        "price": "750,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/limousin/correze/beaulieu-sur-dordogne/camping-huttopia-beaulieu-sur-dordogne-110209/#bookingPriceGridFilter",
                        "price": "760,00"
                    }
                ]
            },
            {
                "campsiteId": 103335,
                "campsiteName": "Camping Huttopia Baie du Mont St-Michel ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/d/5/5/5d5573d37c5aa.jpeg",
                    "https://cdn3.suncamp.eu/5/d/5/5/5d5573d582160.jpeg",
                    "https://cdn3.suncamp.eu/5/d/5/5/5d5573d76ec08.jpeg",
                    "https://cdn1.suncamp.eu/5/d/5/5/5d5573d967b44.jpeg",
                    "https://cdn2.suncamp.eu/5/d/5/5/5d5573db36bbf.jpeg",
                    "https://cdn3.suncamp.eu/5/a/a/a/5aaa2df154937.jpeg",
                    "https://cdn3.suncamp.eu/5/a/a/a/5aaa2df4266a2.jpeg",
                    "https://cdn1.suncamp.eu/5/a/a/a/5aaa2df6019a1.jpeg",
                    "https://cdn2.suncamp.eu/5/d/5/5/5d5573de50a7e.jpeg",
                    "https://cdn3.suncamp.eu/5/d/5/5/5d5573e03befc.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/d/5/5/5d5573d3995e6.jpeg",
                    "https://cdn1.suncamp.eu/5/d/5/5/5d5573d5a25b4.jpeg",
                    "https://cdn1.suncamp.eu/5/d/5/5/5d5573d789c0f.jpeg",
                    "https://cdn2.suncamp.eu/5/d/5/5/5d5573d982f72.jpeg",
                    "https://cdn1.suncamp.eu/5/d/5/5/5d5573db5161b.jpeg",
                    "https://cdn1.suncamp.eu/5/a/a/a/5aaa2df174e7c.jpeg",
                    "https://cdn2.suncamp.eu/5/a/a/a/5aaa2df44235d.jpeg",
                    "https://cdn3.suncamp.eu/5/a/a/a/5aaa2df61f59c.jpeg",
                    "https://cdn2.suncamp.eu/5/d/5/5/5d5573de6ddbe.jpeg",
                    "https://cdn2.suncamp.eu/5/d/5/5/5d5573e055b80.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Dol-de-Bretagne",
                    "region": "Bretagne"
                },
                "reviewScore": "9,8",
                "isSunlodgeCategory": false,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 84",
                "price": "€ 84",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "48.54939",
                "longitude": "-1.68381",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/ille-et-vilaine/dol-de-bretagne/camping-huttopia-baie-du-mont-st-michel-103335/#bookingPriceGridFilter",
                        "price": "1.051,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/ille-et-vilaine/dol-de-bretagne/camping-huttopia-baie-du-mont-st-michel-103335/#bookingPriceGridFilter",
                        "price": "1.060,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/ille-et-vilaine/dol-de-bretagne/camping-huttopia-baie-du-mont-st-michel-103335/#bookingPriceGridFilter",
                        "price": "1.080,00"
                    }
                ]
            }
        ]
    },
    {
        "id": "3",
        "name": "Campings met zwemparadijs",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2Fcampingmetzwemparadijs.jpg?alt=media",
        "key": "Campings met zwemparadijs",
        "resultList": [
            {
                "campsiteId": 100684,
                "campsiteName": "Camping Terme Catez *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108c03a00.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a108dd06a4.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108f977ba.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a10918f8d4.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093a9022.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a116a70bfa.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a116c712c4.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a116e6c2cc.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a117057b29.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a11724fe5e.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a108c21c35.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108dee84d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a108fb302f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1091af76c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093e71bd.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a116a9247f.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a116c9106c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a116e89af6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a117074d37.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a11726dfa2.jpeg"
                ],
                "geo": {
                    "country": "Slovenië",
                    "place": "Čatež ob Savi",
                    "region": null
                },
                "reviewScore": "7,8",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 175",
                "price": "€ 175",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.89111",
                "longitude": "15.62575",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "terme-catez",
                        "url": "https://www.terme-catez.si/en/",
                        "price": "56,20"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "35,00"
                    }
                ]
            },
            {
                "campsiteId": 106750,
                "campsiteName": "Camping Vilanova Park Cat.1",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9937a9c35.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb059dac3.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a4c216.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993bb1f57.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb075e5fa.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb08e59ac.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb0a79f4d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb0c05c32.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cd929e.jpeg",
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d5171f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f9937bf61f.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb05b221f.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a655f2.jpeg",
                    "https://cdn1.suncamp.eu/5/c/8/f/5c8f993bc8fe9.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb077383a.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb090758e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0a90619.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0c1da61.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cf35cb.jpeg",
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d68279.jpeg"
                ],
                "geo": {
                    "country": "Spanje",
                    "place": "Vilanova i la Geltrú",
                    "region": "Catalonië"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 131",
                "price": "€ 131",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "41.23167",
                "longitude": "1.69106",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "vilanovapark",
                        "url": "https://www.vilanovapark.com/",
                        "price": "62,00"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 109105,
                "campsiteName": "Camping La Vallée Verte *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129969f5.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633060554.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633208b93.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406333b6196.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e56d36e.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063373723a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339b0711.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e7778b8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633ce469f.jpeg",
                    "https://cdn2.suncamp.eu/5/7/1/7/5717213a43b3b.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129b18ea.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063307aa85.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633223e34.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c406333d1dd2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e58fd2a.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/0/5c40633753028.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339c9cc7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e7956e1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633d0bdb6.jpeg",
                    "https://cdn1.suncamp.eu/5/7/1/7/5717213a6172e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "La Roque-sur-Cèze",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 124",
                "price": "€ 124",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "44.18132",
                "longitude": "4.53537",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                        "price": "80,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                        "price": "90,00"
                    }
                ]
            },
            {
                "campsiteId": 104032,
                "campsiteName": "Camping Le Sérignan Plage *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8b8955.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae497911f8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49915a09.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a7c761.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae49c02c89.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54d9e4e1.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae54f54675.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae550f2795.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae55299fe1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5543d115.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8cf118.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae497aaa74.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae4992c179.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a96020.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49c1a630.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54dba52a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54f72fa6.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae55119b0b.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae552b599a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5545925e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Sérignan",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 273",
                "price": "€ 273",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "43.26389",
                "longitude": "3.32119",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/herault/serignan-plage/camping-le-serignan-plage-nature-104031/",
                        "price": "69,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/herault/serignan-plage/camping-le-serignan-plage-nature-104031/",
                        "price": "80,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/herault/serignan-plage/camping-le-serignan-plage-nature-104031/",
                        "price": "100,00"
                    }
                ]
            },
            {
                "campsiteId": 105205,
                "campsiteName": "Krk Premium Camping Resort *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4dc2b4a0.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4de20ab1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4e01e933.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4e1df9f7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e3e378a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e458c2da5.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e45a760f3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45c2a8db.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e45e44e6c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e45fe7a48.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4dc4896c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4de3ddb2.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e03b10e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4e2073c8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e40b4e8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e458e33f9.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45a90ec7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45c42baa.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e45e5f2fd.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4600e235.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Krk",
                    "region": "Primorje-Gorski Kotar"
                },
                "reviewScore": "8,3",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 123",
                "price": "€ 123",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.02433",
                "longitude": "14.59161",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "camping-adriatic",
                        "url": "https://www.camping-adriatic.com/nl/camping-krk-politin?utm_source=eurocampings.nl&utm_medium=listing&utm_campaign=camping_ongoing&utm_content=nl_krk_generic_consideration",
                        "price": "66,20"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 100685,
                "campsiteName": "Lanterna Premium Camping Resort *****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb028272.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb204c6b.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3cae92.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb58fb40.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb764f87.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd236fc35.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd25322ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26c2182.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd28c25ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a6fd2c.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb04571e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb221653.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3e9717.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb5acfee.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb784f01.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd2387f7d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd254cc55.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26dcd6c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1edd28dcfc1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a89dad.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Poreč",
                    "region": "Istrië"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 94",
                "price": "€ 94",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.29714",
                "longitude": "13.59439",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "camping-adriatic",
                        "url": "https://www.camping-adriatic.com/nl/lanterna-camping-porec?utm_source=eurocampings.nl&utm_medium=listing&utm_campaign=camping_ongoing&utm_content=nl_lanterna_generic_consideration",
                        "price": "54,80"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 104766,
                "campsiteName": "Eurocamping Pacengo ***",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/2/9/5e2985c3eced6.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2985c5da198.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/9/5e2985c7d9895.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2985c9a98a0.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2985cb7a6e3.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e298670175e9.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/9/5e2986721347a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e298673e01c6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/9/5e298675cfbe4.jpeg",
                    "https://cdn2.suncamp.eu/5/7/c/0/57c028443bf2c.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2985c41302f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2985c619c64.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2985c7f3f8c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2985c9c8d80.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2985cb957ed.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2986703585d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2986723350c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2986740a519.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e298675ed4ad.jpeg",
                    "https://cdn2.suncamp.eu/5/7/c/0/57c028445b694.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Pacengo di Lazise",
                    "region": "Veneto"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 96",
                "price": "€ 96",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.46722",
                "longitude": "10.71617",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "eurocampingpacengo",
                        "url": "https://www.eurocampingpacengo.it/it",
                        "price": "37,00"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 101060,
                "campsiteName": "Village Norcenni Girasole ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da45729621.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da45902cf0.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da45ac8c1a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/0/d/5e0da45c96287.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da45e4c573.jpeg",
                    "https://cdn2.suncamp.eu/5/e/0/d/5e0da4ea51c6b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4ebf17a3.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4ed84fb6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/6/5e6615a9a01b2.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4f10ec94.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/0/d/5e0da45745631.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da4591f12b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/0/d/5e0da45ae3204.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da45cb00ca.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da45e68559.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4ea6e46e.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da4ec1777c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4ed9f6d8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/6/5e6615a9c3afe.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da4f12aa20.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Figline Valdarno",
                    "region": "Toscane"
                },
                "reviewScore": "7,9",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 91",
                "price": "€ 91",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "43.61333",
                "longitude": "11.44944",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/italie/toscane/florence/figline-valdarno/village-norcenni-girasole-101060/#bookingPriceGridFilter",
                        "price": "280,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/italie/toscane/florence/figline-valdarno/village-norcenni-girasole-101060/#bookingPriceGridFilter",
                        "price": "300,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/italie/toscane/florence/figline-valdarno/village-norcenni-girasole-101060/#bookingPriceGridFilter",
                        "price": "320,00"
                    }
                ]
            },
            {
                "campsiteId": 104751,
                "campsiteName": "Camping Cisano/San Vito ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35cca12a81b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e789235dbf8d.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35cca596f6e.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35a93e9a2eb.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/5/5d35a748d8c76.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/5/5d35a74b21611.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35a94283e1a.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e78923806353.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e78923a217a3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e78923c2f833.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35cca144c2c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e78923607a68.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35cca5b41fa.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35a93eb6724.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/5/5d35a74900e4a.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/5/5d35a74b3cacc.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/5/5d35a942a0095.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e789238268bd.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e78923a43b02.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e78923c4d3a1.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Bardolino",
                    "region": "Veneto"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 139",
                "price": "€ 139",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.52556",
                "longitude": "10.72867",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/bardolino/camping-cisano-san-vito-104751/#bookingPriceGridFilter",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/bardolino/camping-cisano-san-vito-104751/#bookingPriceGridFilter",
                        "price": "80,00"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/bardolino/camping-cisano-san-vito-104751/#bookingPriceGridFilter",
                        "price": "100,00"
                    }
                ]
            },
            {
                "campsiteId": 104864,
                "campsiteName": "Camping Pra'delle Torri ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a48967f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a694c0f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a873536.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464aa26b90.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464abecce5.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464af1e829.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464b0e092b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464b2a0a37.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464b47d021.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b64121f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464a4a6cef.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464a6b0ab6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464a8902a7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464aa43167.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464ac156af.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464af3e1ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b109b86.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b2bdb7f.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b498847.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464b65dc41.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Caorle",
                    "region": "Veneto"
                },
                "reviewScore": "8,5",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 232",
                "price": "€ 232",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "45.57311",
                "longitude": "12.81253",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCampings",
                        "url": "https://www.eurocampings.nl/italie/veneto/venetie/caorle/camping-pradelle-torri-104864/#bookingPriceGridFilter",
                        "price": "78,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/italie/veneto/venetie/caorle/camping-pradelle-torri-104864/#bookingPriceGridFilter",
                        "price": "80,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/italie/veneto/venetie/caorle/camping-pradelle-torri-104864/#bookingPriceGridFilter",
                        "price": "100,00"
                    }
                ]
            }
        ]
    },
    {
        "id": "4",
        "name": "Kamperen aan de rivier",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2Fcamping_aan_de_rivier.jpeg?alt=media",
        "key": "kamperen aan de rivier",
        "resultList": [
            {
                "campsiteId": 109105,
                "campsiteName": "Camping La Vallée Verte *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129969f5.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633060554.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633208b93.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406333b6196.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e56d36e.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063373723a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339b0711.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e7778b8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633ce469f.jpeg",
                    "https://cdn2.suncamp.eu/5/7/1/7/5717213a43b3b.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129b18ea.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063307aa85.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633223e34.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c406333d1dd2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e58fd2a.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/0/5c40633753028.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339c9cc7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e7956e1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633d0bdb6.jpeg",
                    "https://cdn1.suncamp.eu/5/7/1/7/5717213a6172e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "La Roque-sur-Cèze",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 233",
                "price": "€ 233",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "44.18132",
                "longitude": "4.53537",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                        "price": "55,98"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                        "price": "60,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/languedoc-roussillon/gard/la-roque-sur-ceze/camping-la-vallee-verte-109105/#bookingPriceGridFilter",
                        "price": "100,00"
                    }
                ]
            },
            {
                "campsiteId": 109413,
                "campsiteName": "Camping Bella Austria ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a02cf993.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a046bab2.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a05e5f2c.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a0759b53.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce2987b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02cfb86c1.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b0269d55a8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bc0aeb.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a1e1750d.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a1f7aab6.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a02edb06.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a048a007.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a0610d17.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a0777e28.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce437f2.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b02cfd31fe.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/b/5e2b0269ee86a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bde671.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a1e3020f.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a1f986bd.jpeg"
                ],
                "geo": {
                    "country": "Oostenrijk",
                    "place": "Sankt Peter am Kammersberg",
                    "region": "Stiermarken"
                },
                "reviewScore": "7,9",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 94",
                "price": "€ 94",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    },
                    {
                        "Name": "Mindervaliden",
                        "ID": 44
                    }
                ],
                "latitude": "47.18028",
                "longitude": "14.21528",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/oostenrijk/stiermarken/sankt-peter-am-kammersberg/camping-bella-austria-109413/",
                        "price": "27,40"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/oostenrijk/stiermarken/sankt-peter-am-kammersberg/camping-bella-austria-109413/",
                        "price": "30,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/oostenrijk/stiermarken/sankt-peter-am-kammersberg/camping-bella-austria-109413/",
                        "price": "33,00"
                    }
                ]
            },
            {
                "campsiteId": 118514,
                "campsiteName": "Altomincio Family Park ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d81c70b81.jpeg",
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d81e4500b.jpeg",
                    "https://cdn3.suncamp.eu/5/d/0/0/5d00d8201f53e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/0/0/5d00d821c05bb.jpeg",
                    "https://cdn2.suncamp.eu/5/d/0/0/5d00d8239e71b.jpeg",
                    "https://cdn2.suncamp.eu/5/d/0/0/5d00d826b4d58.jpeg",
                    "https://cdn2.suncamp.eu/5/d/0/0/5d00d8287a3e5.jpeg",
                    "https://cdn3.suncamp.eu/5/d/0/0/5d00d82a246bd.jpeg",
                    "https://cdn3.suncamp.eu/5/d/0/0/5d00d82be7fc8.jpeg",
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d82dca184.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/d/0/0/5d00d81c8d6ff.jpeg",
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d81e616a3.jpeg",
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d8203c793.jpeg",
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d821df63c.jpeg",
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d823c3951.jpeg",
                    "https://cdn3.suncamp.eu/5/d/0/0/5d00d826d18e9.jpeg",
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d82896ab1.jpeg",
                    "https://cdn3.suncamp.eu/5/d/0/0/5d00d82a3f8c2.jpeg",
                    "https://cdn2.suncamp.eu/5/d/0/0/5d00d82c10c6a.jpeg",
                    "https://cdn1.suncamp.eu/5/d/0/0/5d00d82de9219.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Valeggio sul Mincio",
                    "region": "Veneto"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 273",
                "price": "€ 273",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.396944",
                "longitude": "10.718056",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/valeggio-sul-mincio/altomincio-family-park-118514/",
                        "price": "27,40"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/valeggio-sul-mincio/altomincio-family-park-118514/",
                        "price": "30,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/italie/veneto/verona/valeggio-sul-mincio/altomincio-family-park-118514/",
                        "price": "35,00"
                    }
                ]
            },
            {
                "campsiteId": 101177,
                "campsiteName": "Camping Sandaya Paris Maisons Laffitte ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/7/8/5e78885fbbf73.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e7888db7afff.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e7888dd7c31a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e7888df7d215.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e7888e184534.jpeg",
                    "https://cdn2.suncamp.eu/5/e/3/9/5e392c9846da3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e7888e50197e.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e7888e71ca41.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e7888e908036.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e7888eae80a0.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/7/8/5e78885fd9180.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e7888db96dd3.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e7888dd9a856.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e7888df9d694.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e7888e1a5916.jpeg",
                    "https://cdn1.suncamp.eu/5/e/3/9/5e392c98656e9.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e7888e51fff0.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e7888e7440e8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e7888e9259ac.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e7888eb13843.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Maisons-Laffitte",
                    "region": "Île-de-France"
                },
                "reviewScore": "8,3",
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 121",
                "price": "€ 121",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    },
                    {
                        "Name": "Mindervaliden",
                        "ID": 44
                    }
                ],
                "latitude": "48.93986",
                "longitude": "2.14583",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "sandaya",
                        "url": "https://www.sandaya.nl/onze-campings/paris-maisons-laffitte?utm_source=ACSI&utm_medium=Partner&utm_campaign=CPC&utm_content=PML&xtor=AL-28-%5Bnl%5D",
                        "price": "35,10"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 103605,
                "campsiteName": "Camping La Vallée de Deauville *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/a/e/b/5aeb1fe1b64cf.jpeg",
                    "https://cdn3.suncamp.eu/5/a/e/b/5aeb1fe3798d3.jpeg",
                    "https://cdn2.suncamp.eu/5/a/e/b/5aeb1fe53cdaf.jpeg",
                    "https://cdn2.suncamp.eu/5/a/e/b/5aeb1fe7017db.jpeg",
                    "https://cdn2.suncamp.eu/5/a/e/b/5aeb1fe8bb729.jpeg",
                    "https://cdn1.suncamp.eu/5/a/e/b/5aeb1feb4f9e8.jpeg",
                    "https://cdn1.suncamp.eu/5/a/e/b/5aeb1fed1660e.jpeg",
                    "https://cdn3.suncamp.eu/5/a/e/b/5aeb1feed4881.jpeg",
                    "https://cdn3.suncamp.eu/5/a/e/b/5aeb1ff09f7c0.jpeg",
                    "https://cdn3.suncamp.eu/5/a/e/b/5aeb1ff262390.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/a/e/b/5aeb1fe1d1e79.jpeg",
                    "https://cdn1.suncamp.eu/5/a/e/b/5aeb1fe396eda.jpeg",
                    "https://cdn2.suncamp.eu/5/a/e/b/5aeb1fe55a35e.jpeg",
                    "https://cdn3.suncamp.eu/5/a/e/b/5aeb1fe71c573.jpeg",
                    "https://cdn1.suncamp.eu/5/a/e/b/5aeb1fe8d840e.jpeg",
                    "https://cdn3.suncamp.eu/5/a/e/b/5aeb1feb6daac.jpeg",
                    "https://cdn1.suncamp.eu/5/a/e/b/5aeb1fed3425b.jpeg",
                    "https://cdn1.suncamp.eu/5/a/e/b/5aeb1feef0045.jpeg",
                    "https://cdn1.suncamp.eu/5/a/e/b/5aeb1ff0bcaab.jpeg",
                    "https://cdn2.suncamp.eu/5/a/e/b/5aeb1ff27cfe6.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Saint-Arnoult",
                    "region": "Laag-Normandië"
                },
                "reviewScore": "7,7",
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 128",
                "price": "€ 128",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "49.32892",
                "longitude": "0.08644",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/cpc/out/103605/",
                        "price": "36,50"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 112972,
                "campsiteName": "Camping Le Village Parisien Varreddes ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/c/4/9/5c49be977e3af.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/9/5c49be9908413.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/58415120e8479.jpeg",
                    "https://cdn3.suncamp.eu/5/8/4/1/5841512261225.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/58415123c6932.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/5841512604987.jpeg",
                    "https://cdn3.suncamp.eu/5/8/4/1/5841512771441.jpeg",
                    "https://cdn1.suncamp.eu/5/7/2/8/572890522c2ef.jpeg",
                    "https://cdn3.suncamp.eu/5/8/4/1/58415128de73d.jpeg",
                    "https://cdn2.suncamp.eu/5/8/4/1/5841512a828d4.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/c/4/9/5c49be9798a25.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/9/5c49be9920a7e.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/5841512113d3a.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/584151227ee1b.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/58415123e3860.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/5841512622c1b.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/584151278f577.jpeg",
                    "https://cdn3.suncamp.eu/5/7/2/8/5728905247b62.jpeg",
                    "https://cdn1.suncamp.eu/5/8/4/1/5841512908b41.jpeg",
                    "https://cdn2.suncamp.eu/5/8/4/1/5841512aa3ec6.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Varreddes",
                    "region": "Île-de-France"
                },
                "reviewScore": "7,8",
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 124",
                "price": "€ 124",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "49.00286",
                "longitude": "2.94131",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "olela",
                        "url": "https://www.olela.nl/campings/camping-village-parisien",
                        "price": "37,40"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 109300,
                "campsiteName": "Camping Beau Rivage ***",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/d/7/9/5d79e9f5d5eca.jpeg",
                    "https://cdn3.suncamp.eu/5/d/7/9/5d790f932bc7a.jpeg",
                    "https://cdn1.suncamp.eu/5/d/7/9/5d790f94a21bb.jpeg",
                    "https://cdn1.suncamp.eu/5/d/7/9/5d790f9629df8.jpeg",
                    "https://cdn3.suncamp.eu/5/d/7/9/5d79e9f775410.jpeg",
                    "https://cdn1.suncamp.eu/5/d/7/9/5d79104996dc8.jpeg",
                    "https://cdn3.suncamp.eu/5/d/7/9/5d79e9f907dec.jpeg",
                    "https://cdn2.suncamp.eu/5/d/7/9/5d79e9fa97ca6.jpeg",
                    "https://cdn2.suncamp.eu/5/d/7/9/5d79e9fc3043e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/7/9/5d79099310369.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/d/7/9/5d79e9f5eea5d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/7/9/5d790f93454e8.jpeg",
                    "https://cdn2.suncamp.eu/5/d/7/9/5d790f94bae02.jpeg",
                    "https://cdn3.suncamp.eu/5/d/7/9/5d790f96410ce.jpeg",
                    "https://cdn2.suncamp.eu/5/d/7/9/5d79e9f78d51d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/7/9/5d791049ace0a.jpeg",
                    "https://cdn3.suncamp.eu/5/d/7/9/5d79e9f92291c.jpeg",
                    "https://cdn2.suncamp.eu/5/d/7/9/5d79e9faaf6a1.jpeg",
                    "https://cdn3.suncamp.eu/5/d/7/9/5d79e9fc48d28.jpeg",
                    "https://cdn1.suncamp.eu/5/d/7/9/5d79099328621.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "La Roque-Gageac",
                    "region": "Aquitanië"
                },
                "reviewScore": null,
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 100",
                "price": "€ 100",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "44.81619",
                "longitude": "1.21472",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/rhone-alpes/ardeche/vallon-pont-darc/camping-beau-rivage-104146/",
                        "price": "45,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/rhone-alpes/ardeche/vallon-pont-darc/camping-beau-rivage-104146/",
                        "price": "50,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/rhone-alpes/ardeche/vallon-pont-darc/camping-beau-rivage-104146/",
                        "price": "80,00"
                    }
                ]
            },
            {
                "campsiteId": 113564,
                "campsiteName": "Camping Val de Cesse ***",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/3/9/5e395b94bfef7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/a/5e4a9b138d064.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/a/5e4a9b15ed92c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/a/5e4a9b17e2d42.jpeg",
                    "https://cdn3.suncamp.eu/5/e/3/9/5e395b98cf63d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/3/9/5e395b9aca0d9.jpeg",
                    "https://cdn3.suncamp.eu/5/e/3/9/5e395b9cdf733.jpeg",
                    "https://cdn2.suncamp.eu/5/e/3/9/5e395af1902ee.jpeg",
                    "https://cdn3.suncamp.eu/5/e/3/9/5e395af3a6583.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/a/5e4a9b19e4899.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/3/9/5e395b94dc5eb.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/a/5e4a9b13abc77.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/a/5e4a9b1619378.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/a/5e4a9b180de58.jpeg",
                    "https://cdn3.suncamp.eu/5/e/3/9/5e395b98ec36c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/3/9/5e395b9ae9fc0.jpeg",
                    "https://cdn3.suncamp.eu/5/e/3/9/5e395b9d081c4.jpeg",
                    "https://cdn3.suncamp.eu/5/e/3/9/5e395af1ae8ba.jpeg",
                    "https://cdn3.suncamp.eu/5/e/3/9/5e395af3bfa84.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/a/5e4a9b1a10caf.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Mirepeisset",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": null,
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 109",
                "price": "€ 109",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "43.28694",
                "longitude": "2.88833",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/rhone-alpes/ardeche/vallon-pont-darc/camping-beau-rivage-104146/",
                        "price": "45,30"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/rhone-alpes/ardeche/vallon-pont-darc/camping-beau-rivage-104146/",
                        "price": "50,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/rhone-alpes/ardeche/vallon-pont-darc/camping-beau-rivage-104146/",
                        "price": "80,00"
                    }
                ]
            },
            {
                "campsiteId": 104244,
                "campsiteName": "Camping Le Gallo Romain ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/4/4/5e44112abbd9f.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d43bd2c18.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d43dbafb8.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15d43f904fa.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15d441676a9.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d4442cd49.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/4/5e44112cc3865.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d447c252c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15d449c1e73.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15d44b974af.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/4/4/5e44112ad91b5.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d43bed974.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15d43dd5df5.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15d43fac4c3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d44183bdb.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15d4444e4c3.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/4/5e44112ce1237.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d447dd135.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d449deee8.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15d44bb7b9a.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Barbières",
                    "region": "Rhône-Alpes"
                },
                "reviewScore": null,
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 134",
                "price": "€ 134",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "44.94458",
                "longitude": "5.15122",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "legalloromain",
                        "url": "https://www.legalloromain.com/",
                        "price": "35,50"
                    },
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    }
                ]
            },
            {
                "campsiteId": 103221,
                "campsiteName": "Camping Yelloh! Village L'Océan Breton *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/6/6/5e662c10c3f07.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/6/5e662c12be900.jpeg",
                    "https://cdn1.suncamp.eu/5/d/c/1/5dc1439680ed3.jpeg",
                    "https://cdn3.suncamp.eu/5/d/c/1/5dc1439833c32.jpeg",
                    "https://cdn3.suncamp.eu/5/d/c/1/5dc1439bd9414.jpeg",
                    "https://cdn1.suncamp.eu/5/d/c/1/5dc14368e31a8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/6/5e662c149a631.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/6/5e662c16668ea.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/6/5e662c18524b7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/6/5e662cebbd6e6.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/6/6/5e662c10e4f2a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/6/5e662c12ddaf8.jpeg",
                    "https://cdn2.suncamp.eu/5/d/c/1/5dc143969b53b.jpeg",
                    "https://cdn1.suncamp.eu/5/d/c/1/5dc143985057b.jpeg",
                    "https://cdn1.suncamp.eu/5/d/c/1/5dc1439c04fc6.jpeg",
                    "https://cdn3.suncamp.eu/5/d/c/1/5dc143690a79a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/6/5e662c14b6a3c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/6/5e662c1682da3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/6/5e662c18722f6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/6/5e662cebdc00c.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Plobannalec-Lesconil",
                    "region": "Bretagne"
                },
                "reviewScore": null,
                "isSunlodgeCategory": false,
                "isGlampingCategory": false,
                "discount": 0,
                "fromPrice": "€ 253",
                "price": "€ 253",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "47.81242",
                "longitude": "-4.22147",
                "locale": "nl_NL",
                "links": [
                    {
                        "name": "EuroCamping",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "30,00"
                    },
                    {
                        "name": "Suncamp",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "50,00"
                    },
                    {
                        "name": "booking.com",
                        "url": "https://www.eurocampings.nl/frankrijk/bretagne/finistere/plobannalec-lesconil/camping-yelloh-village-locean-breton-103221/",
                        "price": "80,00"
                    }
                ]
            }
        ]
    },
    {
        "id": "5",
        "name": "Mindervaliden",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2FMindervaliden.jpeg?alt=media",
        "key": "Mindervaliden",
        "resultList": [
            {
                "campsiteId": 105222,
                "campsiteName": "Camping Poljana ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7e0c53e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7fdda59.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a48a63.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c009e8a.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa833f512.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d398d18a6894.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/9/5d398d1a9d805.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ca69d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/9/5d3995a7bd439.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d3995a9d6217.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/d/9/e/5d9efa7e282e9.jpeg",
                    "https://cdn3.suncamp.eu/5/d/9/e/5d9efa8003bfc.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a65d71.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e8c02953a.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa8355c11.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d398d18c25bd.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398d1ac6ef9.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ea800.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d3995a7da26b.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d3995aa02a5d.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Mali Lošinj",
                    "region": "Primorje-Gorski Kotar"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 115",
                "price": "€ 115",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "44.55586",
                "longitude": "14.44228",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 100684,
                "campsiteName": "Camping Terme Catez *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108c03a00.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a108dd06a4.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108f977ba.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a10918f8d4.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093a9022.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a116a70bfa.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a116c712c4.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a116e6c2cc.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a117057b29.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a11724fe5e.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a108c21c35.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108dee84d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a108fb302f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1091af76c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093e71bd.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a116a9247f.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a116c9106c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a116e89af6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a117074d37.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a11726dfa2.jpeg"
                ],
                "geo": {
                    "country": "Slovenië",
                    "place": "Čatež ob Savi",
                    "region": null
                },
                "reviewScore": "7,8",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 175",
                "price": "€ 175",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.89111",
                "longitude": "15.62575",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 106750,
                "campsiteName": "Camping Vilanova Park Cat.1",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9937a9c35.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb059dac3.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a4c216.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993bb1f57.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb075e5fa.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb08e59ac.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb0a79f4d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb0c05c32.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cd929e.jpeg",
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d5171f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f9937bf61f.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb05b221f.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a655f2.jpeg",
                    "https://cdn1.suncamp.eu/5/c/8/f/5c8f993bc8fe9.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb077383a.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb090758e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0a90619.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0c1da61.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cf35cb.jpeg",
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d68279.jpeg"
                ],
                "geo": {
                    "country": "Spanje",
                    "place": "Vilanova i la Geltrú",
                    "region": "Catalonië"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 131",
                "price": "€ 131",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "41.23167",
                "longitude": "1.69106",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 109105,
                "campsiteName": "Camping La Vallée Verte *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129969f5.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633060554.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633208b93.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406333b6196.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e56d36e.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063373723a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339b0711.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e7778b8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633ce469f.jpeg",
                    "https://cdn2.suncamp.eu/5/7/1/7/5717213a43b3b.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129b18ea.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063307aa85.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633223e34.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c406333d1dd2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e58fd2a.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/0/5c40633753028.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339c9cc7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e7956e1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633d0bdb6.jpeg",
                    "https://cdn1.suncamp.eu/5/7/1/7/5717213a6172e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "La Roque-sur-Cèze",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 124",
                "price": "€ 124",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "44.18132",
                "longitude": "4.53537",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 108496,
                "campsiteName": "Camping Leï Suves ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3d302bee.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3d5351b9.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3d7302ca.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/b/5e4bf3d929903.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3db43a10.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/b/5e4bf3df531e3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3e17097e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e36e3ff.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/b/5e4bf3e56339a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e768c54.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3d3242ba.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3d55317c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3d74c5f8.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3d946e75.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3db9943d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3df75976.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e18f8f1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/b/5e4bf3e38b106.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e586171.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e787b2a.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Roquebrune-sur-Argens",
                    "region": "Provence-Alpes-Côte d'Azur"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 224",
                "price": "€ 224",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "43.47773",
                "longitude": "6.63855",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104032,
                "campsiteName": "Camping Le Sérignan Plage *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8b8955.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae497911f8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49915a09.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a7c761.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae49c02c89.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54d9e4e1.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae54f54675.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae550f2795.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae55299fe1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5543d115.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8cf118.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae497aaa74.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae4992c179.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a96020.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49c1a630.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54dba52a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54f72fa6.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae55119b0b.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae552b599a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5545925e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Sérignan",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 273",
                "price": "€ 273",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "43.26389",
                "longitude": "3.32119",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105205,
                "campsiteName": "Krk Premium Camping Resort *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4dc2b4a0.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4de20ab1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4e01e933.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4e1df9f7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e3e378a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e458c2da5.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e45a760f3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45c2a8db.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e45e44e6c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e45fe7a48.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4dc4896c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4de3ddb2.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e03b10e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4e2073c8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e40b4e8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e458e33f9.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45a90ec7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45c42baa.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e45e5f2fd.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4600e235.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Krk",
                    "region": "Primorje-Gorski Kotar"
                },
                "reviewScore": "8,3",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 123",
                "price": "€ 123",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.02433",
                "longitude": "14.59161",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105177,
                "campsiteName": "Camping Bijela Uvala ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7be14e7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7db4a05.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7f94e23.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add81c82e4.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add83bdc51.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce4d5ec1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce679357.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adce8147d2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce9bae6c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adceb6fc7e.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7c0a0e7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7dd12f1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7fb42a5.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2add81e8aef.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add83dbf83.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adce4f33e8.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce69362c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce82f846.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce9d6775.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adceb88d25.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Poreč",
                    "region": "Istrië"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 92",
                "price": "€ 92",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.19153",
                "longitude": "13.59689",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105172,
                "campsiteName": "Camping Park Umag ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/7/3/5e737402e2a12.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/3/5e7374050133b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737406f2e15.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737408f411d.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03885f5.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9056113.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd96226c0.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd97f137d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd99d549d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9bbe04d.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/7/3/5e7374030eb42.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737405219b5.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/3/5e7374071e3c6.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e73740927241.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03a8fb2.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9071f7b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd963ff10.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd981a0c1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9a02df8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd9bdb8a6.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Umag",
                    "region": "Istrië"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 88",
                "price": "€ 88",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.36717",
                "longitude": "13.54731",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 109413,
                "campsiteName": "Camping Bella Austria ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a02cf993.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a046bab2.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a05e5f2c.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a0759b53.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce2987b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02cfb86c1.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b0269d55a8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bc0aeb.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a1e1750d.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a1f7aab6.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a02edb06.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a048a007.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a0610d17.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a0777e28.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce437f2.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b02cfd31fe.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/b/5e2b0269ee86a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bde671.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a1e3020f.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a1f986bd.jpeg"
                ],
                "geo": {
                    "country": "Oostenrijk",
                    "place": "Sankt Peter am Kammersberg",
                    "region": "Stiermarken"
                },
                "reviewScore": "7,9",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 94",
                "price": "€ 94",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    },
                    {
                        "Name": "Mindervaliden",
                        "ID": 44
                    }
                ],
                "latitude": "47.18028",
                "longitude": "14.21528",
                "locale": "nl_NL"
            }
        ]
    },
    {
        "id": "6",
        "name": "Kindvriendelijk",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2FKindvriendelijk.jpeg?alt=media",
        "key": "Kindvriendelijk",
        "resultList": [
            {
                "campsiteId": 105222,
                "campsiteName": "Camping Poljana ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7e0c53e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7fdda59.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a48a63.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c009e8a.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa833f512.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d398d18a6894.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/9/5d398d1a9d805.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ca69d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/9/5d3995a7bd439.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d3995a9d6217.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/d/9/e/5d9efa7e282e9.jpeg",
                    "https://cdn3.suncamp.eu/5/d/9/e/5d9efa8003bfc.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a65d71.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e8c02953a.jpeg",
                    "https://cdn2.suncamp.eu/5/d/9/e/5d9efa8355c11.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d398d18c25bd.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d398d1ac6ef9.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ea800.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/9/5d3995a7da26b.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/9/5d3995aa02a5d.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Mali Lošinj",
                    "region": "Primorje-Gorski Kotar"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 115",
                "price": "€ 115",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "44.55586",
                "longitude": "14.44228",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 100684,
                "campsiteName": "Camping Terme Catez *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108c03a00.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a108dd06a4.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108f977ba.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a10918f8d4.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093a9022.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a116a70bfa.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a116c712c4.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a116e6c2cc.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a117057b29.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a11724fe5e.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a108c21c35.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/a/5e6a108dee84d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a108fb302f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1091af76c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093e71bd.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a116a9247f.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a116c9106c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a116e89af6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/a/5e6a117074d37.jpeg",
                    "https://cdn3.suncamp.eu/5/e/6/a/5e6a11726dfa2.jpeg"
                ],
                "geo": {
                    "country": "Slovenië",
                    "place": "Čatež ob Savi",
                    "region": null
                },
                "reviewScore": "7,8",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 175",
                "price": "€ 175",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.89111",
                "longitude": "15.62575",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 106750,
                "campsiteName": "Camping Vilanova Park Cat.1",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9937a9c35.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb059dac3.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a4c216.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993bb1f57.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb075e5fa.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb08e59ac.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb0a79f4d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb0c05c32.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cd929e.jpeg",
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d5171f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f9937bf61f.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb05b221f.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a655f2.jpeg",
                    "https://cdn1.suncamp.eu/5/c/8/f/5c8f993bc8fe9.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb077383a.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb090758e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0a90619.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0c1da61.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cf35cb.jpeg",
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d68279.jpeg"
                ],
                "geo": {
                    "country": "Spanje",
                    "place": "Vilanova i la Geltrú",
                    "region": "Catalonië"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 131",
                "price": "€ 131",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "41.23167",
                "longitude": "1.69106",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 109105,
                "campsiteName": "Camping La Vallée Verte *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129969f5.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633060554.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633208b93.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406333b6196.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e56d36e.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063373723a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339b0711.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e7778b8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633ce469f.jpeg",
                    "https://cdn2.suncamp.eu/5/7/1/7/5717213a43b3b.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/7/1/7/57172129b18ea.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c4063307aa85.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c40633223e34.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/0/5c406333d1dd2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/5/5e4514e58fd2a.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/0/5c40633753028.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c406339c9cc7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/5/5e4514e7956e1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/0/5c40633d0bdb6.jpeg",
                    "https://cdn1.suncamp.eu/5/7/1/7/5717213a6172e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "La Roque-sur-Cèze",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 124",
                "price": "€ 124",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    }
                ],
                "latitude": "44.18132",
                "longitude": "4.53537",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 108496,
                "campsiteName": "Camping Leï Suves ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3d302bee.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3d5351b9.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3d7302ca.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/b/5e4bf3d929903.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3db43a10.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/b/5e4bf3df531e3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3e17097e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e36e3ff.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/b/5e4bf3e56339a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e768c54.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3d3242ba.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3d55317c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3d74c5f8.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3d946e75.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3db9943d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/4/b/5e4bf3df75976.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e18f8f1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/4/b/5e4bf3e38b106.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e586171.jpeg",
                    "https://cdn3.suncamp.eu/5/e/4/b/5e4bf3e787b2a.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Roquebrune-sur-Argens",
                    "region": "Provence-Alpes-Côte d'Azur"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 224",
                "price": "€ 224",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "43.47773",
                "longitude": "6.63855",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104032,
                "campsiteName": "Camping Le Sérignan Plage *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8b8955.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae497911f8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49915a09.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a7c761.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae49c02c89.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54d9e4e1.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae54f54675.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae550f2795.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae55299fe1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5543d115.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8cf118.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae497aaa74.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae4992c179.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a96020.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49c1a630.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54dba52a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54f72fa6.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae55119b0b.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae552b599a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5545925e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Sérignan",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 273",
                "price": "€ 273",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "43.26389",
                "longitude": "3.32119",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105205,
                "campsiteName": "Krk Premium Camping Resort *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4dc2b4a0.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4de20ab1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4e01e933.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4e1df9f7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e3e378a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e458c2da5.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e45a760f3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45c2a8db.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e45e44e6c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e45fe7a48.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e4dc4896c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4de3ddb2.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e03b10e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4e2073c8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e4e40b4e8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15e458e33f9.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45a90ec7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15e45c42baa.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e45e5f2fd.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15e4600e235.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Krk",
                    "region": "Primorje-Gorski Kotar"
                },
                "reviewScore": "8,3",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 123",
                "price": "€ 123",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.02433",
                "longitude": "14.59161",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105177,
                "campsiteName": "Camping Bijela Uvala ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7be14e7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7db4a05.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7f94e23.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add81c82e4.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add83bdc51.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce4d5ec1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce679357.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adce8147d2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce9bae6c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adceb6fc7e.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7c0a0e7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7dd12f1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7fb42a5.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2add81e8aef.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add83dbf83.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adce4f33e8.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce69362c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce82f846.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce9d6775.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adceb88d25.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Poreč",
                    "region": "Istrië"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 92",
                "price": "€ 92",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.19153",
                "longitude": "13.59689",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105172,
                "campsiteName": "Camping Park Umag ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/7/3/5e737402e2a12.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/3/5e7374050133b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737406f2e15.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737408f411d.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03885f5.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9056113.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd96226c0.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd97f137d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd99d549d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9bbe04d.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/7/3/5e7374030eb42.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737405219b5.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/3/5e7374071e3c6.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e73740927241.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03a8fb2.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9071f7b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd963ff10.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd981a0c1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9a02df8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd9bdb8a6.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Umag",
                    "region": "Istrië"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 88",
                "price": "€ 88",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.36717",
                "longitude": "13.54731",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 109413,
                "campsiteName": "Camping Bella Austria ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a02cf993.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a046bab2.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a05e5f2c.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a0759b53.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce2987b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02cfb86c1.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b0269d55a8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bc0aeb.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a1e1750d.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a1f7aab6.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a02edb06.jpeg",
                    "https://cdn1.suncamp.eu/5/8/3/c/583c2a048a007.jpeg",
                    "https://cdn3.suncamp.eu/5/8/3/c/583c2a0610d17.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a0777e28.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce437f2.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b02cfd31fe.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/b/5e2b0269ee86a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bde671.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a1e3020f.jpeg",
                    "https://cdn2.suncamp.eu/5/8/3/c/583c2a1f986bd.jpeg"
                ],
                "geo": {
                    "country": "Oostenrijk",
                    "place": "Sankt Peter am Kammersberg",
                    "region": "Stiermarken"
                },
                "reviewScore": "7,9",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 94",
                "price": "€ 94",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Honden toegestaan",
                        "ID": 39
                    },
                    {
                        "Name": "Mindervaliden",
                        "ID": 44
                    }
                ],
                "latitude": "47.18028",
                "longitude": "14.21528",
                "locale": "nl_NL"
            }
        ]
    },
    {
        "id": "7",
        "name": "Grootschalig",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2FGrootschalig.jpeg?alt=media",
        "key": "Grootschalig",
        "resultList": [
            {
                "campsiteId": 106750,
                "campsiteName": "Camping Vilanova Park Cat.1",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9937a9c35.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb059dac3.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a4c216.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993bb1f57.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb075e5fa.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb08e59ac.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/9/5d89cb0a79f4d.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb0c05c32.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cd929e.jpeg",
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d5171f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f9937bf61f.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb05b221f.jpeg",
                    "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a655f2.jpeg",
                    "https://cdn1.suncamp.eu/5/c/8/f/5c8f993bc8fe9.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb077383a.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89cb090758e.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0a90619.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0c1da61.jpeg",
                    "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cf35cb.jpeg",
                    "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d68279.jpeg"
                ],
                "geo": {
                    "country": "Spanje",
                    "place": "Vilanova i la Geltrú",
                    "region": "Catalonië"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 131",
                "price": "€ 131",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "41.23167",
                "longitude": "1.69106",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104032,
                "campsiteName": "Camping Le Sérignan Plage *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8b8955.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae497911f8.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49915a09.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a7c761.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae49c02c89.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54d9e4e1.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae54f54675.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae550f2795.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae55299fe1.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5543d115.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/c/a/e/5caefed8cf118.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae497aaa74.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae4992c179.jpeg",
                    "https://cdn1.suncamp.eu/5/c/4/a/5c4ae49a96020.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae49c1a630.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54dba52a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae54f72fa6.jpeg",
                    "https://cdn3.suncamp.eu/5/c/4/a/5c4ae55119b0b.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae552b599a.jpeg",
                    "https://cdn2.suncamp.eu/5/c/4/a/5c4ae5545925e.jpeg"
                ],
                "geo": {
                    "country": "Frankrijk",
                    "place": "Sérignan",
                    "region": "Languedoc-Roussillon"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 273",
                "price": "€ 273",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "43.26389",
                "longitude": "3.32119",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105177,
                "campsiteName": "Camping Bijela Uvala ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7be14e7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7db4a05.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7f94e23.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add81c82e4.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add83bdc51.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce4d5ec1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce679357.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adce8147d2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce9bae6c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adceb6fc7e.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7c0a0e7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2add7dd12f1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add7fb42a5.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2add81e8aef.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2add83dbf83.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/a/5e2adce4f33e8.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adce69362c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce82f846.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/a/5e2adce9d6775.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/a/5e2adceb88d25.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Poreč",
                    "region": "Istrië"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 92",
                "price": "€ 92",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.19153",
                "longitude": "13.59689",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105172,
                "campsiteName": "Camping Park Umag ****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/7/3/5e737402e2a12.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/3/5e7374050133b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737406f2e15.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737408f411d.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03885f5.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9056113.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd96226c0.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd97f137d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd99d549d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9bbe04d.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/7/3/5e7374030eb42.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e737405219b5.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/3/5e7374071e3c6.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/3/5e73740927241.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03a8fb2.jpeg",
                    "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9071f7b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd963ff10.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd981a0c1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9a02df8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd9bdb8a6.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Umag",
                    "region": "Istrië"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 88",
                "price": "€ 88",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.36717",
                "longitude": "13.54731",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105237,
                "campsiteName": "Camping Zaton Holiday Resort ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/b/e/1/5be16142e5703.jpeg",
                    "https://cdn2.suncamp.eu/5/b/e/1/5be16145163a1.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614704fb1.jpeg",
                    "https://cdn2.suncamp.eu/5/b/e/1/5be16148f2796.jpeg",
                    "https://cdn3.suncamp.eu/5/b/e/1/5be1614b0715e.jpeg",
                    "https://cdn3.suncamp.eu/5/b/e/1/5be1614de253f.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614fce0f3.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be16151b7cee.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1615397756.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be161557cf50.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614314cb3.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be16145386c7.jpeg",
                    "https://cdn2.suncamp.eu/5/b/e/1/5be161472a4b0.jpeg",
                    "https://cdn3.suncamp.eu/5/b/e/1/5be16149213e6.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614b29fed.jpeg",
                    "https://cdn2.suncamp.eu/5/b/e/1/5be1614e10237.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be1614feff83.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be16151d8a92.jpeg",
                    "https://cdn3.suncamp.eu/5/b/e/1/5be16153b9b39.jpeg",
                    "https://cdn1.suncamp.eu/5/b/e/1/5be161559dc3f.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Zaton",
                    "region": "Zadar"
                },
                "reviewScore": "8,1",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 191",
                "price": "€ 191",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "44.22806",
                "longitude": "15.16917",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 110977,
                "campsiteName": "Camping Bi-Village ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/7/5e17011a8477c.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/0/5d80b4900558c.jpeg",
                    "https://cdn3.suncamp.eu/5/8/7/4/5874c033afae7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011c70324.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011e48e2a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6cceaff.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6e73930.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc701d841.jpeg",
                    "https://cdn1.suncamp.eu/5/6/f/2/56f250626e863.jpeg",
                    "https://cdn2.suncamp.eu/5/8/7/4/5874c04d6ac7f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011aa1426.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/0/5d80b4901f40b.jpeg",
                    "https://cdn1.suncamp.eu/5/8/7/4/5874c033d2726.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/7/5e17011c8cbb9.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/7/5e17011e67aa7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6ce5e11.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6e906a2.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc7037228.jpeg",
                    "https://cdn1.suncamp.eu/5/6/f/2/56f2506293114.jpeg",
                    "https://cdn1.suncamp.eu/5/8/7/4/5874c04d927e3.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Fažana",
                    "region": "Istrië"
                },
                "reviewScore": "7,9",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 123",
                "price": "€ 123",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "44.91739",
                "longitude": "13.81104",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105180,
                "campsiteName": "Camping Valkanela ***",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d3d21a9e.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d3f20908.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4145e3c.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d431ad6a.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d44dbe44.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4834f99.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4a0a473.jpeg",
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d4baa5d7.jpeg",
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d4d60e1f.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4f225c9.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d3d3d5c5.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d3f3fda4.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4168395.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4333f2c.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4501282.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4855d00.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4a23fe5.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4bc6a6b.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4d7943c.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4f3cd0f.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Funtana",
                    "region": "Istrië"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 80",
                "price": "€ 80",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.165",
                "longitude": "13.60772",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 100685,
                "campsiteName": "Lanterna Premium Camping Resort *****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb028272.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb204c6b.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3cae92.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb58fb40.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb764f87.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd236fc35.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd25322ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26c2182.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd28c25ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a6fd2c.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb04571e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb221653.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3e9717.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb5acfee.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb784f01.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd2387f7d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd254cc55.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26dcd6c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1edd28dcfc1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a89dad.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Poreč",
                    "region": "Istrië"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 94",
                "price": "€ 94",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.29714",
                "longitude": "13.59439",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104864,
                "campsiteName": "Camping Pra'delle Torri ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a48967f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a694c0f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a873536.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464aa26b90.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464abecce5.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464af1e829.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464b0e092b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464b2a0a37.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464b47d021.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b64121f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464a4a6cef.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464a6b0ab6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464a8902a7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464aa43167.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464ac156af.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464af3e1ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b109b86.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b2bdb7f.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b498847.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464b65dc41.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Caorle",
                    "region": "Veneto"
                },
                "reviewScore": "8,5",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 232",
                "price": "€ 232",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "45.57311",
                "longitude": "12.81253",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104874,
                "campsiteName": "Camping Village Marina di Venezia *****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f59ce976c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f59e8adfe.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/6/5e16f5a0379c0.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/6/5e16f5a1ce6a8.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/6/5e16f5a3702c3.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/6/5e16f6377de8a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f63947104.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f63b1eb58.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f63d0d655.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/6/5e16f63ebc7a6.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/6/5e16f59d0fb94.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f59ea2ee6.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/6/5e16f5a053ed6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/6/5e16f5a1ea5a8.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/6/5e16f5a38c9d7.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f637991e1.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/6/5e16f639662c1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f63b3b428.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/6/5e16f63d29e9c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/6/5e16f63ed6b28.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Cavallino-Treporti",
                    "region": "Veneto"
                },
                "reviewScore": "8,5",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 273",
                "price": "€ 273",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.43742",
                "longitude": "12.43776",
                "locale": "nl_NL"
            }
        ]
    },
    // {
    //     "id": "8",
    //     "name": "Kleinschalig",
    //     "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2Fcampingmetzwemparadijs.jpg?alt=media",
    //     "key": "Kleinschalig",
    //     "resultList": [
    //         {
    //             "campsiteId": 109105,
    //             "campsiteName": "Camping La Vallée Verte *****",
    //             "campsiteImages": [
    //                 "https://cdn1.suncamp.eu/5/7/1/7/57172129969f5.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/0/5c40633060554.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/0/5c40633208b93.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c406333b6196.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/4/5/5e4514e56d36e.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c4063373723a.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c406339b0711.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/4/5/5e4514e7778b8.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c40633ce469f.jpeg",
    //                 "https://cdn2.suncamp.eu/5/7/1/7/5717213a43b3b.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn1.suncamp.eu/5/7/1/7/57172129b18ea.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c4063307aa85.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/0/5c40633223e34.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/0/5c406333d1dd2.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/4/5/5e4514e58fd2a.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/4/0/5c40633753028.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c406339c9cc7.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/4/5/5e4514e7956e1.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c40633d0bdb6.jpeg",
    //                 "https://cdn1.suncamp.eu/5/7/1/7/5717213a6172e.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "La Roque-sur-Cèze",
    //                 "region": "Languedoc-Roussillon"
    //             },
    //             "reviewScore": "8,0",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 124",
    //             "price": "€ 124",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Avondentertainment",
    //                     "ID": 47
    //                 },
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "44.18132",
    //             "longitude": "4.53537",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 100345,
    //             "campsiteName": "Camping Huttopia Étang de Fouché ****",
    //             "campsiteImages": [
    //                 "https://cdn1.suncamp.eu/5/6/8/e/568e3420dda08.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/8/e/568e34227da23.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/2/d/582d84237b599.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/2/d/582d842512a10.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/2/d/582d8426a4e9c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/8/e/568e3427938fd.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/8/e/568e34295e7da.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/8/e/568e342b34935.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/8/e/568e342cd2469.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/8/e/568e342e793cd.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn2.suncamp.eu/5/6/8/e/568e34210cff1.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/8/e/568e34229e0f7.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/2/d/582d84239d583.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/2/d/582d842533de3.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/2/d/582d8426c67da.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/8/e/568e3427b603d.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/8/e/568e342981110.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/8/e/568e342b55ce4.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/8/e/568e342cf16a6.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/8/e/568e342e99743.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "Arnay-le-Duc",
    //                 "region": "Bourgondië"
    //             },
    //             "reviewScore": "7,6",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": false,
    //             "discount": 0,
    //             "fromPrice": "€ 82",
    //             "price": "€ 82",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "47.13389",
    //             "longitude": "4.49844",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 100474,
    //             "campsiteName": "Camping l'Ardéchois *****",
    //             "campsiteImages": [
    //                 "https://cdn1.suncamp.eu/5/e/2/e/5e2eb8af4a007.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/e/5e2eb8b113083.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/e/5e2eb8b27cc6c.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/e/5e2eb8b426f42.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/e/5e2eb8b5e5d86.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/e/5e2eb8b91467a.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/e/5e2eb8bae70b8.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/e/5e2eb8bc9d817.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/e/5e2eb8be3b4ea.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/e/5e2eb8bfcf644.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn3.suncamp.eu/5/e/2/e/5e2eb8af64afa.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/e/5e2eb8b12e596.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/e/5e2eb8b298aaa.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/e/5e2eb8b44494c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/e/5e2eb8b60d2ea.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/e/5e2eb8b931f0f.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/e/5e2eb8bb0d278.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/e/5e2eb8bcb79f7.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/e/5e2eb8be5209a.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/e/5e2eb8bfee538.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "Saint-Sauveur-de-Montagut",
    //                 "region": "Rhône-Alpes"
    //             },
    //             "reviewScore": "8,6",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": false,
    //             "discount": 0,
    //             "fromPrice": "€ 99",
    //             "price": "€ 99",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "44.82892",
    //             "longitude": "4.52301",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 117804,
    //             "campsiteName": "Camping Praha Klánovice",
    //             "campsiteImages": [
    //                 "https://cdn3.suncamp.eu/5/8/7/7/58779ea1bcdda.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/0/0/5d00d2e09d9ac.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/7/7/58779ea4d4159.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/0/0/5d00d2e298b3d.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/4/9/5c49941741c9f.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/4/9/5c499418f0a48.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/7/7/58779ea7ad78f.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/7/7/58779ea855f31.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/7/7/58779ea977e96.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/7/7/58779eaab399f.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn3.suncamp.eu/5/8/7/7/58779ea1e41cf.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/0/0/5d00d2e0ba139.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/7/7/58779ea5024f7.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/0/0/5d00d2e2b93d7.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/9/5c4994175d21c.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/9/5c49941919e00.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/7/7/58779ea7cdbde.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/7/7/58779ea874d5b.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/7/7/58779ea98c055.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/7/7/58779eaace64c.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Tsjechië",
    //                 "place": "Klánovice",
    //                 "region": "Praag"
    //             },
    //             "reviewScore": "7,6",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": false,
    //             "discount": 0,
    //             "fromPrice": "€ 136",
    //             "price": "€ 136",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Golf/Midgetgolf",
    //                     "ID": 50
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "50.09858",
    //             "longitude": "14.685",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 111285,
    //             "campsiteName": "Camping Le Rotja ***",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/c/6/b/5c6bd1abcac17.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/6/a/566a6c2c4018c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c2e59823.jpeg",
    //                 "https://cdn1.suncamp.eu/5/6/6/a/566a6c2fac3e6.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/6/a/566a6c3111d39.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c356e3b0.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/6/a/566a6c395ac37.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c3aeb829.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c3c112bf.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/6/a/566a6c3d1fb79.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn1.suncamp.eu/5/c/6/b/5c6bd1abe3173.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c2c5cca7.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c2e7ae1a.jpeg",
    //                 "https://cdn1.suncamp.eu/5/6/6/a/566a6c2fc9adc.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c312940a.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c35cb507.jpeg",
    //                 "https://cdn1.suncamp.eu/5/6/6/a/566a6c398b8c8.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/6/a/566a6c3b13023.jpeg",
    //                 "https://cdn2.suncamp.eu/5/6/6/a/566a6c3c29b54.jpeg",
    //                 "https://cdn3.suncamp.eu/5/6/6/a/566a6c3d37385.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "Fuilla",
    //                 "region": "Languedoc-Roussillon"
    //             },
    //             "reviewScore": "8,7",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 117",
    //             "price": "€ 117",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "42.56239",
    //             "longitude": "2.35944",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 103335,
    //             "campsiteName": "Camping Huttopia Baie du Mont St-Michel ****",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/d/5/5/5d5573d37c5aa.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/5/5/5d5573d582160.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/5/5/5d5573d76ec08.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/5/5/5d5573d967b44.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/5/5/5d5573db36bbf.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/a/a/5aaa2df154937.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/a/a/5aaa2df4266a2.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/a/a/5aaa2df6019a1.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/5/5/5d5573de50a7e.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/5/5/5d5573e03befc.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn3.suncamp.eu/5/d/5/5/5d5573d3995e6.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/5/5/5d5573d5a25b4.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/5/5/5d5573d789c0f.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/5/5/5d5573d982f72.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/5/5/5d5573db5161b.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/a/a/5aaa2df174e7c.jpeg",
    //                 "https://cdn2.suncamp.eu/5/a/a/a/5aaa2df44235d.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/a/a/5aaa2df61f59c.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/5/5/5d5573de6ddbe.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/5/5/5d5573e055b80.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "Dol-de-Bretagne",
    //                 "region": "Bretagne"
    //             },
    //             "reviewScore": "9,8",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 84",
    //             "price": "€ 84",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Golf/Midgetgolf",
    //                     "ID": 50
    //                 }
    //             ],
    //             "latitude": "48.54939",
    //             "longitude": "-1.68381",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 100263,
    //             "campsiteName": "Camping Huttopia Rambouillet ***",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/a/9/5/5a952c966e62d.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/9/5/5a952c98905b7.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/9/5/5a952c9a53fa3.jpeg",
    //                 "https://cdn2.suncamp.eu/5/a/9/5/5a952c9c4ef63.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/9/5/5a952c9e2ee03.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/9/5c4991c011de9.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/9/5c4991c176293.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/9/5c4991c28c0c9.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/9/5c4991c3ee6bb.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/4/9/5c4991c56beea.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn3.suncamp.eu/5/a/9/5/5a952c968b1fe.jpeg",
    //                 "https://cdn2.suncamp.eu/5/a/9/5/5a952c98ae776.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/9/5/5a952c9a6f3c4.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/9/5/5a952c9c6be6d.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/9/5/5a952c9e4b7cc.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/9/5c4991c029da4.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/4/9/5c4991c18f17c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/4/9/5c4991c29ef93.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/9/5c4991c41378d.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/4/9/5c4991c58654e.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "Rambouillet",
    //                 "region": "Île-de-France"
    //             },
    //             "reviewScore": "7,2",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 470",
    //             "price": "€ 470",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "48.62631",
    //             "longitude": "1.84402",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 110209,
    //             "campsiteName": "Camping Huttopia Beaulieu sur Dordogne ****",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/8/5/8/5858ef42dd795.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/5/8/5858ef44e048e.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/5/8/5858ef4623659.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/5/8/5858ef4770093.jpeg",
    //                 "https://cdn1.suncamp.eu/5/9/7/6/5976f2d23e2ac.jpeg",
    //                 "https://cdn3.suncamp.eu/5/9/7/6/5976f2d3ea59f.jpeg",
    //                 "https://cdn1.suncamp.eu/5/9/7/6/5976f2d5b0a5d.jpeg",
    //                 "https://cdn2.suncamp.eu/5/9/7/6/5976f2d76aa6a.jpeg",
    //                 "https://cdn3.suncamp.eu/5/9/7/6/5976f2d928afc.jpeg",
    //                 "https://cdn3.suncamp.eu/5/9/7/6/5976f2dba2068.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn1.suncamp.eu/5/8/5/8/5858ef43062c5.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/5/8/5858ef4509421.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/5/8/5858ef463f6f6.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/5/8/5858ef478bca5.jpeg",
    //                 "https://cdn1.suncamp.eu/5/9/7/6/5976f2d25bda3.jpeg",
    //                 "https://cdn3.suncamp.eu/5/9/7/6/5976f2d413487.jpeg",
    //                 "https://cdn1.suncamp.eu/5/9/7/6/5976f2d5ced13.jpeg",
    //                 "https://cdn2.suncamp.eu/5/9/7/6/5976f2d7871c8.jpeg",
    //                 "https://cdn2.suncamp.eu/5/9/7/6/5976f2d944923.jpeg",
    //                 "https://cdn1.suncamp.eu/5/9/7/6/5976f2dbbe9c6.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "Beaulieu-sur-Dordogne",
    //                 "region": "Limousin"
    //             },
    //             "reviewScore": "8,0",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 78",
    //             "price": "€ 78",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "44.97956",
    //             "longitude": "1.84031",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 103438,
    //             "campsiteName": "Camping Des Familles ****",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/6/3/3/56338c238fe3a.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb466edb85.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb46868116.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/d/c/58dcb469b7a85.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/d/c/58dcb46b291dc.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb46c9ecee.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb46eb4035.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb47020866.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb4719dc9d.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/d/c/58dcb4731aba2.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn1.suncamp.eu/5/6/3/3/56338c23b3894.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb46715aa4.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/d/c/58dcb46882e57.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/d/c/58dcb469d2b04.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb46b44c6f.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb46cba3a8.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/d/c/58dcb46ecf57f.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/d/c/58dcb4703f5ea.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/d/c/58dcb471bdb98.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/d/c/58dcb473380f5.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "Grayan-et-l'Hôpital",
    //                 "region": "Aquitanië"
    //             },
    //             "reviewScore": "8,2",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": false,
    //             "discount": 0,
    //             "fromPrice": "€ 97",
    //             "price": "€ 97",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Avondentertainment",
    //                     "ID": 47
    //                 },
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 }
    //             ],
    //             "latitude": "45.43676",
    //             "longitude": "-1.09176",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 117883,
    //             "campsiteName": "Camping Onda Blu ****",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/a/1/5/5a15388c5db21.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/1/5/5a15388e10f1a.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a15388fa62b5.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/1/5/5a15389164ac6.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/1/5/5a1538932834f.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a153895c21ef.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a153897641fd.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/1/5/5a153899002e6.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a15389aa5ec8.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a15389c30f53.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn3.suncamp.eu/5/a/1/5/5a15388c7772a.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a15388e302e7.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a15388fc1f91.jpeg",
    //                 "https://cdn2.suncamp.eu/5/a/1/5/5a15389180dfc.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/1/5/5a15389343522.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/1/5/5a153895ddcec.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a1538977e0b3.jpeg",
    //                 "https://cdn2.suncamp.eu/5/a/1/5/5a1538991c628.jpeg",
    //                 "https://cdn3.suncamp.eu/5/a/1/5/5a15389abf370.jpeg",
    //                 "https://cdn1.suncamp.eu/5/a/1/5/5a15389c4a900.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Italië",
    //                 "place": "Residence Onda Blu",
    //                 "region": "Lombardije"
    //             },
    //             "reviewScore": "8,0",
    //             "isSunlodgeCategory": false,
    //             "isGlampingCategory": false,
    //             "discount": 0,
    //             "fromPrice": "€ 142",
    //             "price": "€ 142",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "45.57108",
    //             "longitude": "10.54503",
    //             "locale": "nl_NL"
    //         }
    //     ]
    // },
    // {
    //     "id": "9",
    //     "name": "Kampeerplaats",
    //     "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2Fcampingmetzwemparadijs.jpg?alt=media",
    //     "key": "Kampeerplaats",
    //     "resultList": [
    //         {
    //             "campsiteId": 105222,
    //             "campsiteName": "Camping Poljana ****",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7e0c53e.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/9/e/5d9efa7fdda59.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a48a63.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c009e8a.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/9/e/5d9efa833f512.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/3/9/5d398d18a6894.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/3/9/5d398d1a9d805.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ca69d.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/3/9/5d3995a7bd439.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/3/9/5d3995a9d6217.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn1.suncamp.eu/5/d/9/e/5d9efa7e282e9.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/9/e/5d9efa8003bfc.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/3/9/5d398e0a65d71.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/5/5e15e8c02953a.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/9/e/5d9efa8355c11.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/3/9/5d398d18c25bd.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/3/9/5d398d1ac6ef9.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/5/5e15e8c1ea800.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/3/9/5d3995a7da26b.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/3/9/5d3995aa02a5d.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Kroatië",
    //                 "place": "Mali Lošinj",
    //                 "region": "Primorje-Gorski Kotar"
    //             },
    //             "reviewScore": "8,2",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 115",
    //             "price": "€ 115",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Avondentertainment",
    //                     "ID": 47
    //                 },
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 }
    //             ],
    //             "latitude": "44.55586",
    //             "longitude": "14.44228",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 100684,
    //             "campsiteName": "Camping Terme Catez *****",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/e/6/a/5e6a108c03a00.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/6/a/5e6a108dd06a4.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/6/a/5e6a108f977ba.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/6/a/5e6a10918f8d4.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093a9022.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/6/a/5e6a116a70bfa.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/6/a/5e6a116c712c4.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/6/a/5e6a116e6c2cc.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/6/a/5e6a117057b29.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/6/a/5e6a11724fe5e.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn3.suncamp.eu/5/e/6/a/5e6a108c21c35.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/6/a/5e6a108dee84d.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/6/a/5e6a108fb302f.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/6/a/5e6a1091af76c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/6/a/5e6a1093e71bd.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/6/a/5e6a116a9247f.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/6/a/5e6a116c9106c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/6/a/5e6a116e89af6.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/6/a/5e6a117074d37.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/6/a/5e6a11726dfa2.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Slovenië",
    //                 "place": "Čatež ob Savi",
    //                 "region": null
    //             },
    //             "reviewScore": "7,8",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 204",
    //             "price": "€ 204",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Fitness",
    //                     "ID": 46
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 }
    //             ],
    //             "latitude": "45.89111",
    //             "longitude": "15.62575",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 106750,
    //             "campsiteName": "Camping Vilanova Park Cat.1",
    //             "campsiteImages": [
    //                 "https://cdn3.suncamp.eu/5/c/8/f/5c8f9937a9c35.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/8/9/5d89cb059dac3.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a4c216.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/8/f/5c8f993bb1f57.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/8/9/5d89cb075e5fa.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/8/9/5d89cb08e59ac.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/8/9/5d89cb0a79f4d.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/8/9/5d89cb0c05c32.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cd929e.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d5171f.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn2.suncamp.eu/5/c/8/f/5c8f9937bf61f.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/8/9/5d89cb05b221f.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/8/f/5c8f993a655f2.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/8/f/5c8f993bc8fe9.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/8/9/5d89cb077383a.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/8/9/5d89cb090758e.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0a90619.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/8/9/5d89cb0c1da61.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/8/9/5d89ca6cf35cb.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/8/f/5c8f9a1d68279.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Spanje",
    //                 "place": "Vilanova i la Geltrú",
    //                 "region": "Catalonië"
    //             },
    //             "reviewScore": "8,1",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 131",
    //             "price": "€ 131",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Avondentertainment",
    //                     "ID": 47
    //                 },
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Fitness",
    //                     "ID": 46
    //                 }
    //             ],
    //             "latitude": "41.23167",
    //             "longitude": "1.69106",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 109105,
    //             "campsiteName": "Camping La Vallée Verte *****",
    //             "campsiteImages": [
    //                 "https://cdn1.suncamp.eu/5/7/1/7/57172129969f5.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/0/5c40633060554.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/0/5c40633208b93.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c406333b6196.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/4/5/5e4514e56d36e.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c4063373723a.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c406339b0711.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/4/5/5e4514e7778b8.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c40633ce469f.jpeg",
    //                 "https://cdn2.suncamp.eu/5/7/1/7/5717213a43b3b.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn1.suncamp.eu/5/7/1/7/57172129b18ea.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c4063307aa85.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/0/5c40633223e34.jpeg",
    //                 "https://cdn1.suncamp.eu/5/c/4/0/5c406333d1dd2.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/4/5/5e4514e58fd2a.jpeg",
    //                 "https://cdn3.suncamp.eu/5/c/4/0/5c40633753028.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c406339c9cc7.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/4/5/5e4514e7956e1.jpeg",
    //                 "https://cdn2.suncamp.eu/5/c/4/0/5c40633d0bdb6.jpeg",
    //                 "https://cdn1.suncamp.eu/5/7/1/7/5717213a6172e.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Frankrijk",
    //                 "place": "La Roque-sur-Cèze",
    //                 "region": "Languedoc-Roussillon"
    //             },
    //             "reviewScore": "8,0",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 124",
    //             "price": "€ 124",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Avondentertainment",
    //                     "ID": 47
    //                 },
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 }
    //             ],
    //             "latitude": "44.18132",
    //             "longitude": "4.53537",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 105177,
    //             "campsiteName": "Camping Bijela Uvala ****",
    //             "campsiteImages": [
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2add7be14e7.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/a/5e2add7db4a05.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2add7f94e23.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/a/5e2add81c82e4.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2add83bdc51.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2adce4d5ec1.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/a/5e2adce679357.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/a/5e2adce8147d2.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2adce9bae6c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/a/5e2adceb6fc7e.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn3.suncamp.eu/5/e/2/a/5e2add7c0a0e7.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/a/5e2add7dd12f1.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2add7fb42a5.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/a/5e2add81e8aef.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2add83dbf83.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/a/5e2adce4f33e8.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2adce69362c.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/a/5e2adce82f846.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/a/5e2adce9d6775.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/a/5e2adceb88d25.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Kroatië",
    //                 "place": "Poreč",
    //                 "region": "Istrië"
    //             },
    //             "reviewScore": "8,2",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 92",
    //             "price": "€ 92",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Fitness",
    //                     "ID": 46
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 }
    //             ],
    //             "latitude": "45.19153",
    //             "longitude": "13.59689",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 105172,
    //             "campsiteName": "Camping Park Umag ****",
    //             "campsiteImages": [
    //                 "https://cdn1.suncamp.eu/5/e/7/3/5e737402e2a12.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/7/3/5e7374050133b.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/7/3/5e737406f2e15.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/7/3/5e737408f411d.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03885f5.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9056113.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd96226c0.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd97f137d.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd99d549d.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9bbe04d.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn2.suncamp.eu/5/e/7/3/5e7374030eb42.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/7/3/5e737405219b5.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/7/3/5e7374071e3c6.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/7/3/5e73740927241.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/b/f/5dbffe03a8fb2.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/b/f/5dbffd9071f7b.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd963ff10.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd981a0c1.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/d/5e1dbd9a02df8.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/d/5e1dbd9bdb8a6.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Kroatië",
    //                 "place": "Umag",
    //                 "region": "Istrië"
    //             },
    //             "reviewScore": "8,1",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 88",
    //             "price": "€ 88",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Golf/Midgetgolf",
    //                     "ID": 50
    //                 }
    //             ],
    //             "latitude": "45.36717",
    //             "longitude": "13.54731",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 109413,
    //             "campsiteName": "Camping Bella Austria ****",
    //             "campsiteImages": [
    //                 "https://cdn1.suncamp.eu/5/8/3/c/583c2a02cf993.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/3/c/583c2a046bab2.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/3/c/583c2a05e5f2c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/3/c/583c2a0759b53.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce2987b.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/b/5e2b02cfb86c1.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/b/5e2b0269d55a8.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bc0aeb.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/3/c/583c2a1e1750d.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/3/c/583c2a1f7aab6.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn2.suncamp.eu/5/8/3/c/583c2a02edb06.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/3/c/583c2a048a007.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/3/c/583c2a0610d17.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/3/c/583c2a0777e28.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/2/b/5e2b02ce437f2.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/b/5e2b02cfd31fe.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/2/b/5e2b0269ee86a.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/2/b/5e2b026bde671.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/3/c/583c2a1e3020f.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/3/c/583c2a1f986bd.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Oostenrijk",
    //                 "place": "Sankt Peter am Kammersberg",
    //                 "region": "Stiermarken"
    //             },
    //             "reviewScore": "7,9",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 94",
    //             "price": "€ 94",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Honden toegestaan",
    //                     "ID": 39
    //                 },
    //                 {
    //                     "Name": "Mindervaliden",
    //                     "ID": 44
    //                 }
    //             ],
    //             "latitude": "47.18028",
    //             "longitude": "14.21528",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 110977,
    //             "campsiteName": "Camping Bi-Village ****",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/e/1/7/5e17011a8477c.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/8/0/5d80b4900558c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/8/7/4/5874c033afae7.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/7/5e17011c70324.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/7/5e17011e48e2a.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6cceaff.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6e73930.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/5/5e15bc701d841.jpeg",
    //                 "https://cdn1.suncamp.eu/5/6/f/2/56f250626e863.jpeg",
    //                 "https://cdn2.suncamp.eu/5/8/7/4/5874c04d6ac7f.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn1.suncamp.eu/5/e/1/7/5e17011aa1426.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/8/0/5d80b4901f40b.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/7/4/5874c033d2726.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/7/5e17011c8cbb9.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/7/5e17011e67aa7.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6ce5e11.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6e906a2.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/5/5e15bc7037228.jpeg",
    //                 "https://cdn1.suncamp.eu/5/6/f/2/56f2506293114.jpeg",
    //                 "https://cdn1.suncamp.eu/5/8/7/4/5874c04d927e3.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Kroatië",
    //                 "place": "Fažana",
    //                 "region": "Istrië"
    //             },
    //             "reviewScore": "7,9",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 123",
    //             "price": "€ 123",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Avondentertainment",
    //                     "ID": 47
    //                 },
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 }
    //             ],
    //             "latitude": "44.91739",
    //             "longitude": "13.81104",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 105180,
    //             "campsiteName": "Camping Valkanela ***",
    //             "campsiteImages": [
    //                 "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d3d21a9e.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d3f20908.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4145e3c.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d431ad6a.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d44dbe44.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4834f99.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4a0a473.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d4baa5d7.jpeg",
    //                 "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d4d60e1f.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4f225c9.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d3d3d5c5.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d3f3fda4.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4168395.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4333f2c.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4501282.jpeg",
    //                 "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4855d00.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4a23fe5.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4bc6a6b.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4d7943c.jpeg",
    //                 "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4f3cd0f.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Kroatië",
    //                 "place": "Funtana",
    //                 "region": "Istrië"
    //             },
    //             "reviewScore": "8,2",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 80",
    //             "price": "€ 80",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Golf/Midgetgolf",
    //                     "ID": 50
    //                 }
    //             ],
    //             "latitude": "45.165",
    //             "longitude": "13.60772",
    //             "locale": "nl_NL"
    //         },
    //         {
    //             "campsiteId": 100685,
    //             "campsiteName": "Lanterna Premium Camping Resort *****",
    //             "campsiteImages": [
    //                 "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb028272.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb204c6b.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3cae92.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb58fb40.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb764f87.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/e/5e1edd236fc35.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/e/5e1edd25322ee.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26c2182.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/e/5e1edd28c25ee.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a6fd2c.jpeg"
    //             ],
    //             "campsiteRetinaImages": [
    //                 "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb04571e.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb221653.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3e9717.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb5acfee.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb784f01.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/e/5e1edd2387f7d.jpeg",
    //                 "https://cdn3.suncamp.eu/5/e/1/e/5e1edd254cc55.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26dcd6c.jpeg",
    //                 "https://cdn1.suncamp.eu/5/e/1/e/5e1edd28dcfc1.jpeg",
    //                 "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a89dad.jpeg"
    //             ],
    //             "geo": {
    //                 "country": "Kroatië",
    //                 "place": "Poreč",
    //                 "region": "Istrië"
    //             },
    //             "reviewScore": "8,0",
    //             "isSunlodgeCategory": true,
    //             "isGlampingCategory": true,
    //             "discount": 0,
    //             "fromPrice": "€ 94",
    //             "price": "€ 94",
    //             "facilityCategory": [
    //                 {
    //                     "Name": "Barbecueën",
    //                     "ID": 36
    //                 },
    //                 {
    //                     "Name": "Campingwinkel",
    //                     "ID": 37
    //                 },
    //                 {
    //                     "Name": "Fietsen en Wandelen",
    //                     "ID": 53
    //                 },
    //                 {
    //                     "Name": "Geldautomaat",
    //                     "ID": 51
    //                 },
    //                 {
    //                     "Name": "Golf/Midgetgolf",
    //                     "ID": 50
    //                 }
    //             ],
    //             "latitude": "45.29714",
    //             "longitude": "13.59439",
    //             "locale": "nl_NL"
    //         }
    //     ]
    // },
    {
        "id": "10",
        "name": "Avontuurlijke lodgetenten",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/kampeer-platform.appspot.com/o/demo_images%2FTenuta%20Primero-7254.jpg?alt=media",
        "key": "Avontuurlijke lodgetenten",
        "resultList": [
            {
                "campsiteId": 110977,
                "campsiteName": "Camping Bi-Village ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/7/5e17011a8477c.jpeg",
                    "https://cdn2.suncamp.eu/5/d/8/0/5d80b4900558c.jpeg",
                    "https://cdn3.suncamp.eu/5/8/7/4/5874c033afae7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011c70324.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011e48e2a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6cceaff.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6e73930.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc701d841.jpeg",
                    "https://cdn1.suncamp.eu/5/6/f/2/56f250626e863.jpeg",
                    "https://cdn2.suncamp.eu/5/8/7/4/5874c04d6ac7f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/7/5e17011aa1426.jpeg",
                    "https://cdn3.suncamp.eu/5/d/8/0/5d80b4901f40b.jpeg",
                    "https://cdn1.suncamp.eu/5/8/7/4/5874c033d2726.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/7/5e17011c8cbb9.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/7/5e17011e67aa7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc6ce5e11.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15bc6e906a2.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15bc7037228.jpeg",
                    "https://cdn1.suncamp.eu/5/6/f/2/56f2506293114.jpeg",
                    "https://cdn1.suncamp.eu/5/8/7/4/5874c04d927e3.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Fažana",
                    "region": "Istrië"
                },
                "reviewScore": "7,9",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 255",
                "price": "€ 255",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "44.91739",
                "longitude": "13.81104",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 105180,
                "campsiteName": "Camping Valkanela ***",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d3d21a9e.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d3f20908.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4145e3c.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d431ad6a.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d44dbe44.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4834f99.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4a0a473.jpeg",
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d4baa5d7.jpeg",
                    "https://cdn2.suncamp.eu/5/d/d/d/5ddd3d4d60e1f.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4f225c9.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d3d3d5c5.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d3f3fda4.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4168395.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4333f2c.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4501282.jpeg",
                    "https://cdn1.suncamp.eu/5/d/d/d/5ddd3d4855d00.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4a23fe5.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4bc6a6b.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4d7943c.jpeg",
                    "https://cdn3.suncamp.eu/5/d/d/d/5ddd3d4f3cd0f.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Funtana",
                    "region": "Istrië"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 91",
                "price": "€ 91",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.165",
                "longitude": "13.60772",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 100685,
                "campsiteName": "Lanterna Premium Camping Resort *****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb028272.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb204c6b.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3cae92.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb58fb40.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb764f87.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd236fc35.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd25322ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26c2182.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd28c25ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a6fd2c.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb04571e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1eddb221653.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb3e9717.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1eddb5acfee.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1eddb784f01.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd2387f7d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/e/5e1edd254cc55.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd26dcd6c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/e/5e1edd28dcfc1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/e/5e1edd2a89dad.jpeg"
                ],
                "geo": {
                    "country": "Kroatië",
                    "place": "Poreč",
                    "region": "Istrië"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 111",
                "price": "€ 111",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.29714",
                "longitude": "13.59439",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104766,
                "campsiteName": "Eurocamping Pacengo ***",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/2/9/5e2985c3eced6.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2985c5da198.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/9/5e2985c7d9895.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2985c9a98a0.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2985cb7a6e3.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e298670175e9.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/9/5e2986721347a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e298673e01c6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/2/9/5e298675cfbe4.jpeg",
                    "https://cdn2.suncamp.eu/5/7/c/0/57c028443bf2c.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2985c41302f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2985c619c64.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2985c7f3f8c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2985c9c8d80.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2985cb957ed.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2986703585d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/2/9/5e2986723350c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e2986740a519.jpeg",
                    "https://cdn2.suncamp.eu/5/e/2/9/5e298675ed4ad.jpeg",
                    "https://cdn2.suncamp.eu/5/7/c/0/57c028445b694.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Pacengo di Lazise",
                    "region": "Veneto"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 172",
                "price": "€ 172",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    },
                    {
                        "Name": "Golf/Midgetgolf",
                        "ID": 50
                    }
                ],
                "latitude": "45.46722",
                "longitude": "10.71617",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 101060,
                "campsiteName": "Village Norcenni Girasole ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da45729621.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da45902cf0.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da45ac8c1a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/0/d/5e0da45c96287.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da45e4c573.jpeg",
                    "https://cdn2.suncamp.eu/5/e/0/d/5e0da4ea51c6b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4ebf17a3.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4ed84fb6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/6/6/5e6615a9a01b2.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4f10ec94.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/0/d/5e0da45745631.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da4591f12b.jpeg",
                    "https://cdn2.suncamp.eu/5/e/0/d/5e0da45ae3204.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da45cb00ca.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da45e68559.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4ea6e46e.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da4ec1777c.jpeg",
                    "https://cdn3.suncamp.eu/5/e/0/d/5e0da4ed9f6d8.jpeg",
                    "https://cdn2.suncamp.eu/5/e/6/6/5e6615a9c3afe.jpeg",
                    "https://cdn1.suncamp.eu/5/e/0/d/5e0da4f12aa20.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Figline Valdarno",
                    "region": "Toscane"
                },
                "reviewScore": "7,9",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 257",
                "price": "€ 257",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "43.61333",
                "longitude": "11.44944",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104751,
                "campsiteName": "Camping Cisano/San Vito ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35cca12a81b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e789235dbf8d.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35cca596f6e.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35a93e9a2eb.jpeg",
                    "https://cdn2.suncamp.eu/5/d/3/5/5d35a748d8c76.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/5/5d35a74b21611.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35a94283e1a.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e78923806353.jpeg",
                    "https://cdn3.suncamp.eu/5/e/7/8/5e78923a217a3.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e78923c2f833.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35cca144c2c.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e78923607a68.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35cca5b41fa.jpeg",
                    "https://cdn3.suncamp.eu/5/d/3/5/5d35a93eb6724.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/5/5d35a74900e4a.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/5/5d35a74b3cacc.jpeg",
                    "https://cdn1.suncamp.eu/5/d/3/5/5d35a942a0095.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e789238268bd.jpeg",
                    "https://cdn2.suncamp.eu/5/e/7/8/5e78923a43b02.jpeg",
                    "https://cdn1.suncamp.eu/5/e/7/8/5e78923c4d3a1.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Bardolino",
                    "region": "Veneto"
                },
                "reviewScore": "8,0",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 154",
                "price": "€ 154",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.52556",
                "longitude": "10.72867",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 100648,
                "campsiteName": "Camping Barco Reale ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15cf0f80e84.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15cf11675fb.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15cf131bf03.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15cf14d8bb4.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15cf16a815d.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15ce8a9cd9d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15ce8c66f88.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15ce8e14b44.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15ce8fcc6c9.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15ce91d70ff.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15cf0f9d403.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15cf1184a00.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15cf1336b4f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15cf14f37fd.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15cf16c5baa.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15ce8aba20e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15ce8c82470.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15ce8e2b220.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15ce8fef75a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15ce9205747.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "San Baronto",
                    "region": "Toscane"
                },
                "reviewScore": "8,2",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 157",
                "price": "€ 157",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "43.84174",
                "longitude": "10.91049",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104864,
                "campsiteName": "Camping Pra'delle Torri ****",
                "campsiteImages": [
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a48967f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a694c0f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464a873536.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464aa26b90.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464abecce5.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464af1e829.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464b0e092b.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464b2a0a37.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464b47d021.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b64121f.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464a4a6cef.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464a6b0ab6.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464a8902a7.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464aa43167.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464ac156af.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1464af3e1ee.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b109b86.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b2bdb7f.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1464b498847.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1464b65dc41.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Caorle",
                    "region": "Veneto"
                },
                "reviewScore": "8,5",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 254",
                "price": "€ 254",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "45.57311",
                "longitude": "12.81253",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104881,
                "campsiteName": "Camping Union Lido *****",
                "campsiteImages": [
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1458be9eedf.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1458c066582.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1458c257fbf.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1458c45d925.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1458c61e0c2.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e14583b137bb.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e14583cc5356.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e14583e60e76.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e14583ff042d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1458414cc30.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1458bebb3e7.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e1458c083309.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/4/5e1458c2753eb.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1458c479402.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e1458c63a50d.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/4/5e14583b2e53e.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e14583ce124a.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e14583e7bde4.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e14584011d92.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/4/5e14584166e35.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Cavallino",
                    "region": "Veneto"
                },
                "reviewScore": "8,5",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 228",
                "price": "€ 228",
                "facilityCategory": [
                    {
                        "Name": "Avondentertainment",
                        "ID": 47
                    },
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    }
                ],
                "latitude": "45.46726",
                "longitude": "12.53012",
                "locale": "nl_NL"
            },
            {
                "campsiteId": 104880,
                "campsiteName": "Camping Italy ****",
                "campsiteImages": [
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c62f0665f.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15c630a39ad.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15c6324015f.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c633e0145.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15c63599f36.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15c6c7d3b17.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15c6c997c16.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c6cb6b6a1.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c6cd5f07c.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c6cf32b36.jpeg"
                ],
                "campsiteRetinaImages": [
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15c62f24d97.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c630be739.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c6325c576.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c634069db.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15c635b4d9a.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c6c7f0d94.jpeg",
                    "https://cdn1.suncamp.eu/5/e/1/5/5e15c6c9b4146.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15c6cb887de.jpeg",
                    "https://cdn2.suncamp.eu/5/e/1/5/5e15c6cd7ba54.jpeg",
                    "https://cdn3.suncamp.eu/5/e/1/5/5e15c6cf50bac.jpeg"
                ],
                "geo": {
                    "country": "Italië",
                    "place": "Cavallino-Treporti",
                    "region": "Veneto"
                },
                "reviewScore": "8,3",
                "isSunlodgeCategory": true,
                "isGlampingCategory": true,
                "discount": 0,
                "fromPrice": "€ 233",
                "price": "€ 233",
                "facilityCategory": [
                    {
                        "Name": "Barbecueën",
                        "ID": 36
                    },
                    {
                        "Name": "Campingwinkel",
                        "ID": 37
                    },
                    {
                        "Name": "Fietsen en Wandelen",
                        "ID": 53
                    },
                    {
                        "Name": "Fitness",
                        "ID": 46
                    },
                    {
                        "Name": "Geldautomaat",
                        "ID": 51
                    }
                ],
                "latitude": "45.46836",
                "longitude": "12.5331",
                "locale": "nl_NL"
            }
        ]
    }
];
