import React from 'react';
import Rating from "react-rating";
import {calculateRating} from "../../utils";
import FullStar from '../../assets/full-star.svg';
import HalfStar from '../../assets/half-empty.svg';
import Star from '../../assets/empty-star.svg';
import {ToolTip} from "../ToolTip/ToolTip";

interface RatingComponentType {
    ratingScore: number;
    totalRating: number;
    totalCount: number;
}

export const RatingView = ({ratingScore, totalRating, totalCount}: RatingComponentType) => {

    const ratingArray = calculateRating(ratingScore, totalRating);
    return (
        <ToolTip message={`Rating (${ratingScore}/${totalRating})`}>
            <div className={'rating_view_parent'}>
                {ratingArray.map((rate) => {
                    if (rate === 1) return <img src={FullStar} width={20} height={20} alt={'full'}/>;
                    else if (rate === 0.5) return <img src={HalfStar} width={20} height={20} alt={'half'}/>;
                    else return <img src={Star} width={20} height={20} alt={'star'}/>;
                })}
                <div className={'rating_view_total_count'}>{'(' + totalCount + ')'}</div>
            </div>
        </ToolTip>
    )
};
