import React, {memo, useEffect} from 'react';
import {Paper} from "@material-ui/core";
import ReactSiema from "../utils/ReactSiemaSingleImage";
import favourite from "../../assets/favourite.svg";
import {customizeFacility, customizeFeatures} from "../../utils";
import ReviewScore from "../ReviewScore/ReviewScore";
import {RatingView} from "../RatingView/RatingComponent";
import {Button} from "../Button/Button";

interface PopularListItemComponentType {
    item: any;
}

const PopularListItemComponent = ({item}: PopularListItemComponentType) => {

    let myRef = React.useRef();
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [nextButton, showNextButton] = React.useState(item.campsiteImages !== undefined);
    return (
        <>
            <Paper className={'popular_list_item_container'}>
                <div className={'popular_list_item_thumbnail_header'}>
                    {item.campsiteImages ? <div className={'popular_list_item_image_slider'}>
                            {currentSlide !== 0 && <button className="popular_list_item_prev" onClick={() => {
                                (myRef as any).prev()
                            }}>&lt;</button>}

                            <ReactSiema
                                ref={siema => myRef = siema as any}
                                currentSlide={setCurrentSlide}
                                showNextButton={showNextButton}
                                onChange={() => {
                                }}
                            >
                                {(item.campsiteImages || [item.imageUrl]).map((image: any) => {
                                    return (
                                        <div onClick={() => {
                                        }} key={image.toString()} className={'popular_list_item_camping_parent'}>

                                            <img src={favourite} alt={'Favourite'}
                                                 className={"popular_list_item_heart_icon"}
                                                 onClick={() => {
                                                 }}/>

                                            <img src={image} alt={image}
                                                 className={"popular_list_item_thumbnail_camping"}/>
                                        </div>
                                    )
                                })}

                            </ReactSiema>

                            {nextButton && <button className="popular_list_item_next" onClick={() => {
                                (myRef as any).next()
                            }}>&gt;</button>}
                        </div> :

                        <div className={'popular_list_item_image_slider'}>
                            <div onClick={() => {
                            }} key={item.imageUrl.toString()} className={'popular_list_item_single_camping_parent'}>
                                <img src={favourite} alt={'Favourite'} className={"popular_list_item_heart_icon"}
                                     onClick={() => {
                                     }}/>
                                <img src={item.imageUrl} alt={item.imageUrl}
                                     className={"popular_list_item_single_thumbnail_camping"}/>
                            </div>
                        </div>}

                    <div className={'popular_list_item_content_parent'}>
                        <div>
                            <div onClick={() => {
                            }} className={'popular_list_item_header_text'}>{item.name || item.campsiteName}</div>
                            {(item.discount || item.discount === 0) &&
                            <div className={'popular_list_item_price_parent'}>
                                <div className={'popular_list_item_discount_price'}>{'Adviesprijs '}</div>
                                <div className={'popular_list_item_discount_price'}
                                     style={{
                                         marginLeft: '5px',
                                         textDecorationLine: "line-through"
                                     }}>{'€' + item.name ? item.discount : item.fromPrice}</div>
                            </div>}

                            {item.price && <div className={'popular_list_item_price_parent'}>
                                <div className={'popular_list_item_price_mark'}>€</div>
                                <div onClick={() => {
                                }} className={'popular_list_item_price_text'}>{item.price}</div>
                            </div>}

                            {item.content && <div className={'popular_list_item_content'}>{item.content}</div>}

                            {item.features &&
                            <div className={'popular_list_item_content'}>{customizeFeatures(item.features)}</div>}

                            {item.facilityCategory && <div
                                className={'popular_list_item_content'}>{customizeFacility(item.facilityCategory)}</div>}
                        </div>

                        <div className={'popular_list_item_rating_review_more_information'}>
                            <div className={'popular_list_item_review'}>
                                {item.reviewScore && <ReviewScore reviewScore={item.reviewScore} outOfScore={'10.0'}/>}

                                {(item.rating !== undefined || item.rating !== 0) &&
                                <RatingView ratingScore={item.rating}
                                            totalCount={item.rating !== 0 ? item.ratingCount : 0} totalRating={5}/>}
                            </div>
                            <Button className={'popular_list_item_more_information'} title={'More Information'}/>
                        </div>

                    </div>
                </div>

            </Paper>
        </>
    )
};

export default memo(PopularListItemComponent)
