import React, {memo} from 'react';
import {useParams} from 'react-router-dom';
import {Topbar} from "../../components/topbar";
import BottomBarComponent from "../../components/BottomBar/BottomBarComponent";
import {popularCamping, popularMarket} from "../../dummy/dummy";
import {ItemType} from "../../types";
import PopularListItemComponent from "../../components/PopularListItemComponent/PopularListItemComponent";
import {Helmet} from 'react-helmet';

const PopularList = () => {
    const params = useParams();
    return <>
        <Helmet>
            <title>{'Popular Item'}</title>
            <meta
                name="description"
                content={"Search for items"}
            />
        </Helmet>
        <Topbar/>
        <div className={'popular_list_container'}>
            <div className={'popular_list_header_text'}>{`Popular Items "${(params as any).type === "camping" ? "Camping" : "Market"}"`}</div>
        {
            (params as any || {type: 'camping'}).type === 'camping' ?
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    {
                        popularCamping.map((data: any) => {
                            return <PopularListItemComponent item={data}/>
                        })
                    }
                </div>
                : <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    {
                        popularMarket.map((data: any) => {
                            return <PopularListItemComponent item={data}/>
                        })
                    }
                </div>
        }
        </div>
        <BottomBarComponent/>

    </>
};

export default memo(PopularList);
