import React, {memo} from 'react';
import {CategoryType} from "../../types";
import NextIcon from '../../assets/next_icon.svg';
import OthersPlaceholder from "../../assets/images/othersPlaceholder.png";

interface MarketItemComponentType {
    item: CategoryType;
    onClick: Function;
}

const MarketItemComponent = ({item, onClick}: MarketItemComponentType) => {
    return (
        <div onClick={() => onClick()} className="market_grid_item">
            <div className="market_grid_item_wrapper">
                <div className={"market_item"}>
                    <img onError={(e) => {

                        (e.target as any).src=OthersPlaceholder
                    }} className={`market_thumbnail_image`} src={item.imageUrl} alt={"home"}/>
                    <div className={'market_thumbnail_text_parent'}>
                        <div className={"market_thumbnail_text"}>{item.name}</div>
                        <img src={NextIcon} width={20} height={20} alt={'next-icon'}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default memo(MarketItemComponent)
