import React from "react";

interface PartnerItemProps {
    partnerName: string;
    partnerImage: any;
    customImageClass?: string;
}
export const PartnerItem = (props: PartnerItemProps) => {
    return (
        <div className="grid-item">
            <div className="grid-item-wrapper">
                <div className={"partner_item"}>
                    <img className={`thumbnail_image ${props.customImageClass}`} src={props.partnerImage} alt={"home"}/>
                    <div className={"thumbnail_text"}>{props.partnerName}</div>
                </div>
            </div>
        </div>
    )
};
