export const camalize = (str: string) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};


export const customizeFeatures = (features: string[]) => {
    let featureString = '';
    features && features.map((feature: string) => {
        featureString = featureString + '\n - ' + feature
    });

    return featureString
};

export const customizeFacility = (features: any[]) => {
    let featureString = '';
    features && features.map((feature: any) => {
        featureString = featureString + '\n - ' + feature.Name
    });

    return featureString
};

export const calculateRating = (ratingScore:number, totalRating: number) => {
    const ratingArray = [0,0,0,0,0];
    let ratingScoreInternal = ratingScore;
    Array.from(Array(totalRating)).map((rate, index) => {
        if (ratingScoreInternal >= 1) {
            ratingArray[index] = 1;
            ratingScoreInternal -= 1;
        } else if (ratingScoreInternal > 0 && ratingScoreInternal <= 0.5) {
            ratingArray[index] = 0.5;
            ratingScoreInternal = 0;
        } else if (ratingScoreInternal > 0.5 && ratingScoreInternal <= 0.9) {
            ratingArray[index] = 1;
            ratingScoreInternal = 0;
        }
    });
    return ratingArray;
};
