import React, {memo} from 'react';
import {Paper} from "@material-ui/core";
import MarketItemComponent from "../MarketItemComponent/MarketItemComponent";
import {useHistory} from 'react-router-dom';

interface MarketItemListComponentType {
    items: any[];
    headerTitle: string;
    className?: string;
    type: string;
    onItemClick: Function;
}

const MarketItemListComponent = ({className, items, headerTitle, type, onItemClick}: MarketItemListComponentType) => {
    const history = useHistory();
    return (
        <div className={`market_parent ${className}`}>
            <Paper className="market_list">
                <div className={"market_list_header"}>
                    <b className={"market_list_header_text"}>{headerTitle}</b>
                </div>
                <div className="market_grid_row">
                    {items.map((category: any) => {
                        return (
                            <MarketItemComponent onClick={() => {
                                onItemClick(category);
                            }} item={category}/>
                        )
                    })}
                </div>
            </Paper>
        </div>
    )
};

export default memo(MarketItemListComponent)
