import React, {memo} from 'react';

interface ReviewScoreType {
    reviewScore: string;
    outOfScore: string;
}
const ReviewScore = ({reviewScore, outOfScore}: ReviewScoreType) => {
    return (
        <>
            <div className={'review_score'}>
                {reviewScore}
            </div>
        </>
    )
};

export default memo(ReviewScore);
