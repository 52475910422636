import React from 'react';
import Check from '../../assets/check.svg';
export const ObelinkHeaderProductInformationText = () => {
    return (
        <>
            <div className={'obelink_header_product_information'}>
                <div className={'obelink_header_text_parent'}>
                    <img src={Check} alt={'check'} width={15} height={15}/>
                    <div className={'obelink_header_text'}>Grootste assortiment van Europa</div>
                </div>
                <div className={'obelink_header_text_parent'}>
                    <img src={Check} alt={'check'} width={15} height={15}/>
                    <div className={'obelink_header_text'}>Laagste prijsgarantie</div>
                </div>
                <a className={'obelink_header_text_parent obelink_header_text_decoration'} href={'https://www.obelink.nl/klantenservice/bestellen/wat-zijn-de-verzendkosten'}>
                    <img src={Check} alt={'check'} width={15} height={15}/>
                    <div className={'obelink_header_text'}>Gratis verzending vanaf 50,-*</div>
                </a>
                <a className={'obelink_header_text_parent obelink_header_text_decoration'} href={'https://www.obelink.nl/obelink-kampeerwinkel-megastore'}>
                    <img src={Check} alt={'check'} width={15} height={15}/>
                    <div className={'obelink_header_text'}>72.500 m2 Megastore in Winterswijk</div>
                </a>
            </div>
        </>
    )
}
