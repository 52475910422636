import React from "react";
import "../css/main.css";
import Kampeer from "../assets/Kampeer.svg";
import Paper from '@material-ui/core/Paper';
import star from "../assets/star.svg";
import user from "../assets/user.svg";
import MenuComponent from "../components/Menu/menu";
import {Button} from "./Button/Button";

export const Topbar = () => {
    return (
        <div className="navbar">
            <div className="topLevel">
                <Paper className="header" elevation={1} square>
                    <img className="logo" alt="ACSI" src={Kampeer}/>
                    <div className="spacer"/>
                    <div className="navigation_items">
                        <Button title={"Login"}/>
                        <a href="./"><img width={30} src={user} alt={"User Profile"}/></a>
                        <a href="./"><img width={30} src={star} alt={"Favourite"}/></a>
                    </div>
                    <div className="navigation_menu">
                        <MenuComponent/>
                    </div>
                </Paper>
            </div>
        </div>
    );
};
