import React, {memo, useRef} from "react";
import { useHistory } from "react-router-dom";
import ReactSiema from "../utils/ReactSiema";

interface PopularItemsComponentProps {
    childUI: any;
    items: any[];
    className?: string;
    headerText: string;
    type: string;
}

const PopularItemsComponent = ({childUI, items, className, headerText, type}: PopularItemsComponentProps) => {
    let myRef = useRef();
    let history = useHistory();

    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [nextButton, showNextButton] = React.useState(true);
    return (
        <>
            <div className={`popular_item_parent ${className}`}>
                <div className={"popular_item_layout"}>
                    <div className={"popular_item_header"}>
                        <b className={"popular_item_header_text"}>{headerText}</b>
                        <button className="popular_item_header_show_more" onClick={() => {
                            history.push(`./popularList/${type}`)
                        }}>Bejijk meer</button>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {currentSlide !==0 && <button className="popular_item_prev" onClick={() => {
                        (myRef as any).prev()
                    }}>&lt;</button>}


                    <ReactSiema
                        currentSlide={setCurrentSlide}
                        showNextButton={showNextButton}
                        ref={siema => myRef = siema as any}
                        onChange={() => {
                        }}
                        >

                    {items.map((item: any) => {
                        return (
                            childUI(item)
                        )
                    })}
                    </ReactSiema>

                        {nextButton && <button className="popular_item_next" onClick={() => {
                            (myRef as any).next()
                    }}>&gt;</button>}

                    </div>
                </div>
            </div>
        </>
    )
};

export default memo(PopularItemsComponent);
