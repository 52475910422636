import React from 'react';
import {Paper} from "@material-ui/core";
import Market from "../../assets/images/vrijetijdsmarkt.jpg";
import Camping from "../../assets/images/camping.jpg";
import Others from "../../assets/images/others.png";
import {PartnerItem} from "./PartnerItem";

export const PartnerComponent = () => {
    return (
        <>
            <div className={"partner_parent"}>
                <Paper className="partner_list">
                    <div className="grid-row">
                        <PartnerItem partnerName={'Campings'} partnerImage={Camping}/>
                        <PartnerItem partnerName={'Vrijetijdsmarkt'} partnerImage={Market}/>
                        <PartnerItem customImageClass={"gray_bg"} partnerName={'Partner'} partnerImage={Others}/>
                        <PartnerItem customImageClass={"gray_bg"} partnerName={'Partner'} partnerImage={Others}/>
                        <PartnerItem customImageClass={"gray_bg"} partnerName={'Partner'} partnerImage={Others}/>
                    </div>
                </Paper>
            </div>
        </>
    );
};


