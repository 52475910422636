import {Reducer} from "redux";
import produce from 'immer';
import {HomeActionTypes, HomeState} from "./types";

// Type-safe initialState!
export const initialState: HomeState = {
    isLoading: false,
    loadingMessage: ''
};

const reducer: Reducer<HomeState> = (state = initialState, action) =>
    produce(state, draft => {
        if (action.type === HomeActionTypes.LOADING) {
            draft.isLoading = action.payload.value;
            draft.loadingMessage = action.payload.message;
        }
    });


export {reducer}
