import React, {memo} from 'react';
import {Topbar} from "../../components/topbar";
import BottomBarComponent from "../../components/BottomBar/BottomBarComponent";
import {useParams} from "react-router-dom";
import {compose, Dispatch} from "redux";
import {connect} from "react-redux";
import {ApplicationState} from "../../store/combineReducers";
import {subCategoryList} from "./selectors";
import {SubCategoryType} from "../../types";
import MarketListComponent from "../../components/MarketItemListComponent/MarketItemListComponent";
import {ObelinkHeaderProductInformationText as HeaderInformation} from '../../components/ObelinkHeaderProductInformationText/ObelinkHeaderProductInformationText';
import OtherCategoryList from "../../components/OtherCategoryList/OtherCategoryList";
import {categoryJson} from "../../dummy/dummy";

interface SubCategoriesScreenType {
    subCategories: SubCategoryType[];
}

const SubCategoriesScreen = ({subCategories}: SubCategoriesScreenType) => {
    const params = useParams();

    return (
        <>
            <div className={'sub_catergories_list_container'}>
                <Topbar/>
                <HeaderInformation/>
                <div className={'sub_categories_content'}>

                    <OtherCategoryList headerText={'Other Categories: '} type={(params as any).type} allCategory={categoryJson} selectedCategoryName={(params as any).category || ''}/>
                    <MarketListComponent headerTitle={''} items={subCategories} type={'market'}
                                         onItemClick={() => {
                                         }}/>

                </div>
                <BottomBarComponent/>
            </div>
        </>
    )
};


const mapStateToProps = (state: ApplicationState) => {
    return {
        subCategories: subCategoryList(state),
    };
};

const withConnect = connect(
    mapStateToProps,
    null,
);

export default compose(
    memo,
    withConnect
)(SubCategoriesScreen);
