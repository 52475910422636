import React, {memo} from 'react';
import {NavLink} from 'react-router-dom';
import {compose, Dispatch} from "redux";
import {setSelectedCategoryId} from "../../containers/SubCategories/actions";
import {connect} from "react-redux";

interface OtherCategoryListType {
    allCategory: any[];
    selectedCategoryName: string;
    headerText: string;
    type: string;
    setSelectedCategoryId: Function;
}

const OtherCategoryList = ({headerText, setSelectedCategoryId, allCategory, selectedCategoryName, type}: OtherCategoryListType) => {
    return (
        <>
            <div className={'other_category_parent'}>
                <div className={'other_category_header_text'}>{headerText}</div>
                {allCategory.map((category) => {
                    if (category.name !== selectedCategoryName) {
                        return <NavLink to={`/${type}/${category.name}`} onClick={() => {
                            setSelectedCategoryId(category.id)
                        }} className={'other_category_child'}>
                            {category.name}
                        </NavLink>
                    } else return <></>

                })}
            </div>
        </>
    )
};


const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setSelectedCategoryId: (categoryId: string) => dispatch(setSelectedCategoryId(categoryId))
    };
};

const withConnect = connect(
    null,
    mapDispatchToProps,
);

export default compose(memo, withConnect)(OtherCategoryList) as any;
