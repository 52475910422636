import React from "react";
import Search from "../../assets/search.svg";

interface SearchBoxProps {
    placeholder?: string;

}
export const SearchBox = (props: SearchBoxProps) => {
    return (
        <div className={"search_box_parent"}>
            <input className={"search_box_input"} placeholder={props.placeholder}/>
            <img className={"search_box_icon"} src={Search} alt={"Search"}/>
        </div>
    )
};
