import { Reducer } from "redux";
import produce from 'immer';
import {MarketActionTypes, MarketState} from "./types";

// Type-safe initialState!
export const initialState: MarketState = {
  selectedCategoryId: '',
};

const reducer: Reducer<MarketState> = (state = initialState, action) =>
  produce(state, draft => {
    if (action.type === MarketActionTypes.SELECTED_CATEGORY) {
      {
        draft.selectedCategoryId = action.payload;
      }
    }
  });


export { reducer }
