import React, {Component} from "react";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Home from "../containers/home";
import {Provider} from "react-redux";
import {persistor, store} from "../store/configureStore";
import {PersistGate} from "redux-persist/integration/react"
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import PopularListComponent from "../containers/PopularList/PopularList";
import {withRouter} from "react-router";
import SubCategoriesScreen from "../containers/SubCategories/SubCategories";


const primaryTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#323366',
        },
        secondary: {
            main: '#3299cc',
        },
        actionColor: {
            main: '#ec671c',
        }
    },
});

// Added as HOC so on forward navigation it will jump to top
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export const Scroll = withRouter(ScrollToTop);

const Main = props => (
    <ThemeProvider theme={primaryTheme}>

        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Scroll>
                        <Switch>
                            <Route exact path="/"><Home/></Route>
                            <Route exact path="/popularList/:type"><PopularListComponent/></Route>
                            <Route exact path={"/:type/:category"} component={SubCategoriesScreen}/>
                        </Switch>
                    </Scroll>
                </Router>
            </PersistGate>
        </Provider>
    </ThemeProvider>
);

export default Main;
