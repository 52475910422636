import React from "react";

interface ButtonProps {
    title: string;
    className?: string;
}
export const Button = ({title, className}: ButtonProps) => {
    return (
        <button className={`button ${className}`}>{title}</button>
    )
};
