import {combineReducers} from "redux";

import {reducer as homeReducer} from "../containers/Home/reducer";
import {reducer as marketReducer} from "../containers/SubCategories/reducer";
import {HomeState} from "../containers/Home/types";
import {MarketState} from "../containers/SubCategories/types";

/**
 * add component state here inside ApplicationState
 */
export interface ApplicationState {
    home: HomeState,
    market: MarketState
}


/**
 * add component reducers here inside rootReducer
 */
export const rootReducer = combineReducers({
    home: homeReducer,
    market: marketReducer
});
