import React, {memo} from 'react';

const BottomDisclaimerComponent = () => {
    return (
        <>
            <div className={'bottom_disclaimer_container'}>
                <div className={'bottom_disclaimer_parent'}>
                    <div className={'bottom_disclaimer_child'}>
                        <div className={'bottom_disclaimer_text'}>
                            Disclaimer
                        </div>
                        <div className={'bottom_disclaimer_text'}>
                            Privacy
                        </div>
                        <div className={'bottom_disclaimer_text'}>
                            Cookies
                        </div>
                        <div className={'bottom_disclaimer_text'}>
                            Copyright
                        </div>
                        <div className={'bottom_disclaimer_text'}>
                            Fraudebeleid
                        </div>

                    </div>
                    <div className={'bottom_disclaimer_child'}>
                        @ 2020 Kampeerplatform
                    </div>
                </div>
            </div>
        </>
    )
};

export default memo(BottomDisclaimerComponent)
