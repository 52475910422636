import * as React from "react";
import HomeImage from "../../assets/images/shutterstock_766958938.jpg";
import {PartnerComponent} from "../../components/PartnerComponent/PartnerComponent";
import {compose, Dispatch} from "redux";
import {memo} from "react";
import {connect} from 'react-redux';
import {SearchBox} from "../../components/SearchBox/SearchBox";
import PopularItemsComponent from "../../components/PopularItemsComponent/PopularItemsComponent";
import {CampingType, ItemType} from "../../types";
import {popularMarket, popularCamping, categoryJson, campingJson} from "../../dummy/dummy";
import favourite from "../../assets/favourite.svg";
import MarketItemListComponent from "../../components/MarketItemListComponent/MarketItemListComponent";
import Home from "../home";
import {withRouter} from "react-router";
import {setSelectedCategoryId} from "../SubCategories/actions";
import {useHistory} from 'react-router-dom';

// @ts-ignore
const HomeScreen = ({match, setSelectedCategoryId}) => {
    const history = useHistory();
    return (
        <>

            <div className={"home_parent"}>

                <img className={"home_image"} src={HomeImage} alt={"Home"} width={"100%"} height={400}/>
                <SearchBox placeholder={"Zoeken in kampeer platform"}/>
                <PartnerComponent/>
                <PopularItemsComponent headerText={'Populair binnen vrijetijdsmarkt'} items={popularMarket}
                                       childUI={MarketImageUI} type={'market'}/>
                <PopularItemsComponent headerText={'Populaire Campings'} className={'home_popular_camping'}
                                       items={popularCamping}
                                       childUI={CampingImageUI} type={'camping'}/>
                <MarketItemListComponent items={categoryJson} headerTitle={'Vrijetijdsmarkt categorieén'}
                                         type={'market'} onItemClick={(item: any) => {
                                             setSelectedCategoryId(item.id);
                    history.push(`market/${item.name}/`);

                }}/>
                <MarketItemListComponent className={'margin_top'} items={campingJson}
                                         headerTitle={'Vakantiethema\'s en accommodatietypes'} type={'camping'} onItemClick={() => {}}/>
            </div>
        </>
    )
};

const imageClick = (url: string) => {
    window.location.href= url;
};

const MarketImageUI = (item: ItemType) => {
    return (
        <img onClick={() => imageClick(item.links[0].url)} key={item.id} src={item.imageUrl} alt={item.name} className={"popular_item_thumbnail"}/>
    )
};

export const CampingImageUI = (item: CampingType) => {
    return (
        <div onClick={() => imageClick(item.links[0].url)} key={item.campsiteId} className={'popular_item_camping_parent'} style={{backgroundColor: "red"}}>
            <img src={favourite} alt={'Favourite'} className={"popular_item_heart_icon"}
                 onClick={() => alert(item.campsiteName)}/>
            <img src={item.campsiteImages[0]} alt={item.campsiteName}
                 className={"popular_item_thumbnail_camping"}/>
        </div>
    )
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setSelectedCategoryId: (categoryId: string) => dispatch(setSelectedCategoryId(categoryId))
    };
};

const withConnect = connect(
    null,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
    withRouter,
)(HomeScreen);
