import React from 'react';

interface ToolTipType {
    message: string;
    children: any;
}
export const ToolTip = ({message, children}: ToolTipType) => {
    return (
        <div className={'tooltip'}>
            {children}
            <span className="tooltiptext">{message}</span>
        </div>
    )
};
