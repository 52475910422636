import { takeLatest } from '@redux-saga/core/effects';



/**
 * Get list from dummy json file
 *
 * @returns {Generator<*, void, ?>}
 */
export function* getCampsites() {
  // Read projects from a JSON file

}

/**
 * Root saga manages watcher lifecycle
 */
export default function* homeSaga() {
  yield takeLatest("Load", getCampsites);

}
