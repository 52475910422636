import React, {memo} from 'react';
import {Button} from "../Button/Button";

const SubscriptionComponent = () => {
    return (
        <div className={'bottom_bar_subscribe_parent'}>
            <div className={"bottom_bar_header_text"}>Meld ja aan voor onze nieuwsbrief</div>
            <input className={"bottom_bar_subscribe_input"} placeholder={'Ja e-mailadres'}/>
            <Button title={"Aanmelden"} className={'bottom_bar_subscribe_button'}/>
        </div>
    )
};

export default memo(SubscriptionComponent);
