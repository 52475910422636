import {CategoryType, SubCategoryType} from "../../types";
import {categoryJson} from "../../dummy/dummy";
import {ApplicationState} from "../../store/combineReducers";

const categoryJsonData: CategoryType[] = categoryJson;

export const subCategoryList = (
    state: ApplicationState
): SubCategoryType[] => {
    const selectedCategoryId = state.market.selectedCategoryId;

    const selectedCategories: CategoryType[] = categoryJsonData.filter((category: CategoryType) => category.id === selectedCategoryId);

    return selectedCategories.length > 0 ? selectedCategories[0].subCategories : []
};



