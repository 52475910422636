import React, {memo} from 'react';
import SubscriptionComponent from "./SubscriptionComponent";
import BottomLinkComponent from "./BottomLinkComponent";
import BottomDisclaimerComponent from "./BottomDisclaimerComponent";
const BottomBarComponent = () => {
    return (
     <>
         <div className={'bottom_bar_parent'}>
             <SubscriptionComponent/>
             <BottomLinkComponent/>
             <BottomDisclaimerComponent/>
         </div>
     </>
    )
};

export default memo(BottomBarComponent)
