import React, {Component} from "react";
import "../css/main.css";
import {Topbar} from "../components/topbar";
import HomeScreen from "./Home/HomeScreen";
import {Helmet} from 'react-helmet';
import BottomBarComponent from "../components/BottomBar/BottomBarComponent";


class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <>
                <div className={'home_parent'}>
                    <Helmet>
                        <title>{'Kampeer Platform'}</title>
                        <meta
                            name="description"
                            content={"Search for items"}
                        />
                    </Helmet>
                    <Topbar/>
                    <HomeScreen/>
                    <BottomBarComponent/>
                </div>
            </>
        );
    }
}

export default Home;
