import React, {memo} from 'react';
import Kampeer from "../../assets/Kampeer.svg";
import {NavLink} from "react-router-dom";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

const BottomLinkComponent = () => {
    return (
        <>
            <div className={'bottom_link_container'}>
                <div className={'bottom_link_parent'}>
                    <div className={'bottom_link_child'}>
                        <img className={''} src={Kampeer} alt={'Kampeer logo'}/>
                    </div>
                    <div className={'bottom_link_child'}>
                        <div className={'bottom_bar_link_header_text'}>Ik will graag...</div>
                        <NavLink
                            exact
                            className={'bottom_link'}
                            to="/to"
                        >
                            <div className={'bottom_bar_link_header_description'}>
                                <div className={'bottom_link_arrow_style'}>></div>
                                Wijzigingen doorgeven
                            </div>
                        </NavLink>


                    </div>
                    <div className={'bottom_link_child'}>
                        <div className={'bottom_bar_link_header_text'}>Over KampeerPlatform</div>
                        <NavLink
                            exact
                            className={'bottom_link'}
                            to="/to"
                        >
                            <div className={'bottom_bar_link_header_description'}>
                                <div className={'bottom_link_arrow_style'}>></div>
                                Over
                            </div>
                        </NavLink>
                        <NavLink
                            exact
                            className={'bottom_link'}
                            to="/to"
                        >
                            <div className={'bottom_bar_link_header_description'}>
                                <div className={'bottom_link_arrow_style'}>></div>
                                Dienstenwijzer
                            </div>
                        </NavLink>
                    </div>
                    <div className={'bottom_link_child'}>
                        <div className={'bottom_bar_link_header_text'}>Hulp en advises</div>
                        <NavLink
                            exact
                            className={'bottom_link'}
                            to="/to"
                        >
                            <div className={'bottom_bar_link_header_description'}>
                                <div className={'bottom_link_arrow_style'}>></div>
                                Veelgestelde vragen
                            </div>
                        </NavLink>
                        <NavLink
                            exact
                            className={'bottom_link'}
                            to="/to"
                        >
                            <div className={'bottom_bar_link_header_description'}>
                                <div className={'bottom_link_arrow_style'}>></div>
                                Klantenservice
                            </div>
                        </NavLink>
                    </div>
                    <div className={'bottom_link_child black_bg'}>
                        <NavLink
                            exact
                            className={'bottom_link'}
                            to="/to"
                        >
                            <div className={'bottom_link_social_parent'}>
                                <FacebookIcon style={{color: "white"}}/>
                                <div className={'bottom_bar_link_header_description white_text_color'}>Facebook</div>
                            </div>
                        </NavLink>
                        <NavLink
                            exact
                            className={'bottom_link'}
                            to="/to"
                        >
                            <div className={'bottom_link_social_parent'}>
                                <InstagramIcon style={{color: "white"}}/>
                                <div className={'bottom_bar_link_header_description white_text_color'}>Instagram</div>
                            </div>
                        </NavLink>
                        <NavLink
                            exact
                            className={'bottom_link'}
                            to="/to"
                        >
                            <div className={'bottom_link_social_parent'}>
                                <TwitterIcon style={{color: 'white'}}/>
                                <div className={'bottom_bar_link_header_description white_text_color'}>Twitter</div>
                            </div>
                        </NavLink>
                    </div>

                </div>
            </div>
        </>
    )
};

export default memo(BottomLinkComponent)
